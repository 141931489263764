<style>
.menu-cl-d {
  background-color: black;
}
.menu-cl-l {
  background-color: white;
}
.plus_minus {
  font-size: 18px;
  color: red;
}
</style>
<template>
  <default-layout-dynamic>

    <loader @close="displayLoader = false" v-model="displayLoader"></loader>
    <ahtung @close="alert_switch = false" :value="alert_switch" :message="alert_message" :header="alert_header"></ahtung>
    <confirm-dialog ref="confirm"></confirm-dialog>
    <v-progress-linear :active="displayLoaderRecalculate" indeterminate></v-progress-linear>


    <v-row :dense="true">
      <v-col cols="6" md="10"><v-select variant="solo" label="Выбор склада" :disabled="stores.length <= 1" :items="stores" :hide-details="true" density="compact" :return-object="true" :item-title="'name'" v-model="selectedStore"></v-select></v-col>
      <v-col cols="6" md="2">
        <v-tooltip location="bottom">
          <template v-slot:activator="{ props: tooltip }">
            <v-btn v-bind="tooltip" size="x-small" @click="editOrder(templateOr)" icon="mdi-plus"></v-btn>
          </template>
          <span>Создать заказ</span>
        </v-tooltip>
        <v-tooltip  location="bottom">
          <template v-slot:activator="{ props: tooltip }"><v-btn v-bind="tooltip" @click="showItemsFilters = !showItemsFilters" size="x-small" icon="mdi-arrow-down"></v-btn></template>
          <span>Скрыть/показать фильтры</span>
        </v-tooltip>
        <v-tooltip  location="bottom">
          <template v-slot:activator="{ props: tooltip }"><v-btn v-bind="tooltip" @click="loadOrdersData" size="x-small" icon="mdi-refresh"></v-btn></template>
          <span>Обновить список заказов</span>
        </v-tooltip>
      </v-col>
      <template v-if="showItemsFilters && selectedStore">
        <v-col align-self="center" cols="12" md="6">
          <v-text-field variant="solo" :density="'compact'" :hide-details="true" v-model="search" label="Фильтр по тексту"></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-select variant="solo" multiple label="Фильтр по статусам" item-title="nameR" item-value="id" density="compact" :hide-details="true" :items="getStatuses()" v-model="oRselectedStatuses" :clearable="true"></v-select>
        </v-col>

        <v-col cols="12" md="6">
          <v-select variant="solo" label="Фильтр по менеджеру" item-title="name" item-value="id" :multiple="true" density="compact" :hide-details="true" :items="profiles" v-model="oRselectedManagers" :clearable="true"></v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-select variant="solo" label="Фильтр по клиенту" item-title="name" item-value="id" :multiple="true" density="compact" :hide-details="true" :items="oRfilteredClients" v-model="oRselectedClients" :clearable="true"></v-select>
        </v-col>

        <v-col cols="12" md="6">
          <VueDatePicker placeholder="Фильтр по дате создания - начало" v-model="filterStartDateObj.date"></VueDatePicker>
        </v-col>
        <v-col cols="12" md="6">
          <VueDatePicker placeholder="Фильтр по дате создания - конец" v-model="filterEndDateObj.date"></VueDatePicker>
        </v-col>
      </template>

      <template v-if="selectedStore">
        <v-col cols="12" md="8">
          <v-pagination :density="'compact'" size="small" v-if="cabinetData.orders.length > 0" v-model="oRpage" :length="oRpages" :rounded="true" ></v-pagination>
        </v-col>
        <v-col cols="6" md="2">
          <v-text-field variant="solo" :density="'compact'" min="1" :max="oRpages" type="number" :hide-details="true" v-model.number="oRpage" label="Стр №"></v-text-field>
        </v-col>
        <v-col cols="6" md="2">
          <v-text-field variant="solo" :density="'compact'" min="1" :hide-details="true" type="number" v-model.number="oRelPerPage" label="Зак. на странице"></v-text-field>
        </v-col>
      </template>

      <v-col cols="12" v-if="selectedStore">
        <v-table v-if="!m" :fixed-header="true" height="600" :density="'compact'">
          <tbody>
            <template v-for="item in cabinetData.orders" :key="item.id">
              <tr @dblclick="expand(item.id)" :style="getOrderColor(item)">
                <td>{{ item.id }}</td>
                <td>{{ $global.displayDate(item.dateCreation, 'DD.MM.YYYY HH:mm') }}</td>
                <td>{{ getFieldFromStatusOr(item.type, item.status, 'nameR') }}</td>
                <td>{{ item.name }}</td>
                <td>{{ getManagerName(item.managerId) }}</td>
                <td>{{ getShippingName(item.shippingId) }} / {{ item.postTrackNum }}</td>
                <td>{{ item.sum }} / {{ item.paySum }}</td>
                <td>
                  <v-tooltip  location="bottom">
                    <template v-slot:activator="{ props: tooltip }"><v-btn v-bind="tooltip" size="x-small" icon="mdi-arrow-down" @click="expand(item.id)"></v-btn></template>
                    <span>Подробности заказа</span>
                  </v-tooltip>

                  <v-tooltip  location="bottom">
                    <template v-slot:activator="{ props: tooltip }"><v-btn v-bind="tooltip" size="x-small" icon="mdi-table-edit" @click="editOrder(item)"></v-btn></template>
                    <span>Редактировать заказ</span>
                  </v-tooltip>

<!--                  <v-tooltip  location="bottom">-->
<!--                    <template v-slot:activator="{ props: tooltip }">-->
<!--                      -->
<!--                      -->
<!--                    </template>-->
<!--                    <span>Добавить оплату</span>-->
<!--                  </v-tooltip>-->


                  <v-menu location="bottom" :open-on-click="false" :close-on-content-click="false" width="150" v-model="addMoneySwitch[item.id]">
                    <template v-slot:activator="{ props }">
                      <v-btn :disabled="!(item.status === 4 || item.status === 5) || item.sum <= item.paySum" size="x-small" v-bind="props" icon="mdi-currency-usd" @click="add_money(item)"></v-btn>
                    </template>
                    <v-row :class="'menu-cl-' + (isDark ? 'd':'l')" dense align-content="center" align="center" justify="center">
                      <v-col cols="12" align-self="center">
                        <v-text-field variant="solo" :ref="'amtfo' + item.id" @keyup.enter="submit_add_money(item.id)" @keyup.esc="close_add_money(item.id)" density="compact" :hide-details="true" type="number" v-model.number="addMoneyValue[item.id]" label="BYN"></v-text-field>
                        <v-text-field variant="solo" density="compact" :hide-details="true" v-model="addMoneyComment[item.id]" label="Комментарий"></v-text-field>
                        <v-btn size="x-small" icon="mdi-checkbox-marked-circle-outline" :disabled="addMoneyValue[item.id] <= 0" @click="submit_add_money(item.id)"></v-btn>
                        <v-btn size="x-small" icon="mdi-cancel" @click="close_add_money(item.id)"></v-btn>
                      </v-col>
                    </v-row>
                  </v-menu>

                </td>
              </tr>
              <tr v-if="expanded.includes(item.id)">
                <td colspan="7">
                  <table style="border-radius: 5px; width: 100%; font-size: 10px;" border="1">
                    <tr><td>Имя:</td><td>{{item.name}}</td></tr>
                    <tr><td>Емэйл:</td><td>{{item.email}}</td></tr>
                    <tr><td>Телефон:</td><td>{{item.phone}}</td></tr>
                    <tr><td>Адрес:</td><td>{{item.address}}</td></tr>
                    <tr><td>Доставка:</td><td>{{getShippingName(item.shippingId)}}</td></tr>
                    <tr><td>Трекномер почты:</td><td>{{item.postTrackNum}}</td></tr>
                    <tr><td>Стоимость доставки:</td><td><b>{{item.shippingPrice}}</b></td></tr>
                    <tr><td>Дата доставки:</td><td><b>{{$global.displayDate(item.dateShipping, 'DD.MM.YYYY HH:mm')}}</b></td></tr>
                    <tr><td>Комментарий:</td><td>{{item.comment}}</td></tr>
                  </table>
                </td>
              </tr>
              <tr v-if="expanded.includes(item.id)">
                <td colspan="7">
                  <table style="border-radius: 5px; width: 100%; font-size: 10px;" border="1">
                    <tr v-for="(pi, index) of item.payments" :key="index">
                      <td>{{pi.id}}</td>
                      <td>{{$global.displayDate(pi.date, 'DD.MM.YYYY HH:mm')}}</td>
                      <td>{{pi.value}}</td>
                      <td>{{pi.currency}}</td>
                      <td>{{pi.description}}</td>
                      <template v-if="$global.checkRoles(user, ['SUDO', 'ADMIN'])">
                        <td><v-icon color="#ff0000" @click="submit_remove_money(item.id, pi.id)">mdi-delete-forever</v-icon></td>
                      </template>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr v-if="expanded.includes(item.id)">
                <td colspan="7">
                  <table style="border-radius: 5px; width: 100%; font-size: 10px;" border="1">
                    <tr>
                      <td>Арт</td><td>Назв</td><td>Кол</td><td>Цен</td><td>Сум</td><td>Брк</td><td>Взв</td><td>Под</td>
                    </tr>
                    <tr v-for="(it, index) of item.items" :key="index">
                      <td>{{it.artikul}}</td>
                      <td>{{it.breadcrumbs}}</td>
                      <td>{{it.count}}</td>
                      <td>{{it.price}}</td>
                      <td>{{it.sum}}</td>
                      <td><v-tooltip location="bottom"><template v-slot:activator="{ props: tooltip }"><span v-bind="tooltip" v-if="!item.returnsApplied && [7].includes(item.status) && !(it.brak + it.ret + it.present === it.count)" class="plus_minus" @click="increase(it, 1, 'brak', item.id)">+</span></template><span>Добавить брак</span></v-tooltip>&nbsp;{{ it.brak }}&nbsp;<v-tooltip location="bottom"><template v-slot:activator="{ props: tooltip }"><span v-bind="tooltip" v-if="!item.returnsApplied && ![8, 9].includes(item.status) && !(it.brak === 0)" class="plus_minus" @click="increase(it, -1, 'brak', item.id)">-</span></template><span>Уменьшить брак</span></v-tooltip></td>
                      <td><v-tooltip location="bottom"><template v-slot:activator="{ props: tooltip }"><span v-bind="tooltip" v-if="!item.returnsApplied && [7].includes(item.status) && !(it.brak + it.ret + it.present === it.count)" class="plus_minus" @click="increase(it, 1, 'ret', item.id)">+</span></template><span>Добавить возврат</span></v-tooltip>&nbsp;{{ it.ret }}&nbsp;<v-tooltip location="bottom"><template v-slot:activator="{ props: tooltip }"><span v-bind="tooltip" v-if="!item.returnsApplied && ![8, 9].includes(item.status) && !(it.ret === 0)" class="plus_minus" @click="increase(it, -1, 'ret', item.id)">-</span></template><span>Уменьшьть возврат</span></v-tooltip></td>
                      <td><v-tooltip location="bottom"><template v-slot:activator="{ props: tooltip }"><span v-bind="tooltip" v-if="!item.returnsApplied && [7].includes(item.status) && !(it.brak + it.ret + it.present === it.count)" class="plus_minus" @click="increase(it, 1, 'present', item.id)">+</span></template><span>Добавить подарок</span></v-tooltip>&nbsp;{{ it.present }}&nbsp;<v-tooltip location="bottom"><template v-slot:activator="{ props: tooltip }"><span v-bind="tooltip" v-if="!item.returnsApplied && [7].includes(item.status) && !(it.present === 0)" class="plus_minus" @click="increase(it, -1, 'present', item.id)">-</span></template><span>Уменьшьть подарки</span></v-tooltip></td>
                    </tr>
                    <tr>
                      <td></td>
                      <td style="text-align: right"><b>Итого:</b></td>
                      <td>{{ item.totalCount }}</td>
                      <td></td>
                      <td>{{ item.sum }}</td>
                      <td>
                        <v-tooltip location="bottom">
                          <template v-slot:activator="{ props: tooltip }">
                            <v-icon v-bind="tooltip" v-if="!changedOrders.includes(item.id) && item.hasReturns && !item.returnsApplied && [7].includes(item.status)" size="x-small" color="#ff0000" @click="applyReturns(item)">mdi-check</v-icon>
                          </template>
                          <span>Применить возвраты к заказу и складу</span>
                        </v-tooltip>
                        <v-tooltip location="bottom">
                          <template v-slot:activator="{ props: tooltip }">
                            <v-icon v-bind="tooltip" v-if="!changedOrders.includes(item.id) && item.hasReturns && item.returnsApplied && [7].includes(item.status)" size="x-small" color="#ff0000" @click="rollbackReturns(item)">mdi-cancel</v-icon>
                          </template>
                          <span>Отменить применение возвраты к заказу и складу</span>
                        </v-tooltip>
                      </td>
                      <td>
                        <v-tooltip location="bottom">
                          <template v-slot:activator="{ props: tooltip }">
                            <v-icon v-bind="tooltip" v-if="changedOrders.includes(item.id)" size="x-small" color="#ff0000" @click="submitOrder(item)">mdi-content-save</v-icon>
                          </template>
                          <span>Сохранить заказ</span>
                        </v-tooltip>
                      </td>
                      <td></td>
                    </tr>
                  </table>
                </td>
              </tr>



            </template>
          </tbody>
        </v-table>
        <lazy-component v-else wrapper-tag="table" class="cells-wrapper-mobile" v-for="item in cabinetData.orders" :key="item.id">
          <tbody>
          <tr :style="getOrderColor(item)">
            <td class="cell-mobile" style="width: 10%; text-align: left;">
              <b>{{ item.id }}</b>
            </td>
            <td class="cell-mobile" style="width: 30%; text-align: left;">
              {{ $global.displayDate(item.dateCreation, 'DD.MM.YYYY HH:mm') }}
            </td>
            <td class="cell-mobile" style="width: 30%; text-align: left;">
              {{ item.name }}
            </td>
            <td class="cell-mobile" style="width: 30%; text-align: left;">
              {{ getFieldFromStatusOr(item.type, item.status, 'nameR') }}
            </td>
          </tr>
          <tr :style="getOrderColor(item)">
            <td class="cell-mobile" style="width: 40%; text-align: left;" colspan="2">
              {{ getManagerName(item.managerId) }}
            </td>
            <td class="cell-mobile" style="width: 60%; text-align: left;" colspan="2">
              <v-tooltip  location="bottom">
                <template v-slot:activator="{ props: tooltip }"><v-btn v-bind="tooltip" size="x-small" icon="mdi-arrow-down" @click="expand(item.id)"></v-btn></template>
                <span>Подробности заказа</span>
              </v-tooltip>

              <v-tooltip  location="bottom">
                <template v-slot:activator="{ props: tooltip }"><v-btn v-bind="tooltip" size="x-small" icon="mdi-table-edit" @click="editOrder(item)"></v-btn></template>
                <span>Редактировать заказ</span>
              </v-tooltip>


              <v-menu location="bottom" :open-on-click="false" :close-on-content-click="false" width="150" v-model="addMoneySwitch[item.id]">
                <template v-slot:activator="{ props }">
                  <v-btn :disabled="!(item.status === 4 || item.status === 5) || item.sum <= item.paySum" size="x-small" v-bind="props" icon="mdi-currency-usd" @click="add_money(item)"></v-btn>
                </template>
                <v-row :class="'menu-cl-' + (isDark ? 'd':'l')" dense align-content="center" align="center" justify="center">
                  <v-col cols="12" align-self="center">
                    <v-text-field variant="solo" :ref="'amtfo' + item.id" @keyup.enter="submit_add_money(item.id)" @keyup.esc="close_add_money(item.id)" density="compact" :hide-details="true" type="number" v-model.number="addMoneyValue[item.id]" label="BYN"></v-text-field>
                    <v-text-field variant="solo" density="compact" :hide-details="true" v-model="addMoneyComment[item.id]" label="Комментарий"></v-text-field>
                    <v-btn size="x-small" icon="mdi-checkbox-marked-circle-outline" :disabled="addMoneyValue[item.id] <= 0" @click="submit_add_money(item.id)"></v-btn>
                    <v-btn size="x-small" icon="mdi-cancel" @click="close_add_money(item.id)"></v-btn>
                  </v-col>
                </v-row>
              </v-menu>
            </td>
          </tr>
          <tr :style="getOrderColor(item)">
            <td class="cell-mobile" style="width: 40%; text-align: left;" colspan="2">
              {{ item.sum }} / {{ item.paySum }}
            </td>
            <td class="cell-mobile" style="width: 60%; text-align: left;" colspan="2">
              {{ getShippingName(item.shippingId) }} / {{ item.postTrackNum }}
            </td>
          </tr>

          <tr v-if="expanded.includes(item.id)">
            <td colspan="7">
              <table style="border-radius: 5px; width: 100%; font-size: 10px;" border="1">
                <tr><td>Имя:</td><td>{{item.name}}</td></tr>
                <tr><td>Емэйл:</td><td>{{item.email}}</td></tr>
                <tr><td>Телефон:</td><td>{{item.phone}}</td></tr>
                <tr><td>Адрес:</td><td>{{item.address}}</td></tr>
                <tr><td>Доставка:</td><td>{{getShippingName(item.shippingId)}}</td></tr>
                <tr><td>Трекномер почты:</td><td>{{item.postTrackNum}}</td></tr>
                <tr><td>Стоимость доставки:</td><td><b>{{item.shippingPrice}}</b></td></tr>
                <tr><td>Дата доставки:</td><td><b>{{$global.displayDate(item.dateShipping, 'DD.MM.YYYY HH:mm')}}</b></td></tr>
                <tr><td>Комментарий:</td><td>{{item.comment}}</td></tr>
              </table>
            </td>
          </tr>
          <tr v-if="expanded.includes(item.id)">
            <td colspan="7">
              <table style="border-radius: 5px; width: 100%; font-size: 10px;" border="1">
                <tr v-for="(pi, index) of item.payments" :key="index">
                  <td>{{pi.id}}</td>
                  <td>{{$global.displayDate(pi.date, 'DD.MM.YYYY HH:mm')}}</td>
                  <td>{{pi.value}}</td>
                  <td>{{pi.currency}}</td>
                  <td>{{pi.description}}</td>
                  <template v-if="$global.checkRoles(user, ['SUDO', 'ADMIN'])">
                    <td><v-icon color="#ff0000" @click="submit_remove_money(item.id, pi.id)">mdi-delete-forever</v-icon></td>
                  </template>
                </tr>
              </table>
            </td>
          </tr>
          <tr v-if="expanded.includes(item.id)">
            <td colspan="7">
              <table style="border-radius: 5px; width: 100%; font-size: 10px;" border="1">
                <tr>
                  <td>Арт</td><td>Назв</td><td>Кол</td><td>Цен</td><td>Сум</td><td>Брк</td><td>Взв</td><td>Под</td>
                </tr>
                <tr v-for="(it, index) of item.items" :key="index">
                  <td>{{it.artikul}}</td>
                  <td>{{it.breadcrumbs}}</td>
                  <td>{{it.count}}</td>
                  <td>{{it.price}}</td>
                  <td>{{it.sum}}</td>
                  <td><v-tooltip location="bottom"><template v-slot:activator="{ props: tooltip }"><span v-bind="tooltip" v-if="!item.returnsApplied && [7].includes(item.status) && !(it.brak + it.ret + it.present === it.count)" class="plus_minus" @click="increase(it, 1, 'brak', item.id)">+</span></template><span>Добавить брак</span></v-tooltip>&nbsp;{{ it.brak }}&nbsp;<v-tooltip location="bottom"><template v-slot:activator="{ props: tooltip }"><span v-bind="tooltip" v-if="!item.returnsApplied && ![8, 9].includes(item.status) && !(it.brak === 0)" class="plus_minus" @click="increase(it, -1, 'brak', item.id)">-</span></template><span>Уменьшить брак</span></v-tooltip></td>
                  <td><v-tooltip location="bottom"><template v-slot:activator="{ props: tooltip }"><span v-bind="tooltip" v-if="!item.returnsApplied && [7].includes(item.status) && !(it.brak + it.ret + it.present === it.count)" class="plus_minus" @click="increase(it, 1, 'ret', item.id)">+</span></template><span>Добавить возврат</span></v-tooltip>&nbsp;{{ it.ret }}&nbsp;<v-tooltip location="bottom"><template v-slot:activator="{ props: tooltip }"><span v-bind="tooltip" v-if="!item.returnsApplied && ![8, 9].includes(item.status) && !(it.ret === 0)" class="plus_minus" @click="increase(it, -1, 'ret', item.id)">-</span></template><span>Уменьшьть возврат</span></v-tooltip></td>
                  <td><v-tooltip location="bottom"><template v-slot:activator="{ props: tooltip }"><span v-bind="tooltip" v-if="!item.returnsApplied && [7].includes(item.status) && !(it.brak + it.ret + it.present === it.count)" class="plus_minus" @click="increase(it, 1, 'present', item.id)">+</span></template><span>Добавить подарок</span></v-tooltip>&nbsp;{{ it.present }}&nbsp;<v-tooltip location="bottom"><template v-slot:activator="{ props: tooltip }"><span v-bind="tooltip" v-if="!item.returnsApplied && [7].includes(item.status) && !(it.present === 0)" class="plus_minus" @click="increase(it, -1, 'present', item.id)">-</span></template><span>Уменьшьть подарки</span></v-tooltip></td>
                </tr>
                <tr>
                  <td></td>
                  <td style="text-align: right"><b>Итого:</b></td>
                  <td>{{ item.totalCount }}</td>
                  <td></td>
                  <td>{{ item.sum }}</td>
                  <td>
                    <v-tooltip location="bottom">
                      <template v-slot:activator="{ props: tooltip }">
                        <v-icon v-bind="tooltip" v-if="!changedOrders.includes(item.id) && item.hasReturns && !item.returnsApplied && [7].includes(item.status)" size="x-small" color="#ff0000" @click="applyReturns(item)">mdi-check</v-icon>
                      </template>
                      <span>Применить возвраты к заказу и складу</span>
                    </v-tooltip>
                    <v-tooltip location="bottom">
                      <template v-slot:activator="{ props: tooltip }">
                        <v-icon v-bind="tooltip" v-if="!changedOrders.includes(item.id) && item.hasReturns && item.returnsApplied && [7].includes(item.status)" size="x-small" color="#ff0000" @click="rollbackReturns(item)">mdi-cancel</v-icon>
                      </template>
                      <span>Отменить применение возвраты к заказу и складу</span>
                    </v-tooltip>
                  </td>
                  <td>
                    <v-tooltip location="bottom">
                      <template v-slot:activator="{ props: tooltip }">
                        <v-icon v-bind="tooltip" v-if="changedOrders.includes(item.id)" size="x-small" color="#ff0000" @click="submitOrder(item)">mdi-content-save</v-icon>
                      </template>
                      <span>Сохранить заказ</span>
                    </v-tooltip>
                  </td>
                  <td></td>
                </tr>
              </table>
            </td>
          </tr>


          </tbody>
          <template #placeholder>
            <tr>
              <td colspan="4">Loading...</td>
            </tr>
          </template>
        </lazy-component>
      </v-col>
    </v-row>

    <order-editor-admin v-model="editOr" :types="typesOr" :profile="profile" :profiles="profiles" :clients="clients" :store="selectedStore" :template-or-it="templateOrIt" @save="saveOrder"
                         @close="editOrderDialog = false;clearOrder();" @recalculate="recalculateOrder()" :loader="displayLoaderRecalculate"
                         @clear="clearOrder()" :show="editOrderDialog" :mobile="m" @add-to-cart="add_to_cart"></order-editor-admin>



  </default-layout-dynamic>
</template>

<script>

import DefaultLayoutDynamic from "../components/layouts/DefaultLayoutDynamic";
import Loader from "../components/Loader";
import axios from "axios";
import Ahtung from "../components/Ahtung";
import router from "@/router/router";
import OrderEditorAdmin from "@/components/orders/OrderEditorAdmin.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import {useTheme} from "vuetify";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

export default {
  components: {
    ConfirmDialog,
    VueDatePicker,
    OrderEditorAdmin,
    DefaultLayoutDynamic,
    Loader,
    Ahtung
  },
  data() {
    return {
      restUrl: process.env.VUE_APP_BACKEND_BASE_URL,
      displayLoader: false,
      displayLoaderRecalculate: false,

      stores: [],
      selectedStore: undefined,
      cabinetData: { orders: [] },

      showItemsFilters: true,
      filterItems: '',

      clients: [],
      profiles: [],
      profile: {},

      oRsortAsc: false,
      oRsortField: 'id',
      oRpages: 0,
      oRpage: 1,
      oRelPerPage: 25,
      oRselectedTypes: [],
      oRselectedStatuses: [],
      oRselectedManagers: [],
      oRselectedClients: [],
      oRfilteredClients: [],

      typesOr: [],
      editOrderDialog: false,
      search: '',

      filterStartDateObj: {date: null, menu: false},
      filterEndDateObj: {date: null, menu: false},

      firstLoad: true,

      templateOr: {},
      templateOrIt: {},
      editOr: {items:[]},
      willCloseEdit: false,

      expanded: [],

      alert_switch: false,
      alert_message: "",
      alert_header: "",

      addMoneySwitch: {},
      addMoneyValue: {},
      addMoneyComment: {},

      changedOrders: [],
      m: true,
    }
  },
  name: "Orders",
  mounted() {
    this.m = !this.$vuetify.display.mdAndUp;
  },
  created() {
    if (!this.loggedIn) router.push('/start')
    else {
      this.getData();
    }
  },
  methods: {
    applyReturns(o) {
      this.willCloseEdit = false;
      let self = this;
      let json = { item: o };
      axios({
        method: 'post',
        url: this.restUrl + "/rest/admin/orders/apply_returns",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        if(response.data.status) {
          console.log('success');
        }
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.displayLoader = false;
      });
    },
    rollbackReturns(o) {
      this.willCloseEdit = false;
      let self = this;
      let json = { item: o };
      axios({
        method: 'post',
        url: this.restUrl + "/rest/admin/orders/rollback_returns",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        if(response.data.status) {
          console.log('success');
        }
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.displayLoader = false;
      });
    },
    submitOrder(o) {
      var index = this.changedOrders.indexOf(o.id);
      if (index !== -1) this.changedOrders.splice(index, 1);
      let self = this;
      let json = { item: o };
      axios({
        method: 'post',
        url: this.restUrl + "/rest/admin/orders/submit_order",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        if(response.data.status) {
          console.log('success');
        }
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.displayLoader = false;
      });
    },
    increase(it, count, field, id) {
      it[field] += count;
      if(it[field] < 0) it[field] = 0;
      else if(it.brak + it.ret > it.count) it[field] -= 1;
      else if(!this.changedOrders.includes(id)) this.changedOrders.push(id);
    },
    getManagerName(id) {
      for(let p of this.profiles) if(p.id === id) return p.name;
    },
    getStatuses() {
      for(let t of this.typesOr) if(t.id === 1) return t.statuses;
    },
    getOrderColor(i) {
      if(i.status === 8) return 'color: #BFBFBF;';
      if(i.status === 9) return 'color: green;';
      if(i.attention) return 'color: red;';
      if(i.reminderInFuture) return 'color: gray;';
      if((i.type === 5)) return 'color: #029191;';
      return 'color: white;'
    },
    editOrder(item) {
      let self = this;
      self.editOrderDialog = false;
      this.editOr = this.$global.clone(item);
      this.$nextTick(() => {
        if (self.editOr.id === 0) self.updateOrder();
        else {
          if (self.editOr.status === 1) self.editOr.status = 2;
          self.recalculateOrder();
        }
        self.editOrderDialog = true;
      });
    },
    closeEditOrder() {
      this.editOrderDialog = false;
      this.editOr = {items:[]};
    },
    add_money(o) {
      console.log(o)
      document.activeElement.blur();
      this.addMoneyComment[o.id] = '';
      this.addMoneyValue[o.id] = o.sum - o.paySum;
      if(!this.addMoneySwitch[o.id]) this.addMoneySwitch[o.id] = true;
      else this.addMoneySwitch[o.id] = !this.addMoneySwitch[o.id];
      let self = this;
      this.$nextTick(() => {
        // console.log(self.$refs['amtfo' + o.id][0])
        setTimeout(() => {
          self.$refs['amtfo' + o.id][0].focus()
        }, 100)
      });
    },
    close_add_money(orderId) {
      this.addMoneySwitch[orderId] = false;
    },
    submit_add_money(orderId) {
      let self = this;
      let json = { orderId: orderId, value: this.addMoneyValue[orderId], comment: this.addMoneyComment[orderId] };
      self.displayLoaderRecalculate = true;
      axios({
        method: 'post',
        url: this.restUrl + "/rest/admin/orders/add_money",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        if(response.data.status) {
          self.close_add_money(orderId);
        } else {
          console.log("error add money");
        }
        self.$nextTick(() => { self.displayLoaderRecalculate = false; });
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.$nextTick(() => { self.displayLoaderRecalculate = false; });
      });
    },
    submit_remove_money(orderId, piId) {
      let self = this;
      this.$refs.confirm.open('Внимание!', 'Удалить оплату?', {color: '#9C0A20'}).then((result) => {
        if(result) {
          let json = {orderId: orderId, piId: piId};
          self.displayLoaderRecalculate = true;
          axios({
            method: 'post',
            url: self.restUrl + "/rest/admin/orders/remove_money",
            headers: self.$global.headers(self.$store.state.auth.user),
            dataType: 'json',
            data: json
          }).then(function (response) {
            if (response.data.status) {
              console.log("success remove money");
            } else {
              console.log("error remove money");
            }
            self.$nextTick(() => {
              self.displayLoaderRecalculate = false;
            });
          }).catch(function (e) {
            self.alert_message = "Ошибка загрузки данных. " + e;
            self.alert_header = "Внимание!";
            self.alert_switch = true;
            self.$nextTick(() => {
              self.displayLoaderRecalculate = false;
            });
          });
        }
      });
    },
    recalculateOrder() {
      this.displayLoaderRecalculate = true;
      let self = this;
      let json = { item: this.editOr };
      axios({
        method: 'post',
        url: this.restUrl + "/rest/admin/orders/recalculate_order",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        if(response.data.status) {
          self.editOr = response.data.item;
        }
        self.$nextTick(() => { self.displayLoaderRecalculate = false; });
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.$nextTick(() => { self.displayLoaderRecalculate = false; });
      });
    },
    updateOrder() {
      this.editOr.storeId = this.selectedStore.id;
      this.editOr.type = 1;
      this.editOr.status = 1;
      // this.editOr.clientId = this.profile.id;
      // this.editOr.name = this.profile.name;
      this.editOr.nameCreator = this.profile.name;
      this.editOr.managerId = this.profile.id;
      // this.editOr.email = this.profile.email;
      // this.editOr.phone = this.profile.phoneLast.length > 0 ? this.profile.phoneLast : this.profile.phone;
      // this.editOr.address = this.profile.addressLast;
      if(this.selectedStore.shippings.length > 0) this.editOr.shippingId = this.selectedStore.shippings[0].id;
    },
    add_to_cart(item) {
      if(!this.checkSelectedStoreItemsId(item.artikul, item.previousPrice)) {
        let itemO = this.$global.clone(this.templateOrIt);
        itemO.artikul = item.artikul;
        itemO.images = item.images;
        itemO.searchString = item.searchString;
        itemO.breadcrumbs = item.breadcrumbs;
        itemO.description = item.description;
        itemO.price = item.price;
        itemO.pp = item.previousPrice;
        itemO.dif = item.dif;
        itemO.formerId = item.formerId;
        itemO.count = 1;
        itemO.availableCount = item.count;
        this.editOr.items.unshift(itemO);
        this.recalculateOrder();
      } else {
        console.log('added');
      }
    },
    checkSelectedStoreItemsId(artikul, price) {
      for(let i = 0; i < this.editOr.items.length; i++) if(this.editOr.items[i].artikul === artikul && this.editOr.items[i].pp === price) return true;
      return false;
    },
    saveOrder(close) {
      this.displayLoader = true;
      this.willCloseEdit = close;
      let self = this;
      let startId = this.editOr.id;
      let json = { item: this.editOr };
      axios({
        method: 'post',
        url: this.restUrl + "/rest/admin/orders/save_order",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        if(response.data.status) {
          self.$nextTick(() => {
            if (!response.data.item.hasErrors && close) {
              self.closeEditOrder();
            } else {
              if (startId === 0) self.editOr = response.data.item;
            }
          });
        }
        self.$nextTick(() => { self.displayLoader = false; });
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.$nextTick(() => { self.displayLoader = false; });
      });
    },
    reloadEditOrder(id) {
      let self = this;
      let json = { id: id, storeId: this.selectedStore.id };
      axios({
        method: 'post',
        url: this.restUrl + "/rest/admin/orders/reload_order",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
          self.profile = response.data.user; //current
          self.profiles = response.data.users; //available to store admins
          self.clients = response.data.clients; //available to store clients
          self.clients.unshift({id:0, name:'не выбран'});
          self.$nextTick(() => {
            self.editOrder(response.data.item);
          });
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
      });
    },
    loadOrdersData() {
      this.displayLoader = true;
      let self = this;
      let json = {storeId: this.selectedStore.id,
        oRsearch: this.search, oRsortField: this.oRsortField, oRsortAsc: this.oRsortAsc,
        oRselectedTypes: this.oRselectedTypes, oRselectedStatuses: this.oRselectedStatuses, oRselectedManagers: this.oRselectedManagers, oRselectedClients: this.oRselectedClients,
        oRpage: this.oRpage - 1, oRelPerPag: this.oRelPerPage,
        startDate: this.filterStartDateObj.date,
        endDate: this.filterEndDateObj.date};
      axios({
        method: 'post',
        url: this.restUrl + "/rest/admin/orders/orders/data",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        self.profile = response.data.user; //current
        self.profiles = response.data.users; //available to store admins
        self.clients = response.data.clients; //available to store clients
        self.clients.unshift({id:0, name:'не выбран'});
        self.cabinetData.orders = response.data.orders.content;
        self.oRpages = response.data.orders.totalPages;
        if (self.oRpage > self.oRpages && self.oRpages !== 0) self.oRpage = self.oRpages;
        if(self.firstLoad) { self.$nextTick(() => { self.updateOrder(); }); }
        self.$nextTick(() => { self.displayLoader = false; });
        self.firstLoad = false;
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.$nextTick(() => { self.displayLoader = false;  });
      });
    },
    getData() {
      this.displayLoader = true;
      let self = this;
      let json = {};
      axios({
        method: 'post',
        url: this.restUrl + "/rest/admin/orders/data",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        self.stores = response.data.stores;
        self.typesOr = response.data.typesOr;
        if(self.stores.length > 0) self.selectedStore = self.stores[0];
        self.templateOr = response.data.templateOr;
        self.templateOrIt = response.data.templateOrIt;
        self.oRselectedManagers = [];
        self.oRselectedManagers = [];
        self.$nextTick(() => {
          self.clearOrder();
        });
        self.displayLoader = false;
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.displayLoader = false;
      });
    },
    clearOrder() {
        //console.log(this.editOr.id)
        this.editOr = this.$global.clone(this.templateOr);
        // console.log(this.editOr.id)
    },
    checkOrderHasErrors() {
      if(this.editOr.items.length > 0) {
        for (let i of this.editOr.items) if (i.error.length > 0) return true;
      }
      return false;
    },
    getShippingName(id) {
      for(let s of this.selectedStore.shippings) if(s.id===id) return s.name;
    },
    getFieldFromStatusOr(typeId, statusId, field) {
      for(let t of this.typesOr) if(typeId === t.id) for(let s of t.statuses) if(statusId === s.id) return s[field];
      return '';
    },
    expand(id) {
      if(!this.expanded.includes(id)) this.expanded.push(id);
      else {
        for(let i=0;i<this.expanded.length; i++) {
          if(this.expanded[i] === id) {
            this.expanded.splice(i,1);
            break;
          }
        }
      }
    },
  },
  watch: {
    // '$store.state.storesUpdater'(val) {
    //   if(val.storeId === this.selectedStore.id) {
    //     this.loadOrdersData();
    //   }
    // },
    '$store.state.ordersUpdater'(val) {
      if(val.storeId === this.selectedStore.id) {
        this.loadOrdersData();
        if(this.editOrderDialog && ((this.editOr.id === val.orderId) || (this.editOr.id === 0 && this.user.id === val.userId)) && !this.willCloseEdit) {
          this.reloadEditOrder(val.orderId);
        }
      }

    },
    '$vuetify.display.mdAndUp'(val) {
      this.m = !val;
    },
    selectedStore(val) {
      if (val) {
        this.firstLoad = true;
        this.iTpages = 0;
        this.iTpage = 1;
        this.oRpages = 0;
        this.oRpage = 1;
        this.clearOrder();
        this.updateOrder();
        this.loadOrdersData(val);
      } else {
        this.cabinetData = {items: [], orders: []};
      }
    },
    search() {
      if (!this.firstLoad) this.loadOrdersData();
    },
    oRsortAsc() {
      if (!this.firstLoad) this.loadOrdersData();
    },
    'filterStartDateObj.date'() {
      if (!this.firstLoad) this.loadOrdersData();
    },
    'filterEndDateObj.date'() {
      if (!this.firstLoad) this.loadOrdersData();
    },
    oRsortField() {
      if (!this.firstLoad) this.loadOrdersData();
    },
    oRelPerPage(val) {
      if (val < 1) this.oRelPerPage = 1;
      else if (!this.firstLoad) this.loadOrdersData();
    },
    oRshowZeroProducts() {
      if (!this.firstLoad) this.loadOrdersData();
    },
    oRshowCountLessThen() {
      if (!this.firstLoad) this.loadOrdersData();
    },
    oRselectedStatuses() {
      if (!this.firstLoad) this.loadOrdersData();
    },
    oRselectedManagers() {
      if (!this.firstLoad) {
        let result = [];
        if(this.oRselectedManagers.length === 0) {
          for(let i = 1; i < this.clients.length; i++) result.push(this.clients[i]);
          this.oRfilteredClients = result;
        }
        else {
          for (let c of this.clients) if (this.oRselectedManagers.includes(c.ownerId)) result.push(c);
          let newSelection = [];
          for(let c of result) if(this.oRselectedClients.includes(c.id)) newSelection.push(c.id);
          this.oRfilteredClients = result;
          this.oRselectedClients = newSelection;
        }
        this.loadOrdersData();
      }
    },
    oRselectedClients() {
      if (!this.firstLoad) this.loadOrdersData();
    },
    oRpage(val) {

      if (val < 1) this.oRpage = 1;
      else if (val > this.oRpages) this.oRpage = this.oRpages
      else if (!this.firstLoad) this.loadOrdersData();
    },
  },
  computed: {
    isDark() {
      return useTheme().global.name.value === 'dark';
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    user() {
      return this.$store.state.auth.user;
    },
  },
};
</script>
