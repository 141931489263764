<template>
  <default-layout-dynamic>

    <h1>СТАТИСТИКА</h1>

    <loader @close="displayLoader = false" v-model="displayLoader"></loader>
    <ahtung @close="alert_switch = false" :value="alert_switch" :message="alert_message" :header="alert_header"></ahtung>

  </default-layout-dynamic>
</template>

<script>

import DefaultLayoutDynamic from "../components/layouts/DefaultLayoutDynamic";
import Loader from "../components/Loader";
import axios from "axios";
import Ahtung from "../components/Ahtung";
import router from "@/router/router";

export default {
  components: {
    DefaultLayoutDynamic,
    Loader,
    Ahtung
  },
  data() {
    return {
      restUrl: process.env.VUE_APP_BACKEND_BASE_URL,
      displayLoader: false,

      alert_switch: false,
      alert_message: "",
      alert_header: "",

      m: true,
    }
  },
  name: "Settings",
  mounted() {
    this.m = !this.$vuetify.display.mdAndUp;
  },
  created() {
    if (!this.loggedIn) router.push('/start')
    else {
      this.getData();
    }
  },
  methods: {

    openDialog(id) {
      this.getItems(id);
      this[id + 'Dialog'] = true;
    },

    getData() {
      this.displayLoader = true;
      let self = this;
      let json = {};
      axios({
        method: 'post',
        url: this.restUrl + "/rest/statistic/data",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        console.log(response);
        self.displayLoader = false;
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.displayLoader = false;
      });
    },

  },
  watch: {
    '$vuetify.display.mdAndUp'(val) {
      this.m = !val;
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    user() {
      return this.$store.state.auth.user;
    },
  },
};
</script>
