<style>
.v-lazy-component.v-lazy-component--loading {
  filter: blur(15px);
}

.v-lazy-component.v-lazy-component--loaded {
  filter: blur(0);
  transition: filter 0.4s;
}
.cells-wrapper-mobile {
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin-bottom: 3px;
  font-size: 10px;
  text-align: center;
}
.cell-mobile {
  border-bottom: 1px solid #dedede;
}
</style>
<template>

  <v-dialog :fullscreen="mobile" :model-value="show" persistent :scrollable="true">
    <v-card style="overflow-x: hidden">

      <v-card-title class="headline">
      <v-row :no-gutters="true">
        <v-col cols="10">
          <span :style="'margin-left: 14px; font-size:' + (mobile ? '10px':'20px')"><b>Настройки ценообразователей</b> {{total}}/{{selectedItemIds.length}}</span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col style="text-align: right">
          <v-tooltip style="display: inline-block" top>
            <template v-slot:activator="{ props: tooltip }">
              <v-btn v-bind="tooltip" icon :size="(mobile ?'x-small':'x-small')" @click="hide"><v-icon>mdi-close</v-icon></v-btn>
            </template>
            <span>Закрыть</span>
          </v-tooltip>
        </v-col>
      </v-row>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-row :dense="true">
          <v-col align-self="center" cols="12" md="6">
            <v-text-field variant="solo" :density="'compact'" :hide-details="true" v-model="val.search" @change="$emit('refresh')" label="Фильтр по тексту"></v-text-field>
          </v-col>
          <v-col align-self="center" cols="12" md="6">
            <v-select variant="solo" :items="stores" item-title="name" item-value="id" multiple :density="'compact'" :hide-details="true" v-model="val.stores" @update:modelValue="$emit('refresh')" label="Фильтр по складам"></v-select>
          </v-col>
        </v-row>

        <v-row :dense="true" v-if="($global.checkRoles(user, ['SUDO'], ['ADMIN']))">
          <v-col align-self="center" cols="12" md="3">
            <v-select variant="solo" :items="stores" item-title="name" :return-object="true" multiple :density="'compact'" :hide-details="true" v-model="selectedStoresForCopy" label="Склады для копирования"></v-select>
          </v-col>
          <v-col align-self="center" cols="4" md="3">
            <v-text-field variant="solo" :density="'compact'" :hide-details="true" v-model="val.pp" type="number" @change="$emit('refresh')" label="Шт/стр"></v-text-field>
          </v-col>
          <v-col align-self="center" cols="4" md="3">
            <v-btn :block="true" @click="copy()"><v-icon>mdi-content-copy</v-icon><span :style="mobile ? 'font-size: 10px;' : ''">{{(selectedItemIds.length > 0 ? '(' + selectedItemIds.length + ')' : (val.stores.length > 0 ? '(' + getStoreName(val.stores[0]) + ')' : ''))}}</span></v-btn>
          </v-col>
          <v-col align-self="center" cols="4" md="3">
            <v-btn :block="true" @click="editItem(template)"><v-icon>mdi-plus</v-icon></v-btn>
          </v-col>

        </v-row>

        <v-col cols="12" v-if="items.length > 0 && val.pages > 1">
          <v-pagination v-model="val.page" :length="val.pages" :rounded="true" size="x-small"></v-pagination>
        </v-col>


        <v-table v-if="!mobile" :fixed-header="true" height="600" :density="'compact'">
          <thead>
          <tr>
            <th :class="'text-left ' +  (i > 0 ? '' : 'd-inline-flex')" v-for="(h, i) in headers" :key="h.text">
              {{h.text}}
              <v-checkbox v-if="i === 0" density="compact" :hide-details="true" :model-value="checkSelectedItemsIds()" @change="selectAll()"></v-checkbox>
            </th>
          </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id"  @dblclick="editItem(item)">
             <td class="d-flex">
               {{ item.id }}
               <v-checkbox density="compact" :hide-details="true" :model-value="checkSelectedItemsId(item.id)" @change="checkSelectedItems(item)"></v-checkbox>
             </td>
             <td>{{ item.name }}</td>
              <td>
                <v-icon v-if="item.enabled" small>mdi-check</v-icon>
                <v-icon v-else small>mdi-close</v-icon>
              </td>
              <td>{{ item.storeName }}</td>
              <td>{{ item.breadcrumbs }}</td>
             <td>{{ item.createdByName }} / {{ $global.displayDate(item.dateCreation, 'DD.MM.YYYY HH:mm')}}</td>

            </tr>
          </tbody>
        </v-table>

        <div v-else>
        <v-checkbox density="compact" :hide-details="true" label="Выделить все" :model-value="checkSelectedItemsIds()" @change="selectAll()"></v-checkbox>
        <lazy-component  wrapper-tag="table" class="cells-wrapper-mobile" v-for="item in items" :key="item.id">
          <tbody>

          <tr @click="editItem(item)">
            <td class="cell-mobile" style="width: 100%; text-align: left;" colspan="4">
              <b style="color: #0f8107">{{ item.name }}</b>
            </td>
          </tr>
          <tr @click="editItem(item)">
            <td class="cell-mobile" style="width: 100%; text-align: left;" colspan="4">
              {{ item.breadcrumbs }}
            </td>
          </tr>

          <tr>
            <td class="cell-mobile" style="width: 15%">
              <v-checkbox density="compact" :hide-details="true" :model-value="checkSelectedItemsId(item.id)" @change="checkSelectedItems(item)"></v-checkbox>
            </td>
            <td @click="editItem(item)" class="cell-mobile" style="width: 15%">
              <b>{{ item.id }}</b>
            </td>
            <td @click="editItem(item)" class="cell-mobile" style="border-right: 1px solid #dedede; width: 30%;">
              {{ item.storeName }}
            </td>
            <td @click="editItem(item)" class="cell-mobile" style="border-right: 1px solid #dedede; width: 40%;" colspan="2">
              {{ item.createdByName }} / {{ $global.displayDate(item.dateCreation, 'DD.MM.YYYY HH:mm')}}
            </td>
          </tr>

          </tbody>
          <template #placeholder>
            <tr>
              <td colspan="4">Loading...</td>
            </tr>
          </template>
        </lazy-component>
        </div>
      </v-card-text>

    </v-card>

    <v-dialog :fullscreen="mobile" v-model="editDialog" :scrollable="true" persistent>
      <v-card v-if="item && editDialog">
        <v-card-title class="headline">
          <v-row danse>
            <v-col cols="7">
              <span :style="'margin-left: 14px; font-size:' + (mobile ? '10px':'20px')"><b>{{ (item.id === 0 ? 'Создание нового элемента' : 'Редактирование элемента #' + item.id) }}</b></span>
            </v-col>
              <v-col style="text-align: right">
                  <v-tooltip location="left" v-if="item.id > 0 && $global.checkRoles(this.user, ['SUDO', 'ADMIN'])">
                      <template v-slot:activator="{ props: tooltip }">
                          <v-btn style="display: inline" v-bind="tooltip" icon :size="(mobile ? 'x-small' : 'x-small')" @click="getLogs()"><v-icon>mdi-information-variant</v-icon></v-btn>
                      </template>
                      <span>Лог</span>
                  </v-tooltip>
                <v-tooltip style="display: inline-block" top>
                  <template v-slot:activator="{ props: tooltip }">
                    <v-btn v-bind="tooltip" icon :size="(mobile ?'x-small':'x-small')" @click="cancelEditItem()"><v-icon>mdi-close</v-icon></v-btn>
                  </template>
                  <span>Закрыть</span>
                </v-tooltip>
              </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text v-if="item">

          <v-row :dense="true">
            <v-col cols="12">
              <v-text-field variant="solo" density="compact" :hide-details="true" label="Имя" v-model="item.name"></v-text-field>
              <v-checkbox variant="solo" :hide-details="true" density="compact" label="Включен?" v-model="item.enabled"></v-checkbox>
              <v-select variant="solo" :items="filteredMain" v-model="selectedStore" return-object item-title="name" density="compact" label="Привязка склада"></v-select>

              <catalog-search search-type="mixed" :url="'/rest/settings/search_catalog'" @select-item="catalogItemSelected" :mobile="mobile"></catalog-search>
              <span style="font-size: 11px;">Привязка: <span style="color: #0f8107;">{{ item.breadcrumbs }}</span></span>



              <v-row :dense="true">
                <template v-if="$global.checkRoles(this.user, ['SUDO']) || ($global.checkRoles(this.user, ['ADMIN']) && profile.pf_stores_on)">
                  <v-col cols="12" md="6">
                    СКЛАДЫ <v-btn icon size="x-small" @click="item.wholesale.push($global.clone(templateStage))"><v-icon>mdi-plus</v-icon></v-btn>
                    <v-row :dense="true" v-for="(w, index) in  item.wholesale" :key="index"  style="border: 1px solid white">
                      <v-col cols="6">
                        <v-select variant="solo" :hide-details="true" :items="types" v-model="w.type" density="compact" label="Тип наценки"></v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field variant="solo" :hide-details="true" density="compact" label="Величина наценки" type="number" v-model="w.value"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-checkbox :hide-details="true" density="compact" :label="w.countMoney ? 'от суммы заказа':'от кол-ва единиц в заказе'" v-model="w.countMoney"></v-checkbox>
                      </v-col>
                      <v-col cols="5">
                        <v-text-field variant="solo" :hide-details="true" density="compact" :label="w.countMoney ? 'суммы заказа >':'кол-ва единиц >'" type="number" v-model="w.orderValue"></v-text-field>
                      </v-col>
                      <v-col cols="1">
                        <v-btn icon size="x-small" @click="item.wholesale.splice(index, 1)"><v-icon>mdi-delete-forever</v-icon></v-btn>
                      </v-col>
                      <v-col cols="12">
                        <v-checkbox :hide-details="true" density="compact" :label="w.groupedFormerOnly ? 'по группировке ценообразователя':'по всему заказу'" v-model="w.groupedFormerOnly"></v-checkbox>
                      </v-col>
                    </v-row>
                 </v-col>
                </template>
                <template v-if="$global.checkRoles(this.user, ['SUDO']) || ($global.checkRoles(this.user, ['ADMIN']) && profile.pf_opt_on)">
                  <v-col cols="12" md="6">
                    ОПТ <v-btn icon size="x-small" @click="item.retail.push($global.clone(templateStage))"><v-icon>mdi-plus</v-icon></v-btn>
                    <v-row :dense="true" v-for="(r, index) in  item.retail" :key="index" style="border: 1px solid white">
                      <v-col cols="6">
                        <v-select variant="solo" :items="types" v-model="r.type" density="compact"  :hide-details="true" label="Тип наценки"></v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field variant="solo" density="compact" label="Величина наценки" :hide-details="true" type="number" v-model="r.value"></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-checkbox :hide-details="true" density="compact" :label="r.countMoney ? 'от суммы заказа':'от кол-ва единиц в заказе'" v-model="r.countMoney"></v-checkbox>
                      </v-col>
                      <v-col cols="5">
                        <v-text-field variant="solo" :hide-details="true" density="compact" :label="r.countMoney ? 'суммы заказа >':'кол-ва единиц >'" type="number" v-model="r.orderValue"></v-text-field>
                      </v-col>
                      <v-col cols="1">
                        <v-btn icon size="x-small" @click="item.retail.splice(index, 1)"><v-icon>mdi-delete-forever</v-icon></v-btn>
                      </v-col>
                      <v-col cols="12">
                        <v-checkbox :hide-details="true" density="compact" :label="r.groupedFormerOnly ? 'по группировке ценообразователя':'по всему заказу'" v-model="r.groupedFormerOnly"></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-col>
                </template>
              </v-row>
            </v-col>
          </v-row>

        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-row :dense="true">
            <v-col cols="6" style="text-align: left">
              <v-btn x-small @click="saveItem()">
                <span>Сохранить</span></v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <loader @close="displayLoader = false" v-model="displayLoader"></loader>
    <ahtung @close="alert_switch = false" :value="alert_switch" :message="alert_message" :header="alert_header"></ahtung>
      <log-dialog :items="logs" :show="showLogs" @close="closeLogs()" :mobile="mobile"></log-dialog>

  </v-dialog>


</template>

<script>

import Loader from "../Loader";
import Ahtung from "../Ahtung";
import CatalogSearch from "@/components/common/CatalogSearch";
import axios from "axios";
import LogDialog from "@/components/common/LogDialog.vue";
import {computed} from "vue";

export default {
  components: {
      LogDialog,
    Loader,
    Ahtung,
    CatalogSearch
  },
  props: {
    modelValue: {type: Object, required: true, default() {return {}}}, //pagination
    show: {type: Boolean, required: true, default: false},
    mobile: {type: Boolean, required: true, default: false},
    items: {type: Array, required: true, default() {return []}},
    total: {type: Number, required: true, default: 0},
    stores: {type: Array, required: true, default() {return []}},
    types: {type: Array, required: true, default() {return []}},
    profile: {type: Object, required: true, default() {return {}}},
    template: {type: Object, required: true, default() {return {}}},
    templateStage: {type: Object, required: true, default() {return {}}},
  },
  setup(props, { emit }) {
    const val = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });
    return { val };
  },
  data() {
    return {
      windowWidth: 0,
      restUrl: process.env.VUE_APP_BACKEND_BASE_URL,
      displayLoader: false,
      headers: [
        {text: 'ID', value: 'id', field: 'id', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Название', value: 'name', field: 'name', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Включен', value: 'enabled', field: 'enabled', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Склад', value: 'store', field: 'store', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Каталог', value: 'catalog', field: 'catalog', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Создатель/Дата', value: 'creation', field: 'creation', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
      ],
      item: {},
      editDialog: false,

      alert_switch: false,
      alert_message: "",
      alert_header: "",

      showSearchCatalogResult: false,
      catalogSearchString: '',
      searchedCatalogItems: [],

      selectedStore: {id: 0, name: 'не выбран'},
      selectedStoresForCopy: [],
      selectedItemIds: [],

        showLogs: false,
        logs: [],
    }
  },
  watch: {
    selectedStore(val) {
      this.item.storeId = val.id;
      this.item.storeName = val.name;
      console.log(this.item);
    },
  },
  mounted() {
    this.windowWidth = document.documentElement.clientWidth;
    window.addEventListener('resize', this.onResize, {passive: true});
  },
  computed: {
    getModalWidth() {
      return (this.windowWidth <= 1020 ? '98%' : '1000');
    },
    filteredMain() {
      let result = [];
      try {
        result.push({id: 0, name: 'не выбран'});
        for (let item of this.stores) result.push(item);
      } catch (e) {
        console.log(e);
      }
      return result;
    },
    user() {
      return this.$store.state.auth.user;
    },
  },

  methods: {
    getStoreName(id) {
      for(let s of this.stores) if(s.id === id) return s.name;
      return '';
    },
    getLogs() {
      let self = this;
      let json = { id : this.item.id, type: 'PRICE_FORMER' };
      axios({
        method: 'post',
        url: this.restUrl + "/rest/settings/logs/get",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        if(response.data.status) {
          self.logs = response.data.logs;
          self.showLogs = true;
        }
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
      });
    },
    checkSelectedItemsId(id) {
      for(let i=0; i < this.selectedItemIds.length; i++) {
        if(this.selectedItemIds[i] === id) {
          return true;
        }
      }
      return false;
    },
    checkSelectedItemsIds() {
      return this.selectedItemIds.length > 0;
    },
    selectAll() {
      if(this.checkSelectedItemsIds()) this.selectedItemIds = [];
      else {
        this.selectedItemIds = [];
        for(let item of this.items) this.selectedItemIds.push(item.id);
      }
    },
    checkSelectedItems(item) {
      let index = -1;
      for(let i=0; i<this.selectedItemIds.length; i++) {
        if(this.selectedItemIds[i] === item.id) {
          index = i;
          break;
        }
      }
      if(index >= 0) this.selectedItemIds.splice(index, 1);
      else this.selectedItemIds.push(item.id);
    },
    copy() {
          let self = this;
          self.displayLoader = true;
          let json = { item : {pfIds: this.selectedItemIds, fromStoreId: (this.val.stores.length > 0 ? this.val.stores[0] : 0), toStores: this.selectedStoresForCopy }};
          axios({
              method: 'post',
              url: this.restUrl + "/rest/settings/pf/copy",
              headers: this.$global.headers(this.$store.state.auth.user),
              dataType: 'json',
              data: json
          }).then(function (response) {
              if(response.data.status) {
                self.$emit('refresh');
              } else {
                self.alert_message = "Ничего не скопировано. ";
                self.alert_header = "Внимание!";
                self.alert_switch = true;
              }
          }).catch(function (e) {
              self.alert_message = "Ошибка загрузки данных. " + e;
              self.alert_header = "Внимание!";
              self.alert_switch = true;
          });
      self.displayLoader = false;
      },
      closeLogs() {
          this.logs=[];
          this.showLogs = false;
      },
    catalogItemSelected(cat) {
      this.item.catalogId = cat.id;
      this.item.breadcrumbs = cat.text;
    },
    editItem(item) {
      try {
        this.item = this.$global.clone(item);
        for (let store of this.filteredMain) if (store.id === this.item.storeId) {
          this.selectedStore = store;
          break;
        }
        if (this.item.id === 0) this.item.createdBy = this.user.id;
        this.editDialog = true;
      } catch (e) {
        console.log(e);
      }
    },
    cancelEditItem() {
      this.item = {};
      this.editDialog = false;
    },
    saveItem() {
      // if(true) {
        this.$emit('save-item', this.item, 'pf');
        this.cancelEditItem();
      // } else {
      //   this.alert_message = "Заполните обязательные поля.";
      //   this.alert_header = "Внимание!"
      //   this.alert_switch = true;
      // }
    },

    hide() {
      this.$emit('close');
    },
    onResize() {
      this.windowWidth = document.documentElement.clientWidth;
    },
  },

};
</script>
