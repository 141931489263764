<style lang="scss" scoped>
.wrapper-border:hover {
  border-bottom: 1px solid grey
}
.menu-cl-d {
  background-color: black;
}
.menu-cl-l {
  background-color: white;
}
</style>
<template>


    <table style="border-radius: 1px; width: 100%; border-collapse: collapse; font-size: 12px">
      <loader @close="displayLoader = false" v-model="displayLoader"></loader>
      <tr class="wrapper-border" @dblclick="expand(wrapper.id)" :style="getWbColor(wrapper)">
        <td style="width: 6%">{{ wrapper.id }}</td>
        <td style="width: 26%">{{ getFieldFromTypeWb(wrapper.type, 'nameR') }} <v-icon x-small v-if="wrapper.type === 3">{{ getArrow(wrapper) }}</v-icon></td>
        <td style="width: 26%">{{ getFieldFromStatusWb(wrapper.type, wrapper.status, 'nameR') }}</td>
        <td style="width: 13%">{{ $global.displayDate(wrapper.dateCreation, 'DD.MM.YYYY') }}</td>
        <td style="width: 29%">

          <v-tooltip style="display: inline-block" top>
            <template v-slot:activator="{ props: tooltip }">
              <v-btn size="x-small" v-bind="tooltip" icon="mdi-arrow-down" @click="expand(wrapper.id)"></v-btn>
            </template>
            <span>Развернуть</span>
          </v-tooltip>

<!--          oprihod-->
          <template v-if="wrapper.type === 1">
            <v-tooltip style="display: inline-block" top>
              <template v-slot:activator="{ props: tooltip }">
                <v-btn size="x-small" v-bind="tooltip" v-if="wrapper.status === 1" icon="mdi-check" @click="apply(wrapper.id)"></v-btn>
              </template>
              <span>Применение на склад</span>
            </v-tooltip>
            <v-tooltip style="display: inline-block" top>
              <template v-slot:activator="{ props: tooltip }">
                <v-btn size="x-small" v-bind="tooltip" v-if="wrapper.status === 1" icon="mdi-table-edit" @click="edit(wrapper, 'oprihodovanie')"></v-btn>
              </template>
              <span>Редактировать</span>
            </v-tooltip>
            <v-tooltip style="display: inline-block" top>
              <template v-slot:activator="{ props: tooltip }">
                <v-btn size="x-small" v-bind="tooltip" v-if="wrapper.status === 2 || wrapper.status === 3" icon="mdi-eye" @click="edit(wrapper, 'oprihodovanie')"></v-btn>
              </template>
              <span>Просмотр</span>
            </v-tooltip>
            <v-tooltip style="display: inline-block" top>
              <template v-slot:activator="{ props: tooltip }">
                <v-btn size="x-small" v-bind="tooltip" v-if="wrapper.status === 1" icon="mdi-close" @click="cancel(wrapper.id)"></v-btn>
              </template>
              <span>Отменить</span>
            </v-tooltip>

          </template>

<!--          zakaz-->
          <template v-if="wrapper.type === 3">
            <v-tooltip style="display: inline-block" top>
              <template v-slot:activator="{ props: tooltip }">
                <v-btn size="x-small" v-bind="tooltip" v-if="selStoreId === wrapper.dispatchStoreId || wrapper.status === 1" icon="mdi-table-edit" @click="edit(wrapper, 'zakaz')"></v-btn>
              </template>
              <span>Редактировать</span>
            </v-tooltip>
            <v-tooltip style="display: inline-block" top>
              <template v-slot:activator="{ props: tooltip }">
                <v-btn size="x-small" v-bind="tooltip" v-if="selStoreId === wrapper.storeId &&  wrapper.status > 1" icon="mdi-eye" @click="edit(wrapper, 'zakaz')"></v-btn>
              </template>
              <span>Просмотр</span>
            </v-tooltip>
            <v-tooltip style="display: inline-block" top>
              <template v-slot:activator="{ props: tooltip }">
                <v-btn size="x-small" v-bind="tooltip" v-if="wrapper.status === 1" icon="mdi-close" @click="cancel(wrapper.id)"></v-btn>
              </template>
              <span>Отменить</span>
            </v-tooltip>
            <v-tooltip style="display: inline-block" top>
              <template v-slot:activator="{ props: tooltip }">
                <v-btn size="x-small" v-bind="tooltip" v-if="wrapper.status === 3 && checkClient" icon="mdi-checkbox-multiple-marked-circle-outline" @click="receive(wrapper.id)"></v-btn>
              </template>
              <span>Товар принят</span>
            </v-tooltip>

            <v-menu v-if="!checkClient && wrapper.status === 5" location="bottom" :open-on-click="false" :close-on-content-click="false" width="150" v-model="addMoneySwitch[wrapper.id]">
              <template v-slot:activator="{ props }">
                <v-btn :disabled="!(wrapper.status === 4 || wrapper.status === 5) || wrapper.sum <= wrapper.paySum" size="x-small" v-bind="props" icon="mdi-currency-usd" @click="add_money(wrapper)"></v-btn>
              </template>
              <v-row :class="'menu-cl-' + (isDark ? 'd':'l')" dense align-content="center" align="center" justify="center">
                <v-col cols="12" align-self="center">
                  <v-text-field variant="solo" :ref="'amtf' + wrapper.id"  @keyup.enter="submit_add_money(wrapper.id)" @keyup.esc="close_add_money(wrapper.id)"  density="compact" :hide-details="true" type="number" v-model.number="addMoneyValue[wrapper.id]" label="BYN"></v-text-field>
                  <v-text-field variant="solo" density="compact" :hide-details="true" v-model="addMoneyComment[wrapper.id]" label="Комментарий"></v-text-field>
                  <v-btn size="x-small" icon="mdi-checkbox-marked-circle-outline" :disabled="addMoneyValue[wrapper.id] <= 0" @click="submit_add_money(wrapper.id)"></v-btn>
                  <v-btn size="x-small" icon="mdi-cancel" @click="close_add_money(wrapper.id)"></v-btn>
                </v-col>
              </v-row>
            </v-menu>

          </template>

          <!--          revis-->
          <template v-if="wrapper.type === 4">
            <v-tooltip style="display: inline-block" top>
              <template v-slot:activator="{ props: tooltip }">
                <v-btn size="x-small" v-bind="tooltip" v-if="wrapper.status === 1" icon="mdi-check" @click="apply(wrapper.id)"></v-btn>
              </template>
              <span>Применение на склад</span>
            </v-tooltip>
            <v-tooltip style="display: inline-block" top>
              <template v-slot:activator="{ props: tooltip }">
                <v-btn size="x-small" v-bind="tooltip" v-if="wrapper.status === 1" icon="mdi-table-edit" @click="edit(wrapper, 'revision')"></v-btn>
              </template>
              <span>Редактировать</span>
            </v-tooltip>
            <v-tooltip style="display: inline-block" top>
              <template v-slot:activator="{ props: tooltip }">
                <v-btn size="x-small" v-bind="tooltip" v-if="wrapper.status === 2 || wrapper.status === 3" icon="mdi-eye" @click="edit(wrapper, 'revision')"></v-btn>
              </template>
              <span>Просмотр</span>
            </v-tooltip>
            <v-tooltip style="display: inline-block" top>
              <template v-slot:activator="{ props: tooltip }">
                <v-btn size="x-small" v-bind="tooltip" v-if="wrapper.status === 1" icon="mdi-close" @click="cancel(wrapper.id)"></v-btn>
              </template>
              <span>Отменить</span>
            </v-tooltip>

          </template>


        </td>
      </tr>
      <tr v-if="expanded.includes(wrapper.id)">
        <td colspan="5">
          <div style="max-height: 200px; overflow-y: auto;">
            <table style="border-radius: 5px; width: 100%; font-size: 9px;" border="1">
              <tr>
                <td>Арт</td>
                <td>Назв</td>
                <td>Кол</td>
                <td v-if="wrapper.type === 4">КолРс</td>
                <td>Цен</td>
                <td>Сум</td>
                <td v-if="wrapper.type === 3 && (!checkClient || (checkClient && wrapper.hasPresents))">Под</td>
                <td v-if="wrapper.type === 3 && (!checkClient || (checkClient && profile.returning_allowed_brak))">Брк</td>
                <td v-if="wrapper.type === 3 && (!checkClient || (checkClient && profile.returning_allowed))">Взв</td>
              </tr>
              <tr v-for="(it, index) of wrapper.items" :key="index">
                <td>{{it.artikul}}</td>
                <td>{{it.breadcrumbs}}</td>
                <td>{{it.count}}</td>
                <td v-if="wrapper.type === 4">{{it.countEstimated}}</td>
                <td>{{it.price}}</td>
                <td v-if="wrapper.type === 4">{{ ((it.countEstimated - it.count) * it.price).toFixed(2) }}</td>
                <td v-else>{{ (it.count * it.price).toFixed(2) }}</td>

                <td v-if="wrapper.type === 3 && checkClient && wrapper.hasPresents">{{it.present}}</td>
                <td v-else-if="wrapper.type === 3 && !checkClient  && (wrapper.status === 8 || wrapper.hasPresents)" ><v-tooltip location="bottom"><template v-slot:activator="{ props: tooltip }"><span v-bind="tooltip" v-show="!wrapper.returnsApplied && [8].includes(wrapper.status) && !(it.brak + it.ret + it.present === it.count)" class="plus_minus" @click="increase(it, 1, 'present', wrapper.id)">+</span></template><span>Добавить подарок</span></v-tooltip>&nbsp;{{ it.present }}&nbsp;<v-tooltip location="bottom"><template v-slot:activator="{ props: tooltip }"><span v-bind="tooltip" v-show="!wrapper.returnsApplied && [8].includes(wrapper.status) && !(it.present === 0)" class="plus_minus" @click="increase(it, -1, 'present', wrapper.id)">-</span></template><span>Уменьшьть подарки</span></v-tooltip></td>

                <td v-if="wrapper.type === 3 && checkClient && profile.returning_allowed_brak && wrapper.status === 8"><v-tooltip location="bottom"><template v-slot:activator="{ props: tooltip }"><span v-bind="tooltip" v-show="!wrapper.returnsApplied && ![6, 7].includes(wrapper.status) && !(it.brak + it.ret === it.count)" class="plus_minus" @click="increase(it, 1, 'brak', wrapper.id)">+</span></template><span>Добавить брак</span></v-tooltip>&nbsp;{{ it.brak }}&nbsp;<v-tooltip location="bottom"><template v-slot:activator="{ props: tooltip }"><span v-bind="tooltip" v-show="!wrapper.returnsApplied && ![6, 7].includes(wrapper.status) && !(it.brak === 0)" class="plus_minus" @click="increase(it, -1, 'brak', wrapper.id)">-</span></template><span>Уменьшить брак</span></v-tooltip></td>
                <td v-else-if="wrapper.type === 3 && !checkClient && wrapper.status === 8"><v-tooltip location="bottom"><template v-slot:activator="{ props: tooltip }"><span v-bind="tooltip" v-show="!wrapper.returnsApplied && [8].includes(wrapper.status) && !(it.brak + it.ret + it.present === it.count)" class="plus_minus" @click="increase(it, 1, 'brak', wrapper.id)">+</span></template><span>Добавить брак</span></v-tooltip>&nbsp;{{ it.brak }}&nbsp;<v-tooltip location="bottom"><template v-slot:activator="{ props: tooltip }"><span v-bind="tooltip" v-show="!wrapper.returnsApplied && ![6, 7].includes(wrapper.status) && !(it.brak === 0)" class="plus_minus" @click="increase(it, -1, 'brak', wrapper.id)">-</span></template><span>Уменьшить брак</span></v-tooltip></td>

                <td v-if="wrapper.type === 3 && checkClient && profile.returning_allowed && wrapper.status === 8"><v-tooltip location="bottom"><template v-slot:activator="{ props: tooltip }"><span v-bind="tooltip" v-show="!wrapper.returnsApplied && ![6, 7].includes(wrapper.status) && !(it.brak + it.ret === it.count)" class="plus_minus" @click="increase(it, 1, 'ret', wrapper.id)">+</span></template><span>Добавить возврат</span></v-tooltip>&nbsp;{{ it.ret }}&nbsp;<v-tooltip location="bottom"><template v-slot:activator="{ props: tooltip }"><span v-bind="tooltip" v-show="!wrapper.returnsApplied && ![6, 7].includes(wrapper.status) && !(it.ret === 0)" class="plus_minus" @click="increase(it, -1, 'ret', wrapper.id)">-</span></template><span>Уменьшьть возврат</span></v-tooltip></td>
                <td v-else-if="wrapper.type === 3 && !checkClient && wrapper.status === 8"><v-tooltip location="bottom"><template v-slot:activator="{ props: tooltip }"><span v-bind="tooltip" v-show="!wrapper.returnsApplied && [8].includes(wrapper.status) && !(it.brak + it.ret + it.present === it.count)" class="plus_minus" @click="increase(it, 1, 'ret', wrapper.id)">+</span></template><span>Добавить возврат</span></v-tooltip>&nbsp;{{ it.ret }}&nbsp;<v-tooltip location="bottom"><template v-slot:activator="{ props: tooltip }"><span v-bind="tooltip" v-show="!wrapper.returnsApplied && ![6, 7].includes(wrapper.status) && !(it.ret === 0)" class="plus_minus" @click="increase(it, -1, 'ret', wrapper.id)">-</span></template><span>Уменьшьть возврат</span></v-tooltip></td>
              </tr>
              <tr>
                <td></td>

                <td style="text-align: right;"><b>Итого:</b></td>

                <td>{{wrapper.totalCount}}</td>

                <td v-if="wrapper.type === 4">{{ wrapper.totalCountRevision }}</td>

                <td></td>

                <td v-if="wrapper.type === 4">{{wrapper.sumRevision}}</td>
                <td v-else>{{wrapper.sum}}</td>
<!--todo clien-->
                <td v-if="wrapper.type === 3 && checkClient && wrapper.hasPresents"></td>
                <td v-else-if="wrapper.type === 3 && !checkClient && wrapper.status === 8">
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props: tooltip }">
                      <v-icon v-bind="tooltip" v-show="!changedOrders.includes(wrapper.id) && wrapper.hasReturns && !wrapper.returnsApplied && [8].includes(wrapper.status)" size="x-small" color="#ff0000" @click="applyReturns(wrapper)">mdi-check</v-icon>
                    </template>
                    <span>Применить возвраты к заказу и складу</span>
                  </v-tooltip>
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props: tooltip }">
                      <v-icon v-bind="tooltip" v-show="!changedOrders.includes(wrapper.id) && wrapper.hasReturns && wrapper.returnsApplied && [8].includes(wrapper.status)" size="x-small" color="#ff0000" @click="rollbackReturns(wrapper)">mdi-cancel</v-icon>
                    </template>
                    <span>Отменить применение возвраты к заказу и складу</span>
                  </v-tooltip>
                </td>

                <td v-if="wrapper.type === 3 && ((checkClient && profile.returning_allowed) || !checkClient ) && wrapper.status === 8"></td>

                <td v-if="wrapper.type === 3 && ((checkClient && profile.returning_allowed) || !checkClient ) && wrapper.status === 8">
                  <v-tooltip location="bottom">
                    <template v-slot:activator="{ props: tooltip }">
                      <v-icon v-bind="tooltip" v-show="changedOrders.includes(wrapper.id)" size="x-small" color="#ff0000" @click="saveOrder(wrapper)">mdi-content-save</v-icon>
                    </template>
                    <span>Сохранить заказ</span>
                  </v-tooltip>
                </td>
              </tr>

            </table>
          </div>
        </td>
      </tr>

      <tr v-if="expanded.includes(wrapper.id)">
        <td colspan="10">
          <table style="border-radius: 5px; width: 100%; font-size: 10px;" border="1">
            <tr>
              <td>Комментарий:</td>
              <td>{{wrapper.comment}}</td>
            </tr>
          </table>
        </td>
      </tr>

      <tr v-if="expanded.includes(wrapper.id)">
        <td colspan="10">
          <table style="border-radius: 5px; width: 100%; font-size: 10px;" border="1">
            <tr v-for="pi of wrapper.payments" :key="pi.id">
              <td>{{pi.id}}</td>
              <td>{{$global.displayDate(pi.date, 'DD.MM.YYYY HH:mm')}}</td>
              <td>{{pi.value}}</td>
              <td>{{pi.currency}}</td>
              <td>{{pi.description}}</td>
              <template v-if="$global.checkRoles(user, ['SUDO', 'ADMIN']) && !checkClient">
                <td><v-icon color="#ff0000" @click="submit_remove_money(wrapper.id, pi.id)">mdi-delete-forever</v-icon></td>
              </template>
            </tr>
          </table>
        </td>
      </tr>
      <confirm-dialog ref="confirm"></confirm-dialog>
    </table>

</template>

<script>


import axios from "axios";
import {useTheme} from "vuetify";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import Loader from "@/components/Loader.vue";

export default {
  components: {
    Loader,
    ConfirmDialog,
  },
  props: {
    mobile: {type: Boolean, required: true, default: false},
    wrapper: {type: Object, required: true, default() {return {}}},
    types: {type: Array, required: true, default() {return []}},
    selStoreId: {type: Number, required: true, default: 0},
    stores: {type: Array, required: true, default() {return []}},
    profile: {type: Object, required: true, default() {return {}}},
    cashier: {type: Object, required: false, default() {return {}}},
  },
  data() {
    return {
      restUrl: process.env.VUE_APP_BACKEND_BASE_URL,
      expanded: [],
      addMoneySwitch: {},
      addMoneyValue: {},
      addMoneyComment: {},
      changedOrders: [],

      displayLoader: false,

    }
  },
  watch: {},
  mounted() {
  },
  computed: {
    isDark() {
      return useTheme().global.name.value === 'dark';
    },
    checkClient() {
      for(let st of this.stores) if(st.id === this.wrapper.dispatchStoreId && st.adminIds.includes(this.user.id)) return false;
      return true;
    },
    user() {
      return this.$store.state.auth.user;
    },
  },

  methods: {

    saveOrder(o) {
      var index = this.changedOrders.indexOf(o.id);
      if (index !== -1) this.changedOrders.splice(index, 1);
      let self = this;
      let json = { item: o };
      axios({
        method: 'post',
        url: this.restUrl + "/rest/stores/submit_order",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        if(response.data.status) {
          console.log('success');
        }
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.displayLoader = false;
      });
    },

    applyReturns(o) {
      this.willCloseEdit = false;
      let self = this;
      let json = { item: o };
      axios({
        method: 'post',
        url: this.restUrl + "/rest/stores/apply_returns",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        if(response.data.status) {
          console.log('success');
        }
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.displayLoader = false;
      });
    },
    rollbackReturns(o) {
      this.willCloseEdit = false;
      let self = this;
      let json = { item: o };
      axios({
        method: 'post',
        url: this.restUrl + "/rest/stores/rollback_returns",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        if(response.data.status) {
          console.log('success');
        }
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.displayLoader = false;
      });
    },

    increase(it, count, field, id) {
      it[field] += count;
      if(it[field] < 0) it[field] = 0;
      else if(it.brak + it.ret > it.count) it[field] -= 1;
      else if(!this.changedOrders.includes(id)) this.changedOrders.push(id);
    },
    add_money(w) {
      document.activeElement.blur();
      this.addMoneyComment[w.id] = '';
      let sum  = (w.sum - w.paySum).toFixed(2);
      if(w.dispatchStoreId !== this.selStoreId && sum > (this.cashier.cashier - this.cashier.own)) sum = (this.cashier.cashier - this.cashier.own).toFixed(2);
      this.addMoneyValue[w.id] = sum;
      if(!this.addMoneySwitch[w.id]) this.addMoneySwitch[w.id] = true;
      else this.addMoneySwitch[w.id] = !this.addMoneySwitch[w.id];
      let self = this;
      this.$nextTick(() => {
        setTimeout(() => {
          // console.log(self.$refs['amtf' + w.id]);
          self.$refs['amtf' + w.id].focus()
        }, 100)
      });
    },
    close_add_money(wbId) {
      this.addMoneySwitch[wbId] = false;
    },
    submit_add_money(wbId) {
      let self = this;
      let json = { wbId: wbId, value: this.addMoneyValue[wbId], comment: this.addMoneyComment[wbId] };
      self.displayLoaderRecalculate = true;
      axios({
        method: 'post',
        url: this.restUrl + "/rest/stores/add_money",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        if(response.data.status) {
          self.close_add_money(wbId);
        } else {
          console.log("error add money");
        }
        self.$nextTick(() => { self.displayLoaderRecalculate = false; });
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.$nextTick(() => { self.displayLoaderRecalculate = false; });
      });
    },
    submit_remove_money(wbId, piId) {
      let self = this;
      this.$refs.confirm.open('Внимание!', 'Удалить оплату?', {color: '#9C0A20'}).then((result) => {
        if(result) {
          let json = {wbId: wbId, piId: piId};
          self.displayLoaderRecalculate = true;
          axios({
            method: 'post',
            url: self.restUrl + "/rest/stores/remove_money",
            headers: self.$global.headers(self.$store.state.auth.user),
            dataType: 'json',
            data: json
          }).then(function (response) {
            if (response.data.status) {
              console.log("success remove money");
            } else {
              console.log("error remove money");
            }
            self.$nextTick(() => {
              self.displayLoaderRecalculate = false;
            });
          }).catch(function (e) {
            self.alert_message = "Ошибка загрузки данных. " + e;
            self.alert_header = "Внимание!";
            self.alert_switch = true;
            self.$nextTick(() => {
              self.displayLoaderRecalculate = false;
            });
          });
        }
      });
    },
    receive(wbId) {
      let self = this;
      let json = {wbId: wbId};
      axios({
        method: 'post',
        url: this.restUrl + "/rest/stores/receive_waybill",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        if(response.data.status)self.$emit('update-waybills');
      });
    },
    cancel(wbId) {
      let self = this;
      let json = {wbId: wbId};
      axios({
        method: 'post',
        url: this.restUrl + "/rest/stores/cancel_waybill",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        if(response.data.status)self.$emit('update-waybills');
      });
    },
    edit(w, func) {
      console.log(w);
      this.$emit("edit-wb", w, func)
    },
    getWbColor(i) {
      if((i.type === 1 && i.status === 3) || (i.type === 4 && i.status === 3) || (i.type === 3 && i.status === 7)) return 'color: #BFBFBF;';
      if((i.type === 1 && i.status === 2) || (i.type === 4 && i.status === 2) || (i.type === 3 && i.status === 6)) return 'color: green;';
      if(i.attention) return 'color: red;';
      if(i.reminderInFuture) return 'color: gray;';
      if((i.type === 1)) return 'color: #a67a13;';
      if((i.type === 3)) return 'color: #029191;';
      if((i.type === 4)) return 'color: #AA55FF;';
      return 'color: white;'
    },
    apply(wbId) {
      let self = this;
      let json = {wbId: wbId};
      self.displayLoader = true;
      axios({
        method: 'post',
        url: this.restUrl + "/rest/stores/apply_waybill",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        if(response.data.status) {
          console.log('success');
        }
        self.displayLoader = false;
      }).catch(function (e) {
        console.log(e);
        self.displayLoader = false;
      });
    },
    expand(id) {
      if(!this.expanded.includes(id)) this.expanded.push(id);
      else {
        for(let i=0;i<this.expanded.length; i++) {
          if(this.expanded[i] === id) {
            this.expanded.splice(i,1);
            break;
          }
        }
      }
    },
    getArrow(wb) {
      let arrow = '';
      if(wb.type === 3) {
        if(this.selStoreId === wb.dispatchStoreId) arrow = 'mdi-arrow-collapse-right';
        else arrow = 'mdi-arrow-collapse-left';
      }
      return arrow;
    },
    getFieldFromTypeWb(typeId, field) {
      for(let t of this.types) if(typeId === t.id) return t[field];
      return '';
    },
    getFieldFromStatusWb(typeId, statusId, field) {
      for(let t of this.types) if(typeId === t.id) for(let s of t.statuses) if(statusId === s.id) return s[field];
      return '';
    },

  }
};
</script>

