<style>

</style>
<template>

  <v-dialog :fullscreen="mobile" :model-value="show" absolute persistent :scrollable="true">

    <v-card style="overflow-x: hidden">
      <v-card-title class="headline">

      <v-row :no-gutters="true">
        <v-col cols="7">
          <span :style="'margin-left: 14px; font-size:' + (mobile ? '12px':'20px')"><b>ЛОГ</b></span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col style="text-align: right">
          <v-tooltip style="display: inline-block" top>
            <template v-slot:activator="{ props: tooltip }">
              <v-btn v-bind="tooltip" icon :size="(mobile ?'x-small':'x-small')" @click="hide"><v-icon>mdi-close</v-icon></v-btn>
            </template>
            <span>Закрыть</span>
          </v-tooltip>
        </v-col>
      </v-row>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-text>

          <table style="border-radius: 5px; width: 100%; font-size: 10px;" border="1">
            <tr v-for="(item, index) of items" :key="index">
              <td>{{$global.displayDate(item.date, 'DD.MM.YYYY HH:mm')}}</td>
              <td>{{item.who}}</td>
              <td v-html="item.log"></td>
              <td>{{item.description}}</td>
            </tr>
            <tr>

            </tr>
          </table>


      </v-card-text>
    </v-card>
  </v-dialog>


</template>

<script>


export default {
  components: {
  },
  props: {
    show: {type: Boolean, required: true, default: false},
    mobile: {type: Boolean, required: true, default: false},
    items: {type: Array, required: true, default() {return []}},

  },
  data() {
    return {
      windowWidth: 0,
    }
  },
  watch: {
  },
  mounted() {
    this.windowWidth = document.documentElement.clientWidth;
    window.addEventListener('resize', this.onResize, {passive: true});
  },
  computed: {
    getModalWidth() {
      return (this.windowWidth <= 1020 ? '98%' : '1000');
    },
  },

  methods: {
    hide() {
      this.$emit('close');
    },
    onResize() {
      this.windowWidth = document.documentElement.clientWidth;
    },
  }
};
</script>

<style lang="scss" scoped></style>
