<style>
* {
  box-sizing: border-box
}

/* Slideshow container */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

/* Hide the images by default */
.mySlides {
  display: none;
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Caption text */
.text {
  color: #000;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #000;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active, .dot:hover {
  background-color: #717171;
}

/* Fading animation */
.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
}

@-webkit-keyframes fade {
  from {
    opacity: .4
  }
  to {
    opacity: 1
  }
}

@keyframes fade {
  from {
    opacity: .4
  }
  to {
    opacity: 1
  }
}
</style>


<template>
  <v-dialog max-width="700" persistent :scrollable="true" :model-value="visible">
    <v-card style="overflow-x: hidden">
      <v-row :dense="true">
        <v-col cols="9">
          <span :style="'margin-left: 14px; font-size:' + (mobile ? '12px':'20px')"><b>Слайдер изображений:</b></span>
        </v-col>

        <v-col style="text-align: right">
          <v-tooltip style="display: inline-block" top>
            <template v-slot:activator="{ props: tooltip }">
              <span v-bind="tooltip">
                <v-btn icon size="x-small" @click="hide">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </span>
            </template>
            <span>Закрыть</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-divider></v-divider>


      <v-card-text style="padding: 5px">
        <div class="slideshow-container" v-if="images.length > 0">

          <div class="mySlides fade" v-for="(image, i) in images" :key="i">
            <div class="numbertext">{{ i + 1 }} / {{ images.length }}</div>
            <a :href="$global.getImageUrl(image, true, folder, mediaUrl)" target="_blank">
              <img :src="$global.getImageUrl(image, true, folder, mediaUrl)" style="width:100%">
            </a>
            <!--          <div class="text">Caption Text</div>-->
          </div>

          <!-- Next and previous buttons -->
          <a class="prev" @click="plusSlides(-1)">&#10094;</a>
          <a class="next" @click="plusSlides(1)">&#10095;</a>
        </div>
        <br>

        <!-- The dots/circles -->
        <div style="text-align:center">
          <span v-for="(image, i) in images" :key="i" class="dot" @click="currentSlide(i + 1)"></span>
        </div>


      </v-card-text>

    </v-card>
  </v-dialog>
</template>


<script>

export default {
  props: [
    'visible',
    'images',
    'slideIndex',
    'mobile',
    'folder'
  ],
  data() {
    return {
      mediaUrl: process.env.VUE_APP_BACKEND_BASE_URL,
      slides: [],
      dots: [],
    }
  },
  watch: {
    slideIndex(v) {
      let self = this;
      try {
        this.$nextTick()
            .then(function () {
              self.slides = document.getElementsByClassName("mySlides");
              self.dots = document.getElementsByClassName("dot");
              self.showSlides(v);
            });
      } catch (e) {
        console.log(e);
      }
    }
  },
  methods: {
    hide() {
      this.$emit('close');
    },
    plusSlides(n) {
      this.$emit('change-slide', this.slideIndex + n);
    },
    currentSlide(n) {
      this.$emit('change-slide', n);
    },
    showSlides(n) {
      try {
        if (this.visible && this.slides.length > 0 && this.dots.length) {
          let i;

          if (n > this.slides.length) {
            this.$emit('change-slide', 1);
          }
          if (n < 1) {
            this.$emit('change-slide', this.slides.length);
          }
          for (i = 0; i < this.slides.length; i++) {
            this.slides[i].style.display = "none";
          }
          for (i = 0; i < this.dots.length; i++) {
            this.dots[i].className = this.dots[i].className.replace(" active", "");
          }
          this.slides[this.slideIndex - 1].style.display = "block";
          this.dots[this.slideIndex - 1].className += " active";
        }
      } catch (e) {
        console.log(e);
      }
    },
  }
}
</script>
