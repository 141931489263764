<template>
  <default-layout-dynamic>

    <div v-if="$global.checkRoles(this.user, ['ALL'])" @click="openDialog(users)"><a href="#">Пользователи</a></div>
    <div v-if="$global.checkRoles(this.user, ['SUDO'])" @click="openDialog(category)"><a href="#">Категории товаров</a></div>
    <div v-if="$global.checkRoles(this.user, ['SUDO'])" @click="openDialog(product)"><a href="#">Продукты</a></div>
    <div v-if="$global.checkRoles(this.user, ['SUDO', 'ADMIN'])" @click="openDialog(store)"><a href="#">Склады</a></div>
    <div v-if="$global.checkRoles(this.user, ['SUDO'])" @click="openDialog(shipping)"><a href="#">Способы доставки</a></div>
    <div v-if="$global.checkRoles(this.user, ['SUDO', 'ADMIN'])" @click="openDialog(pf)"><a href="#">Ценообразование</a></div>
    <div v-if="$global.checkRoles(this.user, ['SUDO', 'ADMIN', 'MANAGER'])" @click="openDialog(news)"><a href="#">Новости</a></div>
    <div v-if="$global.checkRoles(this.user, ['SUDO'])" @click="openDialog(system, true)"><a href="#">Система</a></div>

    <loader @close="displayLoader = false" v-model="displayLoader"></loader>
    <ahtung @close="alert_switch = false" :value="alert_switch" :message="alert_message" :header="alert_header"></ahtung>
    <users v-model="pagination" :items='items' :template="template" :roles="roles" :show="usersDialog" @close="closeDialog(id)" @save-item="saveItem" :mobile="m" @refresh="getItems(id)"></users>
    <category v-if="$global.checkRoles(this.user, ['SUDO'])" :items='items' :template="template" :show="categoryDialog" @close="closeDialog(id)" @save-item="saveItem" @add-item-root="addRootItemCategories" @add-item="addItemCategories" @del-item="delItemCategories" @refresh="getItems(id)" :mobile="m"></category>
    <price-formers v-model="pagination" v-if="$global.checkRoles(this.user, ['SUDO', 'ADMIN'])" :profile="currentProfile" :stores="stores" :types="types" :total="total" :items='items' :template="template" :template-stage="template_pf_stage" :show="pfDialog" @close="closeDialog(id)"  @refresh="getItems(id)" @save-item="saveItem" :mobile="m" ></price-formers>
    <product v-model="pagination" v-if="$global.checkRoles(this.user, ['SUDO'])" :items='items' :template="template" :show="productDialog" @close="closeDialog(id)" @save-item="saveItem" @update-item="updateItem" :mobile="m"  @refresh="getItems(id)"></product>
    <store v-model="pagination" v-if="$global.checkRoles(this.user, ['SUDO', 'ADMIN'])" :profiles="profiles" :items='items' :template="template" :shippings="shippings" :show="storeDialog" @close="closeDialog(id)" @save-item="saveItem" :mobile="m" @refresh="getItems(id)"></store>
    <shipping v-model="pagination" v-if="$global.checkRoles(this.user, ['SUDO'])" :items='items' :template="template" :show="shippingDialog" @close="closeDialog(id)" @save-item="saveItem" :mobile="m" @refresh="getItems(id)"></shipping>
    <news v-model="pagination" v-if="$global.checkRoles(this.user, ['SUDO', 'ADMIN', 'MANAGER'])" :items='items' :template="template" :show="newsDialog" @close="closeDialog(id)" @save-item="saveItem" @update-item="updateItem" :mobile="m"  @refresh="getItems(id)"></news>
    <system v-if="$global.checkRoles(this.user, ['SUDO'])" :show="systemDialog" @close="systemDialog = false" :mobile="m"></system>

  </default-layout-dynamic>
</template>

<script>

import DefaultLayoutDynamic from "../components/layouts/DefaultLayoutDynamic";
import Loader from "../components/Loader";
import axios from "axios";
import {reactive} from "vue";
import Ahtung from "../components/Ahtung";
import Category from "../components/settings/Category";
import Users from "../components/settings/Users";
import PriceFormers from "../components/settings/PriceFormers";
import Product from "../components/settings/Product";
import Store from "../components/settings/Store";
import News from "../components/settings/News";
import System from "@/components/settings/System";
import Shipping from "@/components/settings/Shipping";
import router from "@/router/router";

export default {
  components: {
    Shipping,
    System,
    News,
    Store,
    Product,
    PriceFormers,
    Category,
    Users,
    DefaultLayoutDynamic,
    Loader,
    Ahtung
  },
  data() {
    return {
      users: 'users',
      category: 'category',
      pf: 'pf',
      product: 'product',
      store: 'store',
      news: 'news',
      system: 'system',
      shipping: 'shipping',
      id: '',

      restUrl: process.env.VUE_APP_BACKEND_BASE_URL,
      displayLoader: false,
      usersDialog: false,
      categoryDialog: false,
      pfDialog: false,
      productDialog: false,
      storeDialog: false,
      newsDialog: false,
      systemDialog: false,
      shippingDialog: false,

      pagination: {pp: 20, page: 1, pages: 0, total: 0, search: '', stores: [], users: []},

      items: [],
      total: 0,
      template: {},
      template_pf_stage: {},
      roles: [],
      profiles: [],
      currentProfile: [],
      types: [],
      stores: [],
      shippings: [],

      alert_switch: false,
      alert_message: "",
      alert_header: "",

      m: true,
    }
  },
  name: "Settings",
  mounted() {
    this.m = !this.$vuetify.display.mdAndUp;
  },
  created() {
    console.log("SETTINGS");
    if (!this.loggedIn) router.push('/start')
  },
  methods: {

    openDialog(id, skip_fetching) {
      this.id = id;
      if(!skip_fetching) {
        this.getItems(this.id);
      }
      this[this.id + 'Dialog'] = true;
    },

    getItems(id) {
      this.displayLoader = true;
      let self = this;
      self.items = [];
      let json = {pagination: this.pagination};
      axios({
        method: 'post',
        url: this.restUrl + "/rest/settings/" + id + "/items",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        self.roles = response.data.roles;
        self.types = response.data.types;
        self.profiles = response.data.profiles;
        self.currentProfile = response.data.user;
        self.items = response.data.items;
        self.total = response.data.total;
        self.pagination.pages = response.data.pages;
        self.pagination.total = response.data.total;
        if (self.pagination.page > self.pagination.pages && self.pagination.pages !== 0) self.pagination.page = self.pagination.pages;
        self.stores = response.data.stores;
        self.shippings = response.data.shippings;
        self.template = response.data.template;
        self.template_pf_stage = response.data.templateStage;
        self.displayLoader = false;
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки элементов. " + id;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.displayLoader = false;
        console.log(e);
      });
    },
    saveItem(item, id) {
      this.displayLoader = true;
      let self = this;
      let json = {item: item};
      axios({
        method: 'post',
        url: this.restUrl + "/rest/settings/" + id + "/save",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        if (response.data.status) {
          self.getItems(id);
        } else {
          if(response.data.error) {
            self.alert_message = "Ошибка сохранения элемента. " + response.data.error;
          } else {
            self.alert_message = "Ошибка сохранения элемента. " + id;
          }
          self.alert_header = "Внимание!";
          self.alert_switch = true;
        }
        self.displayLoader = false;
      }).catch(function (e) {
        console.log(e);
        self.alert_message = "Ошибка сохранения элемента. " + id;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
      });
    },
    updateItem(item) {
      for(let i = 0; i< this.items.length; i++) {
        if(this.items[i].id === item.id) {
          this.items[i] = reactive(item);
        }
      }
      console.log(this.items);
    },
    addRootItemCategories(item) {
      this.items.push(item);
    },
    addItemCategories(parent, item) {
      parent.nodes.push(item);
      this.$nextTick(() => {
        this.key++;
      });
    },
    delItemCategories(items, id) {
      let index = -1;
      for (let i = 0; i < items.length; i++) {
        if (items[i].id === id) {
          index = i;
          break;
        } else if (items[i].nodes.length > 0) {
          index = this.delItemCategories(items[i].nodes, id);
          if (index >= 0) return index;
        }
      }
      if (index >= 0) {
        if(items[index].id.includes('temp'))items.splice(index, 1);
        else console.log("Невозможно удалить");
      }
      return index;
    },
    closeDialog(id) {
      this[id + 'Dialog'] = false;
      this.id = '';
      this.pagination = {pp: 20, page: 1, pages: 0, total: 0, search: '', stores: [], users: []};
    },
  },
  watch: {
    'pagination.page'() {
      if(this.id.length > 0) this.getItems(this.id);
    },
    '$vuetify.display.mdAndUp'(val) {
      this.m = !val;
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    user() {
      return this.$store.state.auth.user;
    },
  },
};
</script>
