import { createStore } from 'vuex'
import { auth } from "./auth.module";
import { websocket } from "./websocket.module";
export default createStore({
  state: {
    sidebar: true,
    storesUpdater: {},
    ordersUpdater: {},
    newsUpdater: {},
    shutdownObj: {run: false, min: 0, len: 0},
    cashier_to_update: {},
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebar = !state.sidebar;
    },
    updateStores(state, val) {
      state.storesUpdater = val;
    },
    updateOrders(state, val) {
      state.ordersUpdater = val;
    },
    updateNews(state, val) {
      state.newsUpdater = val;
    },
    updateShutdownTimer(state, val) {
      state.shutdownObj = val;
    },
    updateCashierUpdate(state, val) {
      state.cashier_to_update = val;
    },
  },
  actions: {
    async handleUpdateStores({commit}, val) {
      console.log(val);
      commit('updateStores', val);
    },
    // updateOnBack(context, id) {
    //   if (context.state.websocket.stompClient && context.state.websocket.stompClient.connected) {
    //     context.state.websocket.stompClient.publish({
    //       destination: '/back',
    //       body: JSON.stringify({"id": id, "userId": context.state["auth/user"].id}),
    //     });
    //   }
    // },
    async handleToggleSideBar({commit}) {
      commit('toggleSidebar');
    },
  },
  modules: {
    auth,
    websocket
  }
})
