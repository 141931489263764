<style scoped>
.cells-wrapper-mobile {
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin-bottom: 3px;
  font-size: 10px;
  text-align: center;
}
.cell-mobile {
  border-bottom: 1px solid #dedede;
}
.v-lazy-component.v-lazy-component--loading {
  filter: blur(15px);
}

.v-lazy-component.v-lazy-component--loaded {
  filter: blur(0);
  transition: filter 0.4s;
}
</style>
<template>

  <v-dialog :fullscreen="mobile" :model-value="show" absolute persistent :scrollable="true">

    <v-card style="overflow-x: hidden">

      <v-row :no-gutters="true">
        <v-col cols="7">
          <span :style="'margin-left: 14px; font-size:' + (mobile ? '12px':'20px')"><b>{{getHeaderForModal()}}</b></span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col style="text-align: right">
          <v-tooltip location="left" v-if="!(item.status > 1)">
            <template v-slot:activator="{ props: tooltip }"><v-checkbox-btn style="display: inline-flex;" density="compact" color="#0f0" v-bind="tooltip" v-model="auto_recalculate"></v-checkbox-btn></template>
            <span>Автопересчет заказа</span>
          </v-tooltip>
          <v-tooltip style="display: inline-block" top>
            <template v-slot:activator="{ props: tooltip }">
              <v-btn v-bind="tooltip" icon :size="(mobile ?'x-small':'x-small')" @click="hide"><v-icon>mdi-close</v-icon></v-btn>
            </template>
            <span>Закрыть</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row :no-gutters="true">
        <v-col cols="12" md="4"><v-text-field variant="solo" label="Имя" density="compact" :hide-details="true" disabled v-model="item.name"></v-text-field></v-col>
        <v-col cols="12" md="4"><v-text-field variant="solo" label="Емэйл" density="compact" :hide-details="true" disabled v-model="item.email"></v-text-field></v-col>
        <v-col cols="12" md="4"><v-combobox variant="solo" :return-object="false" item-title="val" item-value="val" label="Телефон" v-model="item.phone" :items="profile.phones" density="compact" :hide-details="true"></v-combobox></v-col>

      </v-row>
      <v-row :no-gutters="true">
        <v-col cols="12" md="6"><v-select variant="solo" label="Доставка" density="compact" :hide-details="true" :items="store.shippings" item-value="id" item-title="name" v-model="item.shippingId"></v-select></v-col>
        <v-col cols="12" md="6"><v-combobox variant="solo" item-title="val" item-value="val" :disabled="disable_address_input" label="Адрес" v-model="item.address" :items="profile.addresses" :return-object="false" density="compact" :hide-details="true"></v-combobox></v-col>
        <v-col cols="12"><v-textarea variant="solo" :return-object="false" rows="2" label="Комментарий" density="compact" :hide-details="true" v-model="item.comment"></v-textarea></v-col>
      </v-row>
      <v-divider></v-divider>

      <store-search :url="'/rest/client/stores/search_catalog'" @select-item="storeItemSelected" :store-id="store.id" :mobile="mobile"></store-search>

      <v-card-text :style="mobile ? 'font-size: 10px; min-height: 300px;':''">
        <v-progress-linear :active="loader" indeterminate></v-progress-linear>
        <v-table v-if="!mobile" :fixed-header="true" height="600" :density="'compact'" :style="mobile ? 'min-width: 1000px':''">
          <thead><tr>
            <th class="text-left" v-for="h in headers" :key="h.text">
              {{ h.text }}
            </th>
          </tr></thead>
          <tbody>

          <lazy-component wrapper-tag="tr" v-for="(i, index) in item.items" :key="index">
            <td>{{ i.artikul }}</td>
            <v-tooltip location="top">
              <template v-slot:activator="{ props: tooltip }">
                <td v-bind="tooltip">
                  {{ i.breadcrumbs }}
                  <template v-if="i.error.length > 0"><br><span style="color: red; font-size: 10px">{{ i.error }}</span></template>
                </td>
              </template>
              <span>
                <span v-if="i.images.length > 0">
                  <v-img v-for="(img, index) in i.images.split(';')" :key="index" :src="restUrl + img" width="200"/><br>
                </span>
                {{ i.description }}
              </span>
            </v-tooltip>

            <td><v-text-field variant="solo" density="compact" :hide-details="true" type="number" onkeypress="return event.charCode >= 46" @input="v => inputN(v, i, 'count')" :model-value="i.count" min="1" step="1"></v-text-field></td>
            <td v-if="!i.chip">{{ i.price }}</td>
            <td v-else><s style="color: #717171">{{ i.price }}</s>{{ i.priceCalculated }}</td>
            <td style="text-align: center;">{{ i.sumCalculated }}</td>
            <td>
              <v-tooltip location="bottom">
                <template v-slot:activator="{ props: tooltip }">
                  <v-btn v-bind="tooltip" icon="mdi-cart-off" :color="'#ff0000'" size="x-small" @click="item.items.splice(index, 1);"></v-btn>
                </template>
                <span>Удалить из корзины</span>
              </v-tooltip>
            </td>
            <template #placeholder>
              <tr>
                <td colspan="6">Loading...</td>
              </tr>
            </template>
          </lazy-component>
          </tbody>
        </v-table>

        <lazy-component v-else wrapper-tag="table" class="cells-wrapper-mobile" v-for="(i, index) in item.items" :key="index">
          <tbody>
          <tr>
              <v-tooltip location="top">
                <template v-slot:activator="{ props: tooltip }">
                  <td v-bind="tooltip" class="cell-mobile" style="width: 100%; text-align: left; color: #0f8107;" colspan="3">
                    {{ i.breadcrumbs }}
                    <template v-if="i.error.length > 0"><br><span style="color: red; font-size: 10px">{{ i.error }}</span></template>
                  </td>
                </template>
                <span>
                <span v-if="i.images.length > 0">
                  <v-img v-for="(img, index) in i.images.split(';')" :key="index" :src="restUrl + img" width="200"/><br>
                </span>
                {{ i.description }}
              </span>
              </v-tooltip>
            <td colspan="1" class="cell-mobile" style="width: 100%">
              <v-tooltip location="bottom">
                <template v-slot:activator="{ props: tooltip }">
                  <v-btn v-bind="tooltip" icon="mdi-cart-off" :color="'#ff0000'" size="x-small" @click="item.items.splice(index, 1);"></v-btn>
                </template>
                <span>Удалить из корзины</span>
              </v-tooltip>
            </td>
          </tr>

          <tr>
            <td class="cell-mobile" style="width: 25%">
              <b>{{ i.artikul }}</b>
            </td>
            <td class="cell-mobile" style="width: 35%">
              <v-text-field variant="solo" density="compact" label="шт" :hide-details="true" type="number" onkeypress="return event.charCode >= 46" @input="v => inputN(v, i, 'count')" :model-value="i.count" min="1" step="1"></v-text-field>
            </td>

            <td class="cell-mobile" style="border-left: 1px solid #dedede;width: 20%" v-if="!i.chip">{{ i.price }}</td>
            <td class="cell-mobile" style="border-left: 1px solid #dedede;width: 20%" v-else><s style="color: #717171">{{ i.price }}</s>{{ i.priceCalculated }}</td>

            <td class="cell-mobile" style="border-left: 1px solid #dedede;width: 20%">
              {{ i.sumCalculated }}
            </td>
          </tr>

          </tbody>
          <template #placeholder>
            <tr>
              <td colspan="4">Loading...</td>
            </tr>
          </template>
        </lazy-component>





      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row :dense="true">
          <v-col cols="12">
            <v-row :no-gutters="true">
              <v-col cols="4" v-if="!mobile">
                <div class="d-inline-flex" v-if="!mobile">Итого:</div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="d-inline-flex" :style="mobile ? 'font-size: 10px':''">Колличество единиц:&nbsp;<span style="font-weight: bold;">{{ item.totalCount }}</span></div>
              </v-col>
              <v-col cols="12" md="4">
                <div v-if="!orderContainsChip()" class="d-inline-flex" :style="mobile ? 'font-size: 10px':''">Итоговая сумма: <span style="font-weight: bold;">{{ item.sum }}</span></div>
                <div v-else class="d-inline-flex" :style="mobile ? 'font-size: 10px':''">Итоговая сумма:&nbsp;<span style="font-weight: bold;"><s style="color: #717171">{{ item.sum }}</s> {{ item.sumCalculated }}</span></div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" :style="mobile ? '':'text-align: left'">
            <v-btn v-if="!store.stopSales" :block="mobile" :disabled="!auto_recalculate || item.items.length === 0 || checkOrderHasErrors()" x-small @click="saveItem()"><span style="color: green">Оформить</span></v-btn>
          </v-col>
          <v-col cols="12" md="6" :style="mobile ? '':'text-align: right'">
            <v-btn :block="mobile" x-small :disabled="item.items.length === 0" @click="clearEditItem()"><span style="color: red;">Очиcтить корзину</span></v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <loader @close="displayLoader = false" v-model="displayLoader"></loader>
    <ahtung @close="alert_switch = false" :value="alert_switch" :message="alert_message" :header="alert_header"></ahtung>
    <confirm-dialog ref="confirm"></confirm-dialog>

  </v-dialog>

</template>

<script>


import Loader from "@/components/Loader.vue";
import Ahtung from "@/components/Ahtung.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import {computed, defineComponent} from "vue";
import StoreSearch from "@/components/common/StoreSearch.vue";

export default defineComponent ({
  name: "OrderEditorClient",
  components: {
    StoreSearch,
    ConfirmDialog,
    Ahtung,
    Loader
  },
  props: {
    show: {type: Boolean, required: true, default: false},
    loader: {type: Boolean, required: true, default: false},
    mobile: {type: Boolean, required: true, default: false},
    templateOrIt: {type: Object, required: true, default() {return {}}},
    profile: {type: Object, required: true, default() {return {}}},
    modelValue: {type: Object, required: true, default() {return {}}},
    store: {type: Object, required: true, default() {return {}}},
    types: {type: Array, required: true, default() {return []}},
  },
  setup(props, { emit }) {
    const item = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });
    return { item };
  },
  data() {
    return {
      restUrl: process.env.VUE_APP_BACKEND_BASE_URL,
      displayLoader: false,
      alert_switch: false,
      alert_message: "",
      alert_header: "",
      headers: [
        {text: 'ID', value: 'id', field: 'id', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Наименование', value: 'name', field: 'name', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Кол-во', value: 'count', field: 'count', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Стоимость', value: 'price', field: 'price', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Сумма', value: 'sum', field: 'sum', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 100},
        {text: '', value: 'act', field: 'act', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
      ],
      disable_address_input: false,
      auto_recalculate: true,
    }
  },
  watch: {
    auto_recalculate(val) {
      if(val) {
        this.recalculateOrder();
      }
    },
    'item.shippingId'(val) {
      if(val === 5) {
        this.item.address = this.store.address;
        this.disable_address_input = true;
      }
      else {
        this.item.address = '';
        this.disable_address_input = false;
      }

    },

  },
  mounted() {
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },

  methods: {
    storeItemSelected(selItem) {
      this.$emit('addToCart', selItem);
    },
    inputN(v, i, f) {
      let x = this.$global.inputNumberGE(v.target.value, 1);
      if(x === 0) v.target.value = 0;
      i[f] = x;
      this.recalculateOrder();
    },
    checkExist(id) {
      for(let i of this.item.items) if(i.artikul === id) return true;
      return false;
    },
    getHeaderForModal() {
      return "КОРЗИНА ПОКУПАТЕЛЯ"
      // return 'Тип: ' + this.getFieldFromTypeOr(this.item.type, 'nameR') + "; Статус: " + this.getFieldFromStatusOr(this.item.type, this.item.status, 'nameR');
    },
    // getFieldFromTypeOr(typeId, field) {
    //   for(let t of this.types) if(typeId === t.id) return t[field];
    //   return '';
    // },
    // getFieldFromStatusOr(typeId, statusId, field) {
    //   for(let t of this.types) if(typeId === t.id) for(let s of t.statuses) if(statusId === s.id) return s[field];
    //   return '';
    // },
    checkOrderHasErrors() {
      for(let i of this.item.items) if(i.error.length > 0) return true;
      return false;
    },
    orderContainsChip() {
      for(let i of this.item.items) if(i.chip) return true;
      return false;
    },
    getTotalSum(prefix) {
      let result = 0;
      for(let i of this.item.items) result += i.count * i[prefix.length > 0 ? prefix + 'Price' : 'price'];
      return result;
    },
    getTotalCount() {
      let result = 0;
      for(let i of this.item.items) result += i.count;
      return result;
    },
    saveItem() {
      this.$emit('save');
    },
    clearEditItem() {
      this.$emit('clear');
      this.$emit('close');
    },
    recalculateOrder(manual) {
      if(this.auto_recalculate || manual) this.$emit('recalculate');
    },
    hide() {
      this.$emit('close');
    },
    checkCombobox(item, queryText) {
      console.log(item);
      console.log(queryText);
      if (item.val.toLowerCase().indexOf(queryText.toLowerCase()) > -1) return true;
    },
  }
});
</script>

