<style scoped>
.cells-wrapper-mobile {
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin-bottom: 3px;
  font-size: 10px;
  text-align: center;
}
.cell-mobile {
  border-bottom: 1px solid #dedede;
}
.v-lazy-component.v-lazy-component--loading {
  filter: blur(15px);
}

.v-lazy-component.v-lazy-component--loaded {
  filter: blur(0);
  transition: filter 0.4s;
}
</style>
<template>

  <v-dialog :fullscreen="mobile" :model-value="show" persistent :scrollable="true">

    <v-card style="overflow-x: hidden">

      <v-row :no-gutters="true">
        <v-col cols="7">
          <span :style="'margin-left: 14px; font-size:' + (mobile ? '12px' : '20px')"><b>{{ getHeaderForModal() }}</b></span>
        </v-col>
        <v-col style="text-align: right">
          <v-tooltip location="left" v-if="!(val.status > 3)">
            <template v-slot:activator="{ props: tooltip }"><v-checkbox-btn style="display: inline-flex;" density="compact" color="#0f0" v-bind="tooltip" v-model="auto_recalculate"></v-checkbox-btn></template>
            <span>Автопересчет заказа</span>
          </v-tooltip>

          <v-tooltip location="left" v-if="val.id > 0 && $global.checkRoles(this.user, ['SUDO', 'ADMIN', 'MANAGER'])">
            <template v-slot:activator="{ props: tooltip }">
              <v-btn style="display: inline" v-bind="tooltip" icon :size="(mobile ? 'x-small' : 'x-small')" @click="getPrice()"><v-icon>mdi-file-excel</v-icon></v-btn>
            </template>
            <span>XLS</span>
          </v-tooltip>

          <v-tooltip location="left" v-if="val.id > 0 && $global.checkRoles(this.user, ['SUDO', 'ADMIN'])">
            <template v-slot:activator="{ props: tooltip }">
              <v-btn style="display: inline" v-bind="tooltip" icon :size="(mobile ? 'x-small' : 'x-small')" @click="getLogs()"><v-icon>mdi-information-variant</v-icon></v-btn>
            </template>
            <span>Лог</span>
          </v-tooltip>

          <v-tooltip location="left">
            <template v-slot:activator="{ props: tooltip }">
              <v-btn style="display: inline" v-bind="tooltip" icon :size="(mobile ? 'x-small' : 'x-small')" @click="hide"><v-icon>mdi-close</v-icon></v-btn>
            </template>
            <span>Закрыть</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row :no-gutters="true">
        <v-col cols="12" md="4">
          <v-select variant="solo" hide-selected :disabled="val.id === 0" label="Статус" item-title="nameR" :return-object="true" density="compact" :hide-details="true" :items="getStatuses()" :model-value="val.status">
            <template v-slot:item="{ item }">
              <v-list-item :disabled="item.raw.disabled" @click="val.status = item.raw.id">{{ item.raw.nameR }}</v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <v-chip>
                <span>{{ getStatusNameById(item.raw) }}</span>
              </v-chip>
            </template>
          </v-select>

        </v-col>
        <v-col cols="12" md="4"><v-select variant="solo" @update:model-value="v => changeManager(v)" label="Менеджер" item-title="name" density="compact" :hide-details="true" :items="profiles" return-object :model-value="selectedManager"></v-select></v-col>
        <v-col cols="12" md="4"><v-select variant="solo" @update:model-value="v => changeClient(v)" :label="val.clientId + 'Клиент(если не выбран будет создан новый аккаунт клиента)'" item-title="name" density="compact" :hide-details="true" :items="clients" return-object :model-value="selectedClient"></v-select></v-col>
        <v-col cols="12" md="4"><v-text-field variant="solo" :disabled="selectedClient && selectedClient.id !== 0" label="Имя" density="compact" :hide-details="true" v-model="val.name"></v-text-field></v-col>
        <v-col cols="12" md="4"><v-text-field variant="solo" :disabled="selectedClient && selectedClient.id !== 0" label="Емэйл" density="compact" :hide-details="true" v-model="val.email"></v-text-field></v-col>
        <v-col cols="12" md="4"><v-combobox variant="solo" :return-object="false" item-title="val" item-value="val" label="Телефон" v-model="val.phone" :items="(selectedClient && selectedClient.id !== 0 ? selectedClient.phones : [])" density="compact" :hide-details="true"></v-combobox></v-col>

      </v-row>
      <v-row :no-gutters="true">
        <v-col cols="12" md="3"><v-select variant="solo" label="Доставка" density="compact" :hide-details="true" :items="store.shippings" item-value="id" item-title="name" v-model="val.shippingId"></v-select></v-col>
        <v-col cols="12" md="3"><v-combobox variant="solo" :return-object="false" item-title="val" item-value="val" :disabled="disable_address_input" label="Адрес" v-model="val.address" :items="(selectedClient && selectedClient.id !== 0 ? selectedClient.addresses : [])" density="compact" :hide-details="true"></v-combobox></v-col>
        <v-col cols="12" md="3"><v-text-field variant="solo" label="Трекномер" density="compact" :hide-details="true" v-model="val.postTrackNum"></v-text-field></v-col>
        <v-col cols="12" md="3">
          <div class="d-flex">
            <v-tooltip location="top">
              <template v-slot:activator="{ props: tooltip }"><v-checkbox-btn density="compact" @change="recalculateOrder()" v-bind="tooltip" v-model="val.addShippingPrice"></v-checkbox-btn></template>
              <span>Добавлять стоимость доставки к итоговой сумме заказа</span>
            </v-tooltip>
            <currency-input v-model="val.shippingPrice" @kp="recalculateOrder()" label="Стоимость доставки" :options="{ currency: 'BYN', currencyDisplay: 'hidden', valueRange: {min:0} }"/>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <VueDatePicker placeholder="Дата доставки" v-model="val.dateShipping"></VueDatePicker>
        </v-col>
        <v-col cols="12" md="2">
          <v-checkbox color="#ff0000" density="compact" :hide-details="true" v-model="val.attention" label="Требует внимания"></v-checkbox>
        </v-col>
        <v-col cols="12" md="4">
          <VueDatePicker  placeholder="Дата напоминания" v-model="val.dateAttention"></VueDatePicker>
        </v-col>

        <v-col cols="12"><v-textarea variant="solo" rows="2" label="Комментарий" density="compact" :hide-details="true" v-model="val.comment"></v-textarea></v-col>
      </v-row>
      <v-divider></v-divider>

      <store-search :show-data="true" :url="'/rest/admin/orders/search_catalog'" @select-item="storeItemSelected" :store-id="store.id" :mobile="mobile"></store-search>

      <v-card-text :style="mobile ? 'font-size: 10px; min-height: 300px;':''">
        <v-progress-linear :active="loader" indeterminate></v-progress-linear>
        <v-table v-if="!mobile" :fixed-header="true" height="250" :density="'compact'"  :style="mobile ? 'min-width: 1000px':''">
          <thead><tr>
            <th class="text-left" v-for="h in headers" :key="h.text">
              {{ h.text }}
            </th>
          </tr></thead>


          <tbody v-if="val.items && val.items.length !== 0">
          <lazy-component wrapper-tag="tr" v-for="(i, index) in val.items" :key="i.artikul">
            <td style="width: 10%">{{ i.artikul }}</td>
            <v-tooltip location="top">
              <template v-slot:activator="{ props: tooltip }">
                <td v-bind="tooltip"  style="width: 45%">
                  {{ i.breadcrumbs }}
                  <template v-if="i.error.length > 0"><br><span style="color: red; font-size: 10px">{{ i.error }}</span></template>
                </td>
              </template>
              <span>
                <span v-if="i.images.length > 0">
                  <v-img v-for="(img, index) in i.images.split(';')" :key="index" :src="restUrl + img" width="200"/><br>
                </span>
                {{ i.description }}
              </span>
            </v-tooltip>

            <td style="width: 15%"><v-text-field variant="solo" :disabled="val.status > 3" :label="'' + i.availableCount" density="compact" :hide-details="true" type="number" onkeypress="return event.charCode >= 46" @input="v => inputN(v, i, 'count', true, 1)" :model-value="i.count" min="1" step="1"></v-text-field></td>
            <td  style="width: 15%">
              <div class="d-flex">
                <v-tooltip location="top">
                  <template v-slot:activator="{ props: tooltip }"><v-checkbox-btn :disabled="val.status > 3" density="compact" @change="recalculateOrder()" v-bind="tooltip" v-model="i.manualPrice"></v-checkbox-btn></template>
                  <span>Ручной ввод цены</span>
                </v-tooltip>
                <currency-input :color="(i.price < i.pp ? '#f00':'')" :disabled="!i.manualPrice || val.status > 3" v-model="i.price" :label="'' + i.pp + ' BYN'" @kp="recalculateOrder()" :options="{ currency: 'BYN', currencyDisplay: 'hidden', valueRange: {min:0} }"/>
              </div>
            </td>
            <td style="width: 15%">
              <div class="d-flex">
                <v-tooltip location="top">
                  <template v-slot:activator="{ props: tooltip }"><v-checkbox-btn :disabled="val.status > 3" density="compact" @change="v => checkOrderSumManual(v.target.checked, i)" v-bind="tooltip" v-model="i.manualSum"></v-checkbox-btn></template>
                  <span>Ручной ввод суммы</span>
                </v-tooltip>
                <currency-input  :disabled="val.status > 3" v-if="i.manualSum" v-model="i.manualSumVal" @kp="recalculateOrder()" label="BYN" :options="{ currency: 'BYN', currencyDisplay: 'hidden', valueRange: {min:0} }"/>
                <span v-else> {{ i.sum }} </span>
              </div>
            </td>
            <td><v-btn icon="mdi-cart-off" :color="'#ff0000'" size="x-small" @click="val.items.splice(index, 1);"></v-btn></td>
            <template #placeholder>
              <tr>
                <td colspan="6">Loading...</td>
              </tr>
            </template>
          </lazy-component>
          </tbody>

        </v-table>

        <lazy-component v-else wrapper-tag="table" class="cells-wrapper-mobile" v-for="(i, index) in val.items" :key="index">
          <tbody>
          <tr>
            <td colspan="1" class="cell-mobile" style="width: 15%">
              <v-tooltip location="bottom">
                <template v-slot:activator="{ props: tooltip }">
                  <v-btn v-bind="tooltip" icon="mdi-cart-off" :color="'#ff0000'" size="x-small" @click="val.items.splice(index, 1);"></v-btn>
                </template>
                <span>Удалить из корзины</span>
              </v-tooltip>
            </td>
            <v-tooltip location="top">
              <template v-slot:activator="{ props: tooltip }">
                <td v-bind="tooltip"  style="width: 85%" colspan="3">
                  {{ i.breadcrumbs }}
                  <template v-if="i.error.length > 0"><br><span style="color: red; font-size: 10px">{{ i.error }}</span></template>
                </td>
              </template>
              <span>
                <span v-if="i.images.length > 0">
                  <v-img v-for="(img, index) in i.images.split(';')" :key="index" :src="restUrl + img" width="200"/><br>
                </span>
                {{ i.description }}
              </span>
            </v-tooltip>
           </tr>

          <tr>
            <td class="cell-mobile" style="width: 15%">
              <b>{{ i.artikul }}</b>
            </td>
            <td class="cell-mobile" style="width: 25%">
              <v-text-field variant="solo" :disabled="val.status > 3" :label="'' + i.availableCount" density="compact" :hide-details="true" type="number" onkeypress="return event.charCode >= 46" @input="v => inputN(v, i, 'count', true, 1)" :model-value="i.count" min="1" step="1"></v-text-field>
            </td>

            <td class="cell-mobile" style="border-left: 1px solid #dedede;width: 30%">
              <div class="d-flex">
                <v-tooltip location="top">
                  <template v-slot:activator="{ props: tooltip }"><v-checkbox-btn :disabled="val.status > 3" density="compact" @change="recalculateOrder()" v-bind="tooltip" v-model="i.manualPrice"></v-checkbox-btn></template>
                  <span>Ручной ввод цены</span>
                </v-tooltip>
                <currency-input :color="(i.price < i.pp ? '#f00':'')" :disabled="!i.manualPrice || val.status > 3" v-model="i.price" :label="'' + i.pp + ' BYN'" @kp="recalculateOrder()" :options="{ currency: 'BYN', currencyDisplay: 'hidden', valueRange: {min:0} }"/>
              </div>
            </td>

            <td class="cell-mobile" style="border-left: 1px solid #dedede;width: 30%">
              <div class="d-flex">
                <v-tooltip location="top">
                  <template v-slot:activator="{ props: tooltip }"><v-checkbox-btn :disabled="val.status > 3" density="compact" @change="v => checkOrderSumManual(v.target.checked, i)" v-bind="tooltip" v-model="i.manualSum"></v-checkbox-btn></template>
                  <span>Ручной ввод суммы</span>
                </v-tooltip>
                <currency-input  :disabled="val.status > 3" v-if="i.manualSum" v-model="i.manualSumVal" @kp="recalculateOrder()" label="BYN" :options="{ currency: 'BYN', currencyDisplay: 'hidden', valueRange: {min:0} }"/>
                <span v-else> {{ i.sum }} </span>
              </div>
            </td>

          </tr>


          </tbody>
          <template #placeholder>
            <tr>
              <td colspan="4">Loading...</td>
            </tr>
          </template>
        </lazy-component>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row :dense="true">
          <v-col cols="12">
            <v-row :no-gutters="true">
              <v-col cols="4" v-if="!mobile">
                <div class="d-inline-flex" v-if="!mobile">Итого:</div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="d-inline-flex" :style="mobile ? 'font-size: 10px':''">Колличество единиц:&nbsp;<span style="font-weight: bold;">{{ val.totalCount }}</span></div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="d-flex" :style="mobile ? 'font-size: 10px':''">
                  Итоговая сумма:&nbsp;
                  <v-tooltip location="top">
                    <template v-slot:activator="{ props: tooltip }"><v-checkbox-btn :disabled="val.status > 3 && val.status !== 7" density="compact" @change="v => checkOrderSumManual(v.target.checked, val)" v-bind="tooltip" v-model="val.manualSum"></v-checkbox-btn></template>
                    <span>Ручной ввод итоговой суммы</span>
                  </v-tooltip>
                  <currency-input :disabled="val.status > 3 && val.status !== 7" v-if="val.manualSum" v-model="val.manualSumVal" @kp="recalculateOrder()" label="BYN" :options="{ currency: 'BYN', currencyDisplay: 'hidden', valueRange: {min:0} }"/>
                  <span v-else style="font-weight: bold;">{{ val.sum }}</span>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" :style="mobile ? '':'text-align: left'">
            <v-btn :block="mobile" v-if="!store.stopSales" :disabled="!auto_recalculate || val.items.length === 0 || checkOrderHasErrors()" x-small @click="saveItem(false)"><span style="color: green">Сохранить</span></v-btn>
          </v-col>
          <v-col cols="12" md="6" :style="mobile ? '':'text-align: right'">
            <v-btn :block="mobile" v-if="!store.stopSales" :disabled="!auto_recalculate || val.items.length === 0 || checkOrderHasErrors()" x-small @click="saveItem(true)"><span style="color: green">Сохранить и закрыть</span></v-btn>
          </v-col>
          <v-col cols="12" md="4" v-if="!($global.checkRoles(user, ['SUDO']))"></v-col>
          <v-col v-else cols="12" md="4" :style="mobile ? 'text-align: center':'text-align: left'">
            <v-checkbox :disabled="val.id !== 0" style="display: inline-block" color="#ff0000" density="compact" :hide-details="true" v-model="val.dontApplyToStore" label="Не применять заказ к складу"></v-checkbox>
          </v-col>
          <v-col cols="12" md="4" v-if="!($global.checkRoles(user, ['SUDO']))"></v-col>
          <v-col v-else cols="12" md="4" :style="mobile ? 'text-align: center':'text-align: left'">
            <v-checkbox :disabled="val.id !== 0" style="display: inline-block" color="#ff0000" density="compact" :hide-details="true" v-model="val.dontApplyToCashier" label="Не применять заказ к кассе"></v-checkbox>
          </v-col>
          <v-col cols="12" md="4" :style="mobile ? '':'text-align: right'">
            <v-btn :block="mobile" x-small :disabled="val.items.length === 0" @click="recalculateOrder(true)"><span style="color: red;">Пересчет заказа</span></v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <loader @close="displayLoader = false" v-model="displayLoader"></loader>
    <ahtung @close="alert_switch = false" :value="alert_switch" :message="alert_message" :header="alert_header"></ahtung>
    <confirm-dialog ref="confirm"></confirm-dialog>
    <log-dialog :items="logs" :show="showLogs" @close="closeLogs()" :mobile="mobile"></log-dialog>

  </v-dialog>

</template>

<script>


import Loader from "@/components/Loader.vue";
import Ahtung from "@/components/Ahtung.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import {computed, defineComponent} from "vue";
import StoreSearch from "@/components/common/StoreSearch.vue";
import axios from "axios";
import LogDialog from "@/components/common/LogDialog.vue";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import CurrencyInput from "@/components/common/CurrencyInput.vue";

export default defineComponent ({
  name: "OrderEditorAdmin",
  components: {
    CurrencyInput,
    VueDatePicker,
    LogDialog,
    StoreSearch,
    ConfirmDialog,
    Ahtung,
    Loader
  },
  props: {
    show: {type: Boolean, required: true, default: false},
    loader: {type: Boolean, required: true, default: false},
    mobile: {type: Boolean, required: true, default: false},
    templateOrIt: {type: Object, required: true, default() {return {}}},
    profile: {type: Object, required: true, default() {return {}}},
    profiles: {type: Array, required: true, default() {return []}},
    clients: {type: Array, required: true, default() {return []}},
    modelValue: {type: Object, required: true, default() {return {}}},
    store: {type: Object, required: true, default() {return {}}},
    types: {type: Array, required: true, default() {return []}},
  },
  setup(props, { emit }) {
    const val = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });
    return { val };
  },
  data() {
    return {
      restUrl: process.env.VUE_APP_BACKEND_BASE_URL,
      displayLoader: false,
      alert_switch: false,
      alert_message: "",
      alert_header: "",
      headers: [
        {text: 'ID', value: 'id', field: 'id', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Наименование', value: 'name', field: 'name', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Кол-во', value: 'count', field: 'count', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Стоимость', value: 'price', field: 'price', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Сумма', value: 'sum', field: 'sum', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 100},
        {text: '', value: 'act', field: 'act', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
      ],
      disable_address_input: false,
      shippingDateObj: {date: null, menu: false},
      reminderDateObj: {date: null, menu: false},
      selectedManager: undefined,
      selectedClient: undefined,
      logs: [],
      showLogs: false,
      auto_recalculate: true,

    }
  },
  watch: {
    auto_recalculate(val) {
      if(val) {
        this.recalculateOrder();
      }
    },
    show(val) {
      if (val) {
        if (this.val.id === 0) {
          if (this.profiles.length > 0) {
            this.selectedManager = this.profiles[0];
            this.val.managerId = this.selectedManager.id;
          }
          if (this.clients.length > 0) this.selectedClient = this.clients[0];
        } else {
          for (let p of this.profiles) if (this.val.managerId === p.id) {
            this.selectedManager = p;
            break;
          }
          for (let c of this.clients) if (this.val.clientId === c.id) {
            this.selectedClient = c;
            break;
          }
        }
        this.auto_recalculate = true;
      }
    },
    'val.shippingId'(val) {
      if(val === 5) {
        this.val.address = this.store.address;
        this.disable_address_input = true;
      }
      else {
        this.val.address = '';
        this.disable_address_input = false;
      }

    },

  },
  mounted() {

  },
  computed: {

    user() {
      return this.$store.state.auth.user;
    },
  },

  methods: {
    getStatuses() {
      let result = [];
      for(let t of this.types) if(t.id === this.val.type) {
        let cur = this.val.status;
        for(let s of t.statuses) {
          switch (cur) {
            case 1:
              if(s.id === 1) s.disabled = true;         //new
              else if(s.id === 2) s.disabled = false;   //obrabotka
              else if(s.id === 3) s.disabled = false;   //sborka
              else if(s.id === 4) s.disabled = false;   //paid
              else if(s.id === 5) s.disabled = false;   //realize
              else if(s.id === 6) s.disabled = false;   //post
              else if(s.id === 7) s.disabled = false;   //vozvrat
              else if(s.id === 8) s.disabled = false;   //otmena
              else if(s.id === 9) s.disabled = false;   //zavershen
              break;
            case 2:
              if(s.id === 1) s.disabled = true;         //new
              else if(s.id === 2) s.disabled = true;    //obrabotka
              else if(s.id === 3) s.disabled = false;   //sborka
              else if(s.id === 4) s.disabled = false;   //paid
              else if(s.id === 5) s.disabled = false;   //realize
              else if(s.id === 6) s.disabled = false;   //post
              else if(s.id === 7) s.disabled = false;   //vozvrat
              else if(s.id === 8) s.disabled = false;   //otmena
              else if(s.id === 9) s.disabled = false;   //zavershen
              break;
            case 3:
              if(s.id === 1) s.disabled = true;         //new
              else if(s.id === 2) s.disabled = true;    //obrabotka
              else if(s.id === 3) s.disabled = true;    //sborka
              else if(s.id === 4) s.disabled = false;   //paid
              else if(s.id === 5) s.disabled = false;   //realize
              else if(s.id === 6) s.disabled = false;   //post
              else if(s.id === 7) s.disabled = false;   //vozvrat
              else if(s.id === 8) s.disabled = false;   //otmena
              else if(s.id === 9) s.disabled = false;   //zavershen
              break;
            case 4:
              if(s.id === 1) s.disabled = true;         //new
              else if(s.id === 2) s.disabled = true;   //obrabotka
              else if(s.id === 3) s.disabled = true;   //sborka
              else if(s.id === 4) s.disabled = true;    //paid
              else if(s.id === 5) s.disabled = false;   //realize
              else if(s.id === 6) s.disabled = false;   //post
              else if(s.id === 7) s.disabled = false;   //vozvrat
              else if(s.id === 8) s.disabled = true;   //otmena
              else if(s.id === 9) s.disabled = false;   //zavershen
              break;
            case 5:
              if(s.id === 1) s.disabled = true;         //new
              else if(s.id === 2) s.disabled = true;    //obrabotka
              else if(s.id === 3) s.disabled = true;    //sborka
              else if(s.id === 4) s.disabled = false;    //paid
              else if(s.id === 5) s.disabled = true;    //realize
              else if(s.id === 6) s.disabled = true;    //post
              else if(s.id === 7) s.disabled = false;   //vozvrat
              else if(s.id === 8) s.disabled = true;   //otmena
              else if(s.id === 9) s.disabled = false;   //zavershen
              break;
            case 6:
              if(s.id === 1) s.disabled = true;         //new
              else if(s.id === 2) s.disabled = true;    //obrabotka
              else if(s.id === 3) s.disabled = true;    //sborka
              else if(s.id === 4) s.disabled = false;    //paid
              else if(s.id === 5) s.disabled = true;    //realize
              else if(s.id === 6) s.disabled = true;    //post
              else if(s.id === 7) s.disabled = true;    //vozvrat
              else if(s.id === 8) s.disabled = true;   //otmena
              else if(s.id === 9) s.disabled = false;   //zavershen
              break;
            case 7:
              if(s.id === 1) s.disabled = true;         //new
              else if(s.id === 2) s.disabled = true;    //obrabotka
              else if(s.id === 3) s.disabled = true;    //sborka
              else if(s.id === 4) s.disabled = false;    //paid
              else if(s.id === 5) s.disabled = false;    //realize
              else if(s.id === 6) s.disabled = true;    //post
              else if(s.id === 7) s.disabled = true;    //vozvrat
              else if(s.id === 8) s.disabled = true;    //otmena
              else if(s.id === 9) s.disabled = false;   //zavershen
              break;
            case 8:
              if(s.id === 1) s.disabled = true;         //new
              else if(s.id === 2) s.disabled = true;    //obrabotka
              else if(s.id === 3) s.disabled = true;    //sborka
              else if(s.id === 4) s.disabled = true;    //paid
              else if(s.id === 5) s.disabled = true;    //realize
              else if(s.id === 6) s.disabled = true;    //post
              else if(s.id === 7) s.disabled = true;    //vozvrat
              else if(s.id === 8) s.disabled = true;    //otmena
              else if(s.id === 9) s.disabled = true;    //zavershen
              break;
            case 9:
              if(s.id === 1) s.disabled = true;         //new
              else if(s.id === 2) s.disabled = true;    //obrabotka
              else if(s.id === 3) s.disabled = true;    //sborka
              else if(s.id === 4) s.disabled = false;    //paid
              else if(s.id === 5) s.disabled = true;    //realize
              else if(s.id === 6) s.disabled = true;    //post
              else if(s.id === 7) s.disabled = false;   //vozvrat
              else if(s.id === 8) s.disabled = true;    //otmena
              else if(s.id === 9) s.disabled = true;    //zavershen
              break;
          }
          result.push(s);
        }
      }
      return result;
    },
    getStatusNameById(id) {
      for(let s of this.getStatuses()) if(s.id === id) return s.nameR;
      return "";
    },
    getPrice() {
      let self = this;
      let json = { "item": this.val };
      axios({headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json,
        method: 'post',
        url: this.restUrl + '/rest/admin/orders/price',
        responseType: 'blob',
      }).then((response) => {
            let filename = response.request.getResponseHeader('content-disposition').split("=")[1];
            self.$global.forceFileDownload(response.data, filename);
          }).catch(function (e) {
            self.alert_message = "Ошибка загрузки данных. " + e;
            self.alert_header = "Внимание!";
            self.alert_switch = true;
          });
    },
    getLogs() {
      let self = this;
      let json = { orderId: this.val.id };
      axios({
        method: 'post',
        url: this.restUrl + "/rest/admin/orders/get_order_logs",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        if(response.data.status) {
          self.logs = response.data.logs;
          self.showLogs = true;
        }
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
      });

    },
    closeLogs() {
      this.logs=[];
      this.showLogs = false;
    },
    getStatus() {
      for(let t of this.types) if(t.id === this.val.type) for(let s of t.statuses) if(s.id === this.val.status) return s;
    },
    changeManager(val) {
      this.selectedManager = val;
      this.val.managerId = val.id;
    },
    changeClient(val) {
      if(val.id !== 0) {
        this.selectedClient = val;
        this.val.name = this.selectedClient.name;
        this.val.email = this.selectedClient.email;
        this.val.phone = this.selectedClient.phoneLast;
        this.val.address = this.selectedClient.addressLast;
        this.val.clientId = val.id;
      } else {
        this.selectedClient = val;
        this.val.name = '';
        this.val.email = '';
        this.val.phone = '';
        this.val.address = '';
        this.val.clientId = val.id;
      }
    },
    checkOrderSumManual(v, i) {
      if(v) i.manualSumVal = i.sum;
      else this.recalculateOrder();
    },
    storeItemSelected(selItem) {
      this.$emit('addToCart', selItem);
    },
    inputN(v, i, f, recalc, min) {
      let x = this.$global.inputNumberGE(v.target.value, min);
      if(x === 0) v.target.value = min;
      i[f] = x;
      if(recalc) this.recalculateOrder();
    },
    checkExist(id) {
      for(let i of this.val.items) if(i.artikul === id) return true;
      return false;
    },
    getHeaderForModal() {
      return this.val.id > 0 ? "ЗАКАЗ №" + this.val.id : "НОВЫЙ ЗАКАЗ";
    },
    checkOrderHasErrors() {
      for(let i of this.val.items) if(i.error.length > 0) return true;
      return false;
    },
    saveItem(close) {
      this.$emit('save', close);
    },
    recalculateOrder(manual) {
      if(this.auto_recalculate || manual) this.$emit('recalculate');
    },
    hide() {
      this.selectedManager = undefined;
      this.selectedClient = undefined;
      this.$emit('close');
    },
    checkCombobox(item, queryText) {
      console.log(item);
      console.log(queryText);
      if (item.val.toLowerCase().indexOf(queryText.toLowerCase()) > -1) return true;
    },
  }
});
</script>

