<template>
  <v-menu offset-y :close-on-content-click="false">
    <template v-slot:activator="{ props: menu }">
          <v-btn v-bind="mergeProps(menu)" icon :x-large="$vuetify.display.mdAndUp" :small="!$vuetify.display.mdAndUp" @click="checkLoggedIn()"><v-icon>mdi-account-circle</v-icon></v-btn>
    </template>

    <div style="width: 250px;" v-if="serverAvailable">
      <v-form v-if="!loggedIn">
        <v-text-field density="compact" variant="solo" name="username" @keyup.enter="nextPlease('password')" autocomplete="username"
                      id="username" :label="$t('login.login')" :autofocus="true" type="text"
                      v-model="username"></v-text-field>
        <v-text-field density="compact" variant="solo" @keyup.enter="nextPlease('code')" autocomplete="current-password" id="password"
                      :label="$t('login.password')" name="password" type="password"
                      v-model="password"></v-text-field>
        <v-text-field density="compact" variant="solo" @keyup.enter="login()" id="code" :label="$t('login.code')" name="code"
                      type="text" v-model="secret"></v-text-field>
        <div style="color: #f00; font-size: 12px" v-if="loginError.length > 0" border-color="error" icon="mdi-alert-circle">Неправильный логин или пароль
<!--          {{ (loginError && loginError.length > 0 ? $t('login.' + loginError) : '') }}-->
        </div>
      </v-form>

      <div v-if="!loggedIn">
        <v-btn @click="login">{{ $t('login.enter') }}</v-btn>
      </div>
    </div>

    <v-card class="elevation-12" v-else>
      <v-card-text>
        <v-alert :value="true" color="error" icon="warning" outlined style="text-align: center">
          {{ $t('login.server_unavailable') }} <br>
          {{ $t('login.call_to_admin') }}
          <v-btn @click="$router.go($router.currentRoute)" block color="red">
            <v-icon>refresh</v-icon>
            {{ $t('login.refresh') }}
          </v-btn>
        </v-alert>
      </v-card-text>
    </v-card>


  </v-menu>
</template>

<script>
import {mergeProps} from 'vue'
import router from "@/router/router";
import axios from "axios";

export default {
  props: {},
  data() {
    return {
      urlFront: process.env.VUE_APP_FRONTEND_BASE_URL,
      restUrl: process.env.VUE_APP_BACKEND_BASE_URL,
      loginError: '',
      serverAvailable: false,
      username: '',
      password: '',
      secret: '',
    }
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    this.checkIsServerAvailable();
  },
  methods: {
    checkLoggedIn() {
      if (this.loggedIn) router.push('/')
    },
    mergeProps,
    nextPlease(id) {
      document.getElementById(id).focus();
    },
    login() {
      let self = this;
      let user = {username: this.username, password: this.password, secret: this.secret};
      this.$store.dispatch("auth/login", user).then(
          () => {
            router.push('/');
            // let roles = this.$store.state.auth.user.roles;
            // if(roles.includes('ADMIN'))
            // else if(roles.includes('DEALER')) window.location.href = "/";
          },
          (error) => {
            self.loginError = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
          }
      );
    },
    checkIsServerAvailable() {
      let self = this;
      axios({
        method: 'post',
        url: this.restUrl + '/rest/auth/ping',
      }).then(function (response) {
        self.serverAvailable = response.data;
        clearTimeout(self.timeout);
        if (!self.serverAvailable) {
          self.timeout = setTimeout(function () {
            self.checkIsServerAvailable();
          }, 3000);
        } else {
          clearTimeout(self.timeout);
        }

      }).catch(function () {
        self.serverAvailable = false;
        clearTimeout(self.timeout);
        if (!self.serverAvailable) {
          self.timeout = setTimeout(function () {
            self.checkIsServerAvailable();
          }, 3000);
        } else {
          clearTimeout(self.timeout);
        }

      })
    },
  }
};
</script>

<style lang="scss" scoped></style>
