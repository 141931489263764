<template>
  <v-app>
    <v-main>
      <slot/>
    </v-main>
    <!--  SHUTDOWN TIMER  -->
    <v-snackbar timeout="-1" location="top" v-model="$store.state.shutdownObj.run" vertical>
      <div class="text-subtitle-1 pb-2">Система будет отключена для проведения технических работ через {{ $store.state.shutdownObj.min }} минут.</div>
      <p v-if="$store.state.shutdownObj.len > 0">Время проведения технических работ: {{ $store.state.shutdownObj.len }} минут.</p>
    </v-snackbar>
  </v-app>
</template>
<script>
export default {

  name: 'EmptyLayout',
  created() {
    console.log("EMPTY LAYOUT");
  },
}
</script>
