<template>
  <v-dialog persistent :model-value="value">
    <v-dialog hide-overlay persistent :model-value="true" width="300">
      <v-card>
        <v-card-text>
          <div style="width:90%; float:left; display:inline-block; text-align: left;"><b>Загрузка...</b></div>
          <div @click="disableLoader()" style="width:10%; display:inline-block; text-align: right;cursor: pointer;"><b>X</b></div>
          <v-progress-linear class="mb-0" color="blue lighten-4" height="15px" :indeterminate="true" style="border-radius: 5px"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>
<script>
export default {
  props: [
    'value'
  ],
  methods: {
    disableLoader() {
      console.log("close loader")
        this.$emit('close');
    }
  }
}
</script>
