<style scoped>
.cells-wrapper-mobile {
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin-bottom: 3px;
  font-size: 10px;
  text-align: center;
}
.cell-mobile {
  border-bottom: 1px solid #dedede;
}
.pagination-or-element {
  display: inline-block;
  color: #00ff00;
  font-size: 12px;
  background-color: #1e1e1e;
  text-align: center;
  height: 13px;
  line-height: 13px;
  outline: none;
}
.v-lazy-component.v-lazy-component--loading {
  filter: blur(15px);
}

.v-lazy-component.v-lazy-component--loaded {
  filter: blur(0);
  transition: filter 0.4s;
}
</style>
<template>
  <default-layout-dynamic>

    <loader @close="displayLoader = false" v-model="displayLoader"></loader>
    <ahtung @close="alert_switch = false" :value="alert_switch" :message="alert_message" :header="alert_header"></ahtung>
    <v-progress-linear :active="displayLoaderRecalculate" indeterminate></v-progress-linear>
    <v-navigation-drawer :width="$vuetify.display.mdAndUp ? $vuetify.display.width / 2.5 : $vuetify.display.width" :disable-resize-watcher="true" :disable-route-watcher="true" fixed style="padding:5px;" v-model="sidePanel" location="right">

      <v-card elevation=10 style="margin-bottom: 5px">
        <v-card-title style="padding: 0; margin-bottom: 5px">
          <v-row :dense="true">
            <v-col align-self="center" cols="11" style="text-align: center;">ЗАКАЗЫ</v-col>
            <v-col align-self="center" cols="1" style="display: flex;justify-content: center;align-items: center;">
              <v-tooltip location="bottom">
                <template v-slot:activator="{ props: tooltip }">
                  <v-btn v-bind="tooltip" @click="sidePanel = !sidePanel" size="x-small" icon="mdi-close"></v-btn>
                </template>
                <span>Закрыть панель заказов</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
      <v-card>
        <v-card-text style="height: 90%; padding: 5px; ">
          <div style="width: 100%; height: 100%; overflow-y: auto; border: 1px solid green;">
            <template v-if="selectedStore">
              <div style="width: 100%; text-align: center;">
                <div style="display: inline-block; width: 10%; text-align: center;">
                  <v-tooltip top>
                    <template v-slot:activator="{ props: tooltip }"><v-btn class="pagination-or-element" :disabled="oRpage <= 1" @click="oRpage -= 1" v-bind="tooltip" size="x-small">&lt;</v-btn></template>
                    <span>Предыдущая страница</span>
                  </v-tooltip>
                </div>

                <div style="display: inline-block; width: 77%; text-align: center;">
                  <v-tooltip location="top">
                    <template v-slot:activator="{ props: tooltip }">
                      <span class="pagination-or-element" style="border: 1px solid green; border-radius: 2px; width: 49%; height: 15px;" v-bind="tooltip">
                        <input class="pagination-or-element" :value="oRpage" @change="v => oRpage = parseInt(v.target.value)" @keyup.enter="loadOrdersData()" style="width:70%;" type="number"/>из {{oRpages}}
                      </span>
                    </template>
                    <span>Страница</span>
                  </v-tooltip>

                  <v-tooltip  location="top">
                    <template v-slot:activator="{ props: tooltip }">
                      <span class="pagination-or-element" style="border: 1px solid green; border-radius: 2px; width: 49%; height: 15px;" v-bind="tooltip">
                        <input class="pagination-or-element" :value="oRelPerPage" @change="v => oRelPerPage = parseInt(v.target.value)" style="width:100%;" type="number"/>
                      </span>
                    </template>
                    <span>Элементов на странице</span>
                  </v-tooltip>
                </div>

                <div style="display: inline-block; width: 10%; text-align: center;">
                  <v-tooltip location="top">
                    <template v-slot:activator="{ props: tooltip }"><v-btn class="pagination-or-element" :disabled="oRpage >= oRpages" @click="oRpage += 1" v-bind="tooltip" size="x-small">&gt;</v-btn></template>
                    <span>Следующая страница</span>
                  </v-tooltip>
                </div>

              </div>
            </template>
            <template v-else>
              Склад не выбран.
            </template>
          </div>
        </v-card-text>
      </v-card>

      <v-card elevation=10 style="height: 90%; margin-bottom: 5px">
        <v-card-text style="height: 90%; padding: 5px; overflow-y: auto">
          <table style="border-radius: 5px; width: 100%;">
            <order-client :store="selectedStore" :profile="profile" @save="submitOrder" :shippings="selectedStore.shippings" :types="typesOr" :wrapper="o" :mobile="m" v-for="o in cabinetData.orders" @update-order="loadOrdersData()" @update-all="loadStoreData()" :key="o.id"></order-client>
          </table>
        </v-card-text>

                <v-divider></v-divider>
        <v-card-actions style="margin-bottom: 5px; height: 10%">

        </v-card-actions>
      </v-card>

    </v-navigation-drawer>

    <v-row :dense="true">
      <v-col cols="8"><v-select variant="solo" v-if="profile.store_select_allowed" label="Выбор склада" :disabled="stores.length <= 1" :items="stores" :hide-details="true" density="compact" :return-object="true" :item-title="'name'" v-model="selectedStore"></v-select></v-col>
      <v-col cols="4">
        <v-tooltip location="bottom">
          <template v-slot:activator="{ props: tooltip }"><v-btn @click="sidePanel = !sidePanel" v-bind="tooltip" icon="mdi-clipboard-flow" size="x-small"></v-btn></template>
          <span>Движение товаров</span>
        </v-tooltip>
        <v-badge v-if="editOr.items" :content="editOr.items.length" :value="editOr.items.length > 0" :color="checkOrderHasErrors() ? '#ff0000' : '#5b7f23'" overlap location="bottom end">
          <v-tooltip location="top">
            <template v-slot:activator="{ props: tooltip }">
              <v-btn v-bind="tooltip" size="x-small" :color="selectedStore.stopSales ? '#8f0000':''" @click="(selectedStore.stopSales ? $global.doNothing() : (cartDialog = !cartDialog))" icon="mdi-cart-outline"></v-btn>
            </template>
            <span>{{selectedStore.stopSales ? 'Покупки временно отключены':'Корзина покупок'}}</span>
          </v-tooltip>
        </v-badge>
        <v-tooltip  location="bottom">
          <template v-slot:activator="{ props: tooltip }"><v-btn v-bind="tooltip" @click="showItemsFilters = !showItemsFilters" size="x-small" icon="mdi-arrow-down"></v-btn></template>
          <span>Скрыть/показать фильтры</span>
        </v-tooltip>
      </v-col>
      <template v-if="showItemsFilters">
        <v-col cols="6" v-if="selectedStore"></v-col>
        <v-col align-self="center" cols="12" md="10">
<!--          <v-text-field :density="'compact'" :hide-details="true" v-model="filterItems" label="Фильтр товаров по названию"></v-text-field>-->
          <v-text-field variant="solo" :density="'compact'" :hide-details="true" v-model="iTsearch" @change="loadItemsData()" label="Текстовый поиск"></v-text-field>
        </v-col>
        <v-col cols="6" md="1">
          <v-text-field variant="solo" :density="'compact'" :hide-details="true" label="№ стр:" type="number" v-model.number="iTpage" min=1 step=1></v-text-field>
        </v-col>
        <v-col cols="6" md="1">
          <v-text-field variant="solo" :density="'compact'" :hide-details="true" type="number" v-model.number="iTelPerPage" label="шт/стр"></v-text-field>
        </v-col>
      </template>

      <v-col cols="12" v-if="selectedStore && cabinetData.items.length > 0 && iTpages > 1">
        <v-pagination size="x-small" v-model="iTpage" :length="iTpages" :rounded="true" ></v-pagination>
      </v-col>
      <v-col cols="12" v-if="selectedStore">

        <v-table v-if="!m" :fixed-header="true" height="600" :density="'compact'" :style="m ? 'font-size: 10px;':''">
          <tbody>
          <lazy-component wrapper-tag="tr" v-for="item in cabinetData.items" :key="item.id">
            <td>
              <v-tooltip location="bottom">
                <template v-slot:activator="{ props: tooltip }">
                  <v-btn v-bind="tooltip" icon="mdi-cart-outline" :color="checkSelectedStoreItemsId(item.artikul, item.price) ? '#5b7f23' : '#ff0000'" size="x-small" @click="add_to_cart(item)"></v-btn>
                </template>
                <span>Добавить в корзину</span>
              </v-tooltip>
            </td>

            <td>{{ item.artikul }}</td>
            <td>
              <v-tooltip location="top">
                <template v-slot:activator="{ props: tooltip }">
                  <span style="color: #5b7f23" v-bind="tooltip">{{ item.breadcrumbs }}</span>
                </template>
                <span>
                  <span v-if="item.images.length > 0">
                    <v-img v-for="(img, index) in item.images.split(';')" :key="index" :src="restUrl + img" width="130"/><br>
                  </span>
                {{ item.description }}
                </span>
              </v-tooltip>
            </td>
            <td>{{ (item.count > 100 ? '100+' : item.count) }}</td>
            <td>{{ item.price }}</td>
            <template #placeholder>
              <tr>
                <td colspan="6">Loading...</td>
              </tr>
            </template>
          </lazy-component>
          </tbody>
        </v-table>

        <lazy-component v-else wrapper-tag="table" class="cells-wrapper-mobile" v-for="item in cabinetData.items" :key="item.id">
          <tbody>
          <tr>
            <td class="cell-mobile" style="width: 100%; text-align: left;" colspan="4">
              <v-tooltip location="top">
                <template v-slot:activator="{ props: tooltip }">
                  <span style="color: #5b7f23" v-bind="tooltip">{{ item.breadcrumbs }}</span>
                </template>
                <span>
                  <span v-if="item.images.length > 0">
                    <v-img v-for="(img, index) in item.images.split(';')" :key="index" :src="restUrl + img" width="130"/><br>
                  </span>
                {{ item.description }}
                </span>
              </v-tooltip>
            </td>
          </tr>

          <tr>
            <td class="cell-mobile" style="width: 15%">
              <b>{{ item.artikul }}</b>
            </td>
            <td class="cell-mobile" style="width: 15%">
              <b>{{ (item.count > 100 ? '100+' : item.count) }}</b>
            </td>
            <td class="cell-mobile" style="width: 15%">
              <b>{{ item.price }}</b>
            </td>
            <td class="cell-mobile" style="width: 15%">
              <v-tooltip location="bottom">
              <template v-slot:activator="{ props: tooltip }">
                <v-btn v-bind="tooltip" icon="mdi-cart-outline" :color="checkSelectedStoreItemsId(item.artikul, item.price) ? '#5b7f23' : '#ff0000'" size="x-small" @click="add_to_cart(item)"></v-btn>
              </template>
              <span>Добавить в корзину</span>
              </v-tooltip>
            </td>
          </tr>
          </tbody>
          <template #placeholder>
            <tr>
              <td colspan="4">Loading...</td>
            </tr>
          </template>
        </lazy-component>


      </v-col>
    </v-row>

    <order-editor-client v-model="editOr" :types="typesOr" :profile="profile" :store="selectedStore" :template-or-it="templateOrIt" @save="saveOrder"
                         @close="cartDialog = false" @recalculate="recalculateOrder()" :loader="displayLoaderRecalculate"
                         @clear="clearOrder()" :show="cartDialog" :mobile="m" @add-to-cart="add_to_cart"></order-editor-client>



  </default-layout-dynamic>
</template>

<script>

import DefaultLayoutDynamic from "../components/layouts/DefaultLayoutDynamic";
import Loader from "../components/Loader";
import axios from "axios";
import Ahtung from "../components/Ahtung";
import router from "@/router/router";
import OrderEditorClient from "@/components/orders/OrderEditorClient.vue";
import OrderClient from "@/components/orders/OrderClient.vue";

export default {
  name: "Stores",
  components: {
    OrderClient,
    OrderEditorClient,
    DefaultLayoutDynamic,
    Loader,
    Ahtung
  },
  data() {
    return {
      restUrl: process.env.VUE_APP_BACKEND_BASE_URL,
      displayLoader: false,
      displayLoaderRecalculate: false,

      sidePanel: false,

      stores: [],
      selectedStore: undefined,
      selectedStoreItems: [],
      cabinetData: { items: [], orders: [] },

      showItemsFilters: true,
      filterItems: '',

      profile: {},

      iTsortAsc: false,
      iTsortField: 'id',
      iTsearch: '',
      iTpages: 0,
      iTpage: 1,
      iTelPerPage: 50,
      iTshowZeroProducts: false,
      iTshowCountLessThen: 0,

      oRsortAsc: false,
      oRsortField: 'id',
      oRsearch: '',
      oRpages: 0,
      oRpage: 1,
      oRelPerPage: 25,
      oRselectedTypes: [],
      oRselectedStatuses: [],

      typesOr: [],
      cartDialog: false,

      firstLoad: true,

      templateOr: {},
      templateOrIt: {},
      editOr: {},
      savingOrder: false,

      alert_switch: false,
      alert_message: "",
      alert_header: "",

      m: true,

    }
  },
  mounted() {
    this.m = !this.$vuetify.display.mdAndUp;
  },
  created() {
    if (!this.loggedIn) router.push('/start')
    else {
      this.getData();
    }
  },
  methods: {
    recalculateOrder() {
      this.displayLoaderRecalculate = true;
      let self = this;
      let json = { item: this.editOr };
      axios({
        method: 'post',
        url: this.restUrl + "/rest/client/stores/recalculate_order",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        if(response.data.status) {
          self.editOr = response.data.item;
        }
        self.displayLoaderRecalculate = false;
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.displayLoaderRecalculate = false;
      });
    },
    submitOrder(o) {
      let self = this;
      let json = { item: o };
      axios({
        method: 'post',
        url: this.restUrl + "/rest/client/stores/submit_order",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        if(response.data.status) {
            console.log('success');
        }
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.displayLoader = false;
      });
    },
    updateOrder() {
      console.log(this.profile);
      this.editOr.storeId = this.selectedStore.id;
      this.editOr.type = 1;
      this.editOr.status = 1;
      this.editOr.clientId = this.profile.id;
      this.editOr.name = this.profile.name;
      this.editOr.nameCreator = this.profile.name;
      this.editOr.managerId = this.profile.ownerId;
      this.editOr.email = this.profile.email;
      this.editOr.phone = this.profile.phoneLast.length > 0 ? this.profile.phoneLast : this.profile.phone;
      this.editOr.address = this.profile.addressLast;
      if(this.selectedStore.shippings.length > 0) this.editOr.shippingId = this.selectedStore.shippings[0].id;
      console.log(this.editOr);
    },
    add_to_cart(item) {
      if(!this.checkSelectedStoreItemsId(item.artikul, item.price)) {
        let itemO = this.$global.clone(this.templateOrIt);
        itemO.artikul = item.artikul;
        itemO.images = item.images;
        itemO.searchString = item.searchString;
        itemO.breadcrumbs = item.breadcrumbs;
        itemO.description = item.description;
        itemO.price = item.price;
        itemO.priceCalculated = item.price;
        itemO.dif = item.dif;
        itemO.formerId = item.formerId;
        itemO.count = 1;
        this.editOr.items.unshift(itemO);
        this.recalculateOrder();
      } else {
        console.log('added');
      }
    },
    checkSelectedStoreItemsId(artikul, price) {
      for(let i = 0; i < this.editOr.items.length; i++) if(this.editOr.items[i].artikul === artikul && this.editOr.items[i].price === price) return true;
      return false;
    },
    saveOrder() {
      this.displayLoader = true;
      this.savingOrder = true;
      let self = this;
      let json = { item: this.editOr };
      axios({
        method: 'post',
        url: this.restUrl + "/rest/client/stores/save_order",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        if(response.data.status) {
          if(response.data.error.length === 0) {
            if (!response.data.item.hasErrors) {
              self.profile = response.data.user;
              self.$nextTick(() => {
                self.clearOrder();
                self.updateOrder();
                self.savingOrder = false;
              });
              self.cartDialog = false;
            } else {
              self.editOr = response.data.item;
            }
          } else {
            self.editOr = response.data.item;
            self.alert_message = response.data.error;
            self.alert_header = "Внимание!";
            self.alert_switch = true;
          }
        }
        self.displayLoader = false;
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.displayLoader = false;
      });
    },
    loadItemsData() {
      this.displayLoader = true;
      let self = this;
      let json = {storeId: this.selectedStore.id, iTsearch: this.iTsearch, iTsortField: this.iTsortField, iTsortAsc: this.iTsortAsc, iTshowZeroProducts: this.iTshowZeroProducts, iTshowCountLessThen: this.iTshowCountLessThen, iTpage: this.iTpage - 1, iTelPerPag: this.iTelPerPage};
      axios({
        method: 'post',
        url: this.restUrl + "/rest/client/stores/items/data",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        self.cabinetData.items = response.data.items.content;
        self.iTpages = response.data.items.totalPages;
        if (self.iTpage > self.iTpages && self.iTpages != 0) self.iTpage = self.iTpages;
        self.displayLoader = false;

      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.displayLoader = false;
      });
    },
    loadOrdersData() {
      this.displayLoader = true;
      let self = this;
      let json = {storeId: this.selectedStore.id, oRsearch: this.oRsearch, oRsortField: this.oRsortField, oRsortAsc: this.oRsortAsc, oRselectedTypes: this.oRselectedTypes, oRselectedStatuses: this.oRselectedStatuses, oRpage: this.oRpage - 1, oRelPerPag: this.oRelPerPage};
      axios({
        method: 'post',
        url: this.restUrl + "/rest/client/stores/orders/data",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        console.log(response.data.orders)
        self.cabinetData.orders = response.data.orders.content;
        self.oRpages = response.data.orders.totalPages;
        if (self.oRpage > self.oRpages && self.oRpages !== 0) self.oRpage = self.oRpages;
        self.displayLoader = false;
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.displayLoader = false;
      });
    },
    loadStoreData() {
      this.displayLoader = true;
      let self = this;
      let json = {storeId: this.selectedStore.id, iTsearch: this.iTsearch, iTsortField: this.iTsortField, iTsortAsc: this.iTsortAsc, iTshowZeroProducts: this.iTshowZeroProducts, iTshowCountLessThen: this.iTshowCountLessThen, iTpage: this.iTpage - 1, iTelPerPag: this.iTelPerPage,
        oRsearch: this.oRsearch, oRsortField: this.oRsortField, oRsortAsc: this.oRsortAsc, oRselectedTypes: this.oRselectedTypes, oRselectedStatuses: this.oRselectedStatuses, oRpage: this.oRpage - 1, oRelPerPag: this.oRelPerPage };

      axios({
        method: 'post',
        url: this.restUrl + "/rest/client/stores/store/data",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        self.cabinetData.items = response.data.items.content;
        self.iTpages = response.data.items.totalPages;
        if (self.iTpage > self.iTpages && self.iTpages != 0) self.iTpage = self.iTpages;

        self.cabinetData.orders = response.data.orders.content;
        self.oRpages = response.data.orders.totalPages;
        if (self.oRpage > self.oRpages && self.oRpages !== 0) self.oRpage = self.oRpages;

        self.firstLoad = false;
        self.displayLoader = false;
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.displayLoader = false;
      });
    },
    getData() {
      this.displayLoader = true;
      let self = this;
      let json = {};
      axios({
        method: 'post',
        url: this.restUrl + "/rest/client/stores/data",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        self.stores = response.data.stores;
        self.typesOr = response.data.typesOr;
        if(self.stores.length > 0)self.selectedStore = self.stores[0];
        self.templateOr = response.data.templateOr;
        self.templateOrIt = response.data.templateOrIt;
        self.profile = response.data.user;
            self.$nextTick(() => {
              self.clearOrder();
              self.updateOrder();
            });
        self.displayLoader = false;
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.displayLoader = false;
      });
    },
    clearOrder() {
      this.editOr = this.$global.clone(this.templateOr);
      this.editOr.dontApplyToStore = false;
      this.editOr.dontApplyToCashier = false;
    },
    checkOrderHasErrors() {
      if(this.editOr.items.length > 0) {
        for (let i of this.editOr.items) if (i.error.length > 0) return true;
      }
      return false;
    },
  },
  watch: {
    '$store.state.storesUpdater'(val) {
      if(val.storeId === this.selectedStore.id) {
        this.loadItemsData();
        if(this.editOr.items && this.editOr.items.length > 0 && !this.savingOrder) this.recalculateOrder();
      }
    },
    '$store.state.ordersUpdater'(val) {
      if(val.storeId === this.selectedStore.id) {
        this.loadOrdersData();
      }
    },
    '$vuetify.display.mdAndUp'(val) {
      this.m = !val;
    },
    selectedStore(val) {
      if(val) {
        this.firstLoad = true;
        this.iTpages = 0;
        this.iTpage = 1;
        this.oRpages = 0;
        this.oRpage = 1;
        this.clearOrder();
        this.updateOrder();
        this.loadStoreData(val);
      } else {
        this.cabinetData = {items: [], orders: []};
      }
    },
    oRsortAsc() {
      if(!this.firstLoad) this.loadOrdersData();
    },
    oRsortField() {
      if(!this.firstLoad) this.loadOrdersData();
    },
    oRelPerPage() {
      if(!this.firstLoad) this.loadOrdersData();
    },
    oRshowZeroProducts() {
      if(!this.firstLoad) this.loadOrdersData();
    },
    oRshowCountLessThen() {
      if(!this.firstLoad) this.loadOrdersData();
    },
    oRpage(val) {
      if(val < 1) this.oRpage = 1;
      else if(val > this.oRpages) this.oRpage = this.oRpages

      if(!this.firstLoad) this.loadOrdersData();
    },
    iTsortField() {
      if(!this.firstLoad) this.loadItemsData();
    },
    iTsortAsc() {
      if(!this.firstLoad) this.loadItemsData();
    },
    iTelPerPage() {
      if(!this.firstLoad) this.loadItemsData();
    },
    iTshowZeroProducts() {
      if(!this.firstLoad) this.loadItemsData();
    },
    iTshowCountLessThen() {
      if(!this.firstLoad) this.loadItemsData();
    },
    iTpage() {
      if(!this.firstLoad) this.loadItemsData();
    },
  },
  computed: {
    computedItems() {
      if(this.filterItems.length > 2) {
        let result = [];
        let words = this.filterItems.split(" ");
        for(let item of this.cabinetData.items) if(words.every(word => item.searchString.toLowerCase().includes(word.toLowerCase()))) result.push(item);
        return result;
      } else return this.cabinetData.items;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    user() {
      return this.$store.state.auth.user;
    },
  },
};
</script>
