<template>
  <v-text-field variant="solo" @blur="$emit('kp')" @keyup.enter="$emit('kp')" :disabled="disabled" :color="color" :label="label" density="compact" :hide-details="true" :model-value="modelValue" ref="inputRef" type="text"/>
</template>

<script>
import { useCurrencyInput } from 'vue-currency-input'
import { watch } from "vue";
export default {
  name: 'CurrencyInput',
  props: {
    color: String,
    disabled: Boolean,
    label: String,
    modelValue: Number,
    options: Object
  },
  setup(props) {
    const { inputRef, setValue } = useCurrencyInput(props.options)
    watch(() => props.modelValue, (value) => { setValue(value) })
    return { inputRef }
  }
}
</script>
