export default {
  "language_selector": {
    "switch_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пераключыць мову"])}
  },
  "login": {
    "authorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Аўтарызацыя"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лагін:"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль:"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код:"])},
    "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["УВАЙСЦІ"])},
    "login_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ забаронены"])},
    "server_unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервер часова недаступны."])},
    "call_to_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Калі праблема доўжыцца больш за 5 хвілін, звярніцеся да адміністратара."])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Абнавіць"])}
  }
}