<style lang="scss" scoped>

</style>
<template>

  <v-dialog :fullscreen="mobile" :model-value="show" absolute persistent :scrollable="true">

    <v-card style="overflow-x: hidden">
      <v-card-title class="headline">

      <v-row :no-gutters="true">
        <v-col cols="7">
          <span :style="'margin-left: 14px; font-size:' + (mobile ? '12px':'20px')"><b>Редактор кассы</b></span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col style="text-align: right">
          <v-tooltip style="display: inline-block" top>
            <template v-slot:activator="{ props: tooltip }">
              <v-btn v-bind="tooltip" icon :size="(mobile ?'x-small':'x-small')" @click="hide"><v-icon>mdi-close</v-icon></v-btn>
            </template>
            <span>Закрыть</span>
          </v-tooltip>
        </v-col>
      </v-row>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text :style="mobile ? 'font-size: 10px; min-height: 300px;':''">
        <currency-input v-model="item.balance" :disabled="false" label="Баланс, BYN" :options="{ currency: 'BYN', currencyDisplay: 'hidden'}"/>
        <currency-input v-model="item.cashier" :disabled="false" label="Касса, BYN" :options="{ currency: 'BYN', currencyDisplay: 'hidden' }"/>
        <currency-input v-model="item.debt" :disabled="false" label="Дебит, BYN" :options="{ currency: 'BYN', currencyDisplay: 'hidden' }"/>
        <currency-input v-model="item.kredit" :disabled="false" label="Кредит, BYN" :options="{ currency: 'BYN', currencyDisplay: 'hidden' }"/>
        <currency-input v-model="item.own" :disabled="false" label="Свои, BYN" :options="{ currency: 'BYN', currencyDisplay: 'hidden' }"/>
        <currency-input v-model="item.ownTeor" :disabled="false" label="Свои расчетные, BYN" :options="{ currency: 'BYN', currencyDisplay: 'hidden' }"/>
      </v-card-text>

      <v-card-actions>
        <v-row :dense="true">
          <v-col cols="12" md="6" :style="mobile ? '':'text-align: left'">
            <v-btn :block="mobile" :disabled="!store.stopSales && false" x-small @click="saveItem()"><span style="color: green">Сохранить</span></v-btn>
          </v-col>
<!--          <v-col cols="12" md="6" :style="mobile ? '':'text-align: right'">-->
<!--            <v-btn :block="mobile" x-small @click="hide()"><span style="color: red;">Закрыть</span></v-btn>-->
<!--          </v-col>-->
        </v-row>
      </v-card-actions>
    </v-card>

    <ahtung @close="alert_switch = false" :value="alert_switch" :message="alert_message" :header="alert_header"></ahtung>
    <confirm-dialog ref="confirm"></confirm-dialog>

  </v-dialog>

</template>

<script>


import Ahtung from "@/components/Ahtung.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import {computed, defineComponent} from "vue";
import CurrencyInput from "@/components/common/CurrencyInput.vue";

export default defineComponent ({
  name: "EditCashier",
  components: {
    CurrencyInput,
    ConfirmDialog,
    Ahtung,
  },
  props: {
    modelValue: {type: Object, required: true, default() {return {}}},
    show: {type: Boolean, required: true, default: false},
    store: {type: Object, required: true, default() {return {}}},
    mobile: {type: Boolean, required: true, default: false},
  },
  setup(props, { emit }) {
    const item = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });
    return { item };
  },
  data() {
    return {
      restUrl: process.env.VUE_APP_BACKEND_BASE_URL,
      displayLoader: false,
      alert_switch: false,
      alert_message: "",
      alert_header: "",
    }
  },
  watch: {
  },
  mounted() {
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },

  methods: {
    inputN(v, i, f) {
      let x = this.$global.inputNumberGE(v.target.value, 0);
      if(x === 0) v.target.value = 0;
      i[f] = x;
    },
    saveItem() {
      this.$emit('save');
    },
    hide() {
      this.$emit('close');
    },
  }
});
</script>

