import {Client} from '@stomp/stompjs';

export const websocket = {
    namespaced: true,

    state: {
        websocketUrl: process.env.VUE_APP_BACKEND_WS_URL + '/ws',
        stompClient: null,
        subscriptionUpdateStores: null,
        subscriptionUpdateOrders: null,
        subscriptionUpdateNews: null,
        subscriptionShutdownTimer: null,
        subscriptionCashierUpdate: null,
    },

    getters: {
        getStompClient: (state) => state.stompClient,
        getSubscriptionUpdateStores: (state) => state.subscriptionUpdateStores,
        getSubscriptionUpdateOrders: (state) => state.subscriptionUpdateOrders,
        getSubscriptionUpdateNews: (state) => state.subscriptionUpdateNews,
        getSubscriptionShutdownTimer: (state) => state.subscriptionShutdownTimer,
        getSubscriptionCashierUpdate: (state) => state.subscriptionCashierUpdate,
    },

    mutations: {
        setStompClient(state, value) {
            state.stompClient = value;
        },
        deactivateClient(state, context) {
            if (context.state.stompClient) {
                if (context.state.subscriptionUpdateStores) {
                    context.getters.getSubscriptionUpdateStores.unsubscribe();
                    context.commit('setSubscriptionUpdateStores', null);
                }
                if (context.state.subscriptionUpdateOrders) {
                    context.getters.getSubscriptionUpdateOrders.unsubscribe();
                    context.commit('setSubscriptionUpdateOrders', null);
                }
                if (context.state.subscriptionUpdateNews) {
                    context.getters.getSubscriptionUpdateNews.unsubscribe();
                    context.commit('setSubscriptionUpdateNews', null);
                }
                if (context.state.subscriptionShutdownTimer) {
                    context.getters.getSubscriptionShutdownTimer.unsubscribe();
                    context.commit('setSubscriptionShutdownTimer', null);
                }
                if (context.state.subscriptionCashierUpdate) {
                    context.getters.getSubscriptionCashierUpdate.unsubscribe();
                    context.commit('setSubscriptionCashierUpdate', null);
                }
                context.getters.getStompClient.deactivate();
                context.commit('setStompClient', null);
            }
        },
        activateClient(state, context) {
            context.getters.getStompClient.onConnect = function () {
                context.commit('setSubscriptionUpdateStores', context.getters.getStompClient.subscribe('update_stores', function (message) {
                    console.log("message: update stores");
                    console.log(message);
                    const val = JSON.parse(message.body);
                    context.commit("updateStores", val, {root: true});
                }));
                context.commit('setSubscriptionUpdateOrders', context.getters.getStompClient.subscribe('update_orders', function (message) {
                    console.log("message: update orders");
                    console.log(message);
                    const val = JSON.parse(message.body);
                    context.commit("updateOrders", val, {root: true});
                }));
                context.commit('setSubscriptionUpdateNews', context.getters.getStompClient.subscribe('update_news', function (message) {
                    console.log("message: update news");
                    console.log(message);
                    const val = JSON.parse(message.body);
                    context.commit("updateNews", val, {root: true});
                }));
                context.commit('setSubscriptionShutdownTimer', context.getters.getStompClient.subscribe('shutdown_timer', function (message) {
                    console.log("message: shutdown timer");
                    console.log(message);
                    const val = JSON.parse(message.body);
                    context.commit("updateShutdownTimer", val, {root: true});
                }));
                context.commit('setSubscriptionCashierUpdate', context.getters.getStompClient.subscribe('cashier_update', function (message) {
                    console.log("message: CashierUpdate");
                    console.log(message);
                    const val = JSON.parse(message.body);
                    context.commit("updateCashierUpdate", val, {root: true});
                }));
            };
            context.getters.getStompClient.onStompError = function (frame) {
                console.log('Broker reported error: ' + frame.headers['message']);
                console.log('Additional details: ' + frame.body);
            };

            context.getters.getStompClient.onDisconnect = function () {
                console.log('deactivation');
            };
            context.getters.getStompClient.activate();

        },
        setSubscriptionUpdateStores(state, value) {
            state.subscriptionUpdateStores = value;
        },
        setSubscriptionUpdateOrders(state, value) {
            state.subscriptionUpdateOrders = value;
        },
        setSubscriptionUpdateNews(state, value) {
            state.subscriptionUpdateNews = value;
        },
        setSubscriptionShutdownTimer(state, value) {
            state.subscriptionShutdownTimer = value;
        },
        setSubscriptionCashierUpdate(state, value) {
            state.subscriptionCashierUpdate = value;
        },
    },

    actions: {
        disconnect(context) {
            context.commit('deactivateClient', context)
        },
        connect(context) {
            const settings = {
                brokerURL: context.state.websocketUrl,
                debug: function (str) {
                    console.log(str);
                },
                reconnectDelay: 5000,
                heartbeatIncoming: 4000,
                heartbeatOutgoing: 4000,

            };
            if (!context.getters.getStompClient) {
                context.commit('setStompClient', new Client(settings));
            }
            context.commit('activateClient', context)
        },
    },
}
