<style>
.search-catalog-result-wrapper {
  display: inline-block;
  min-height: 200px;
  min-width: 900px;
  height: 200px;
  font-size: 12px;
  font-weight: bold;
  width: 900px;
  max-height: 200px;
  max-width: 900px;
  border: 2px solid gray;
  border-radius: 5px;
  background: #626161;
  overflow: auto;
}
.search-catalog-result-wrapper-mob {
  display: inline-block;
  min-height: 200px;
  min-width: 350px;
  height: 200px;
  font-size: 9px;
  font-weight: bold;
  width: 350px;
  max-height: 200px;
  max-width: 350px;
  border: 2px solid gray;
  border-radius: 5px;
  background: #626161;
  overflow: auto;
}
.search-catalog-result-row.product {
  color: #bdb8fd;
}
.search-catalog-result-row.category {
  color: #9ab771;
}
.search-catalog-result-row:hover {
  color: white;
  cursor: pointer;
}
.search-catalog-result-image {
  display: inline-block;
  max-width: 14px;
  max-height: 14px;
  margin-right: 2px;
  transition: 0.2s;
  transform-origin: left top;
}
.search-catalog-result-image:hover {
  transform: scale(10)
}
</style>
<template>

          <v-row :dense="true">
            <v-col cols="12">
              <v-menu v-model="showSearchResult" :open-on-click="false" :close-on-content-click="false" :close-on-click="true" offset-y :min-width="mobile ? 350 : 900" :max-width="mobile ? 350 : 900">
                <template v-slot:activator="{ props }">
                  <v-text-field variant="solo" :append-inner-icon="clearable ? 'mdi-cancel':''" @click:append-inner="$emit('clear'); searchString=''" density="compact" v-bind="props" style="display: inline" :hide-details="true" :label="label && label.length > 0 ? label : 'Поиск по каталогу'" v-model="searchString" @click="searchClick()" @input="searchCategories()"></v-text-field>
                </template>
                <div :class="'search-catalog-result-wrapper' + (mobile ? '-mob':'')">
                <div v-for="c in searchedItems" :class="['search-catalog-result-row', c.id.includes('-') ? 'product' : 'category']" :key="c.id" @click="selectItem(c)">
                    <div v-for="(img, index) in c.images" :key="img + '_' + index" class="search-catalog-result-image">
                      <img :src="restUrl + img" width="12" height="12"/>
                    </div>
                  <v-tooltip location="top">
                    <template v-slot:activator="{ props: tooltip }">
                      <span v-bind="tooltip"><span>{{c.id}}</span> / <span>{{c.text}}</span></span>
                    </template>
                    <span>{{ c.description }}</span>
                  </v-tooltip>
                  </div>
                </div>
              </v-menu>
            </v-col>
          </v-row>

</template>

<script>

import axios from "axios";
import router from "@/router/router";

export default {
  components: {
  },
  props: {
    searchType: {type: String, required: true, default: 'mixed'},
    url: {type: String, required: true, default: '/rest/settings/search_catalog'},
    label: {type: String, required: false, default: ''},
    mobile: {type: Boolean, required: false, default: false},
    clearable: {type: Boolean, required: false, default: false},
  },
  data() {
    return {
      restUrl: process.env.VUE_APP_BACKEND_BASE_URL,
      showSearchResult: false,
      searchString: '',
      searchedItems: [],
      showLargeImage: [],
    }
  },
  watch: {
  },
  mounted() {
  },
  computed: {
  },

  methods: {
    searchClick() {
      if(this.searchedItems.length > 0) this.showSearchResult = true;
    },
    searchCategories() {
      let self=this;
      if(this. searchString.length > 1) {
        let json = {'search': this.searchString, 'where': this.searchType};
        axios({
          method: 'post',
          url: this.restUrl + this.url,
          headers: this.$global.headers(this.$store.state.auth.user),
          dataType: 'json',
          data: json
        }).then(function (response) {
          self.searchedItems = response.data;
          self.showSearchResult = self.searchedItems.length > 0;
        }).catch(function (err) {
          console.log(err);
          router.push('/start');
        });
      } else {
        this.searchedItems = [];
      }
    },
    selectItem(cat) {
      this.$emit('select-item', cat);
      this.showSearchResult = false;
    },
  }
};
</script>

<style scoped>

</style>
