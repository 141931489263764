<style>
.modalBackground {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(22, 22, 22, 0.85);
  z-index: 1000;
}

.container-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
  animation-delay: 1s;
}

</style>
<template>
  <div v-show="visible" class="modalBackground">
    <div class="container-loader">

      <v-progress-circular rotate="270" size="150" :value="percentage" width="10" :indeterminate="percentage===100">
        <span style="font-size: 27px;">{{ percentage }}</span>
      </v-progress-circular>

    </div>
  </div>
</template>
<script>
export default {
  props: [
    'visible',
    'percentage',
  ],
  methods: {
    disableLoader() {
      this.$emit('close');
    }
  }
}
</script>
