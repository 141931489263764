<style>
.v-lazy-component.v-lazy-component--loading {
  filter: blur(15px);
}

.v-lazy-component.v-lazy-component--loaded {
  filter: blur(0);
  transition: filter 0.4s;
}
.cells-wrapper-mobile {
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin-bottom: 3px;
  font-size: 10px;
  text-align: center;
}
.cell-mobile {
  border-bottom: 1px solid #dedede;
}
</style>
<template>

  <v-dialog :fullscreen="mobile" :model-value="show" absolute persistent :scrollable="true">

    <v-card style="overflow-x: hidden">

      <v-card-title class="headline">
        <v-row :no-gutters="true">
        <v-col cols="7">
          <span :style="'margin-left: 14px; font-size:' + (mobile ? '10px':'20px')"><b>Настройки складов</b></span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col style="text-align: right">
          <v-tooltip style="display: inline-block" top>
            <template v-slot:activator="{ props: tooltip }">
              <v-btn v-bind="tooltip" icon :size="(mobile ?'x-small':'x-small')" @click="hide"><v-icon>mdi-close</v-icon></v-btn>
            </template>
            <span>Закрыть</span>
          </v-tooltip>
        </v-col>
      </v-row>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-btn :block="true" @click="editItem(template)"><v-icon>mdi-plus</v-icon></v-btn>
        <v-col cols="12" v-if="items.length > 0 && val.pages > 1">
          <v-pagination v-model="val.page" :length="val.pages" :rounded="true" size="x-small"></v-pagination>
        </v-col>

        <v-table  v-if="!mobile" :fixed-header="true" height="600" :density="'compact'">
          <thead><tr>
            <th class="text-left" v-for="h in headers" :key="h.text">
              {{h.text}}
            </th>
          </tr></thead>
          <tbody>
            <tr v-for="item in items" :key="item.id"  @dblclick="editItem(item)">
             <td>{{ item.id }}</td>
             <td>{{ item.name }}</td>
             <td>{{ getProfileNames(item.adminIds) }}</td>
              <td>{{ getProfileNames(item.managerIds) }}</td>
              <td>
                <v-icon v-if="item.enabled" small>mdi-check</v-icon>
                <v-icon v-else small>mdi-close</v-icon>
              </td>
              <td>
                <v-icon v-if="item.stopSales" small>mdi-check</v-icon>
                <v-icon v-else small>mdi-close</v-icon>
              </td>
              <td>{{ item.createdByName }} / {{ $global.displayDate(item.dateCreation, 'DD.MM.YYYY HH:mm')}}</td>
            </tr>
          </tbody>
        </v-table>
        <lazy-component v-else wrapper-tag="table" class="cells-wrapper-mobile" v-for="item in items" :key="item.id">
          <tbody @click="editItem(item)">
          <tr>
            <td class="cell-mobile" style="width: 100%; text-align: left; font-size: 14px" colspan="4">
              <b><i style="color: #0f8107;">{{ item.name }}</i></b>
            </td>
          </tr>

          <tr>
            <td class="cell-mobile" style="width: 15%" colspan="2">
              <b>{{ item.id }}</b>
            </td>
            <td class="cell-mobile" style="border-right: 1px solid #dedede; width: 85%;" colspan="2">
              {{ $global.displayDate(item.dateCreation, 'DD.MM.YYYY HH:mm') }}
            </td>
          </tr>
          <tr>
            <td class="cell-mobile" style="width: 100%; text-align: left;" colspan="4">
              {{ item.createdByName }} / {{ $global.displayDate(item.dateCreation, 'DD.MM.YYYY HH:mm')}}
            </td>
          </tr>
          </tbody>
          <template #placeholder>
            <tr>
              <td colspan="4">Loading...</td>
            </tr>
          </template>
        </lazy-component>

      </v-card-text>

    </v-card>

    <v-dialog :fullscreen="mobile" v-model="editDialog" :width="getModalWidth" :scrollable="true" persistent>
      <v-card v-if="item && editDialog">
        <v-card-title class="headline">
          <v-row danse>
            <v-col cols="7">
              <span :style="'margin-left: 14px; font-size:' + (mobile ? '10px':'20px')"><b>{{ (item.id === 0 ? 'Создание нового элемента' : 'Редактирование элемента #' + item.id) }}</b></span>
            </v-col>
              <v-col style="text-align: right">
                  <v-tooltip location="left" v-if="item.id > 0 && $global.checkRoles(this.user, ['SUDO', 'ADMIN'])">
                      <template v-slot:activator="{ props: tooltip }">
                          <v-btn style="display: inline" v-bind="tooltip" icon :size="(mobile ? 'x-small' : 'x-small')" @click="getLogs(true)"><v-icon>mdi-information-variant</v-icon></v-btn>
                      </template>
                      <span>Лог кассы</span>
                  </v-tooltip>
                  <v-tooltip location="left" v-if="item.id > 0 && $global.checkRoles(this.user, ['SUDO', 'ADMIN'])">
                      <template v-slot:activator="{ props: tooltip }">
                          <v-btn style="display: inline" v-bind="tooltip" icon :size="(mobile ? 'x-small' : 'x-small')" @click="getLogs(false)"><v-icon>mdi-information-variant</v-icon></v-btn>
                      </template>
                      <span>Лог склада</span>
                  </v-tooltip>
                <v-tooltip style="display: inline-block" top>
                  <template v-slot:activator="{ props: tooltip }">
                    <v-btn v-bind="tooltip" icon :size="(mobile ?'x-small':'x-small')" @click="cancelEditItem()"><v-icon>mdi-close</v-icon></v-btn>
                  </template>
                  <span>Закрыть</span>
                </v-tooltip>
              </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text v-if="item">

          <v-text-field variant="solo" :hide-details="true" density="compact" label="ID" disabled v-model="item.id"></v-text-field>
          <v-text-field variant="solo" :hide-details="true" :disabled="item.createdBy !== user.id" density="compact" label="Название*" v-model="item.name"></v-text-field>
<!--          <v-checkbox density="compact" label="Включен?" v-model="item.enabled"></v-checkbox>-->
          <v-checkbox density="compact" label="Стоп продажи" v-model="item.stopSales"></v-checkbox>
          <v-checkbox density="compact" label="Подарки пополам" v-model="item.halfPresent"></v-checkbox>
          <v-select variant="solo" :hide-details="true" v-if="item.createdBy === user.id" :items="filteredMain(item)" v-model="item.mainStoreId" item-value="id" item-title="name" density="compact" label="Cклад поставщик"></v-select>
<!--          TODO on change -> compute managers-->
          <v-select variant="solo" :hide-details="true" v-if="item.createdBy === user.id" :items="filteredProfiles('ADMIN', item)" v-model="item.adminIds" item-value="id" multiple item-title="name" density="compact" label="Админы склада"></v-select>

          <v-select variant="solo" :hide-details="true" :items="filteredProfiles('MANAGER', item)" v-model="item.managerIds" item-value="id" multiple item-title="name" density="compact" label="Менеджеры склада">
            <template v-slot:selection="{ item }">
              <v-chip v-if="!$global.isNumeric(item.title)"> {{ item.title }}</v-chip>
            </template>
          </v-select>

          <v-select variant="solo" :hide-details="true" :items="shippings" v-model="item.shippingTypesIds" item-value="id" multiple item-title="name" density="compact" label="Включить способы доставки"></v-select>

        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-row :dense="true">
            <v-col cols="6" style="text-align: left">
              <v-btn x-small @click="saveItem()">
                <span>Сохранить</span></v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <loader @close="displayLoader = false" v-model="displayLoader"></loader>
    <ahtung @close="alert_switch = false" :value="alert_switch" :message="alert_message" :header="alert_header"></ahtung>
    <confirm-dialog ref="confirm"></confirm-dialog>
      <log-dialog :items="logs" :show="showLogs" @close="closeLogs()" :mobile="mobile"></log-dialog>

  </v-dialog>


</template>

<script>

// import axios from "axios";
import Loader from "../Loader";
import Ahtung from "../Ahtung";
import ConfirmDialog from "../ConfirmDialog";
import axios from "axios";
import LogDialog from "@/components/common/LogDialog.vue";
import {computed} from "vue";

export default {
  components: {
      LogDialog,
    Loader,
    Ahtung,
    ConfirmDialog,
  },
  props: {
    modelValue: {type: Object, required: true, default() {return {}}}, //pagination
    show: {type: Boolean, required: true, default: false},
    mobile: {type: Boolean, required: true, default: false},
    items: {type: Array, required: true, default() {return []}},
    profiles: {type: Array, required: true, default() {return []}},
    shippings: {type: Array, required: true, default() {return []}},
    template: {type: Object, required: true, default() {return {}}},
  },
  setup(props, { emit }) {
    const val = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });
    return { val };
  },
  data() {
    return {
      windowWidth: 0,
      restUrl: process.env.VUE_APP_BACKEND_BASE_URL,

      displayLoader: false,
      headers: [
        {text: 'ID', value: 'id', field: 'id', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Название', value: 'name', field: 'name', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Админы', value: 'name', field: 'name', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Менеджеры', value: 'name', field: 'name', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Включен', value: 'enabled', field: 'enabled', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Стоп продажи', value: 'enabled', field: 'enabled', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Создатель/Дата', value: 'creation', field: 'creation', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
      ],
      item: {},
      editDialog: false,

      alert_switch: false,
      alert_message: "",
      alert_header: "",

        showLogs: false,
        logs: [],

    }
  },
  watch: {
  },
  mounted() {
    this.windowWidth = document.documentElement.clientWidth;
    window.addEventListener('resize', this.onResize, {passive: true});
  },
  computed: {
    getModalWidth() {
      return (this.windowWidth <= 1020 ? '98%' : '1000');
    },
    user() {
      return this.$store.state.auth.user;
    },
  },

  methods: {
      getLogs(cash) {
          let self = this;
          let json = { id : this.item.id, type: (cash ? 'CASHIER' : 'STORE') };
          axios({
              method: 'post',
              url: this.restUrl + "/rest/settings/logs/get",
              headers: this.$global.headers(this.$store.state.auth.user),
              dataType: 'json',
              data: json
          }).then(function (response) {
              if(response.data.status) {
                  self.logs = response.data.logs;
                  self.showLogs = true;
              }
          }).catch(function (e) {
              self.alert_message = "Ошибка загрузки данных. " + e;
              self.alert_header = "Внимание!";
              self.alert_switch = true;
          });
      },
      closeLogs() {
          this.logs=[];
          this.showLogs = false;
      },
    getProfileNames(ids) {
      let result = '';
      for (let profile of this.profiles) if(ids.includes(profile.id)) {
        result += profile.name + ' ';
      }
      return result;
    },
    filteredProfiles(role) {
      let result = [];
      if(!this.$global.checkRoles(this.user, ['SUDO'])) {
        for (let profile of this.profiles) if (profile.role === role)
          if (role === 'MANAGER' || role === 'ADMIN') result.push(profile);
      } else {
        for (let profile of this.profiles) if (profile.role === role || (profile.role === 'SUDO' && role !== 'MANAGER')) result.push(profile);
      }
      return result;
    },

    filteredMain(store) {
      let result = [];
      try {
        result.push({id: 0, name: 'не выбран'});
        for (let item of this.items) if (item.id !== store.id) result.push(item);
      } catch (e) {
        console.log(e);
      }
      return result;
    },
    updateItem(item) {
      console.log('update on store');
      this.$emit('update-item', item);
      this.editItem(item);
    },
    editItem(item) {
      this.item = this.$global.clone(item);
      if(this.item.id === 0) this.item.createdBy = this.user.id;
      this.editDialog = true;
    },
    cancelEditItem() {
      this.item = {};
      this.editDialog = false;
    },
    saveItem() {
      // if(true) {
        this.$emit('save-item', this.item, 'store');
        this.cancelEditItem();
      // } else {
      //   this.alert_message = "Заполните обязательные поля.";
      //   this.alert_header = "Внимание!"
      //   this.alert_switch = true;
      // }
    },

    hide() {
      this.$emit('close');
    },
    onResize() {
      this.windowWidth = document.documentElement.clientWidth;
    },
  }
};
</script>

<style lang="scss" scoped></style>
