<style scoped>
.v-lazy-component.v-lazy-component--loading {
  filter: blur(15px);
}

.v-lazy-component.v-lazy-component--loaded {
  filter: blur(0);
  transition: filter 0.4s;
}
</style>
<template>

  <v-dialog :fullscreen="mobile" :model-value="show" absolute persistent :scrollable="true">

    <v-card style="overflow-x: hidden">

      <v-row :no-gutters="true">
        <v-col cols="7">
          <span :style="'margin-left: 14px; font-size:' + (mobile ? '10px':'20px')"><b>{{ getHeaderForModal() }}</b></span>
        </v-col>
        <v-col style="text-align: right">
          <v-tooltip location="left" v-if="item.id > 0 && $global.checkRoles(this.user, ['SUDO', 'ADMIN', 'MANAGER'])">
            <template v-slot:activator="{ props: tooltip }">
              <v-btn style="display: inline" v-bind="tooltip" icon :size="(mobile ? 'x-small' : 'x-small')" @click="getPrice()"><v-icon>mdi-file-excel</v-icon></v-btn>
            </template>
            <span>XLS</span>
          </v-tooltip>

          <v-tooltip location="left" v-if="item.id > 0 && $global.checkRoles(this.user, ['SUDO', 'ADMIN'])">
            <template v-slot:activator="{ props: tooltip }">
              <v-btn style="display: inline" v-bind="tooltip" icon :size="(mobile ? 'x-small' : 'x-small')" @click="getLogs()"><v-icon>mdi-information-variant</v-icon></v-btn>
            </template>
            <span>Лог</span>
          </v-tooltip>

          <v-tooltip style="display: inline-block" top>
            <template v-slot:activator="{ props: tooltip }">
              <v-btn v-bind="tooltip" icon :size="(mobile ?'x-small':'x-small')" @click="hide"><v-icon>mdi-close</v-icon></v-btn>
            </template>
            <span>Закрыть</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-row :no-gutters="true">
        <v-col cols="12"><v-textarea variant="solo" :return-object="false" rows="2" label="Комментарий" density="compact" :hide-details="true" v-model="item.comment"></v-textarea></v-col>
      </v-row>
      <v-divider></v-divider>


      <v-card-text :style="mobile ? 'font-size: 10px; min-height: 300px;':''">
        <v-progress-linear :active="loader" indeterminate></v-progress-linear>

        <v-table v-if="!mobile" :fixed-header="true" height="600" :density="'compact'" :style="mobile ? 'min-width: 1000px':''">
          <thead><tr>
            <th class="text-left" v-for="h in headers" :key="h.text">
              {{ h.text }}
            </th>
          </tr></thead>
          <tbody>

          <lazy-component wrapper-tag="tr" v-for="(i, index) in item.items" :key="i.artikul">
            <td>{{ i.artikul }}</td>
            <v-tooltip location="top">
              <template v-slot:activator="{ props: tooltip }">
                <td v-bind="tooltip">
                  {{ i.breadcrumbs }}
                  <template v-if="i.error.length > 0"><br><span style="color: red; font-size: 10px">{{ i.error }}</span></template>
                </td>
              </template>
              <span>
                <span v-if="i.images.length > 0">
                  <v-img v-for="(img, index) in i.images.split(';')" :key="index" :src="restUrl + img" width="200"/><br>
                </span>
                {{ i.description }}
              </span>
            </v-tooltip>

            <td><span :style="getColor(i)">{{ i.count }}</span></td>
            <td>
              <v-text-field variant="solo" :id="'count-input-' + index" @keyup.enter="nextPlease('count-input-' + (index + 1))" v-if="item.status === 1" density="compact" :hide-details="true" type="number" onkeypress="return event.charCode >= 46" @input="v => inputN(v, i, 'countEstimated')" :model-value="i.countEstimated" min="0" step="1"></v-text-field>
              <span v-else>{{ i.countEstimated }}</span>
            </td>

            <td>{{ i.price }}</td>
            <td style="text-align: center;">{{ ((i.countEstimated * i.price) - (i.price * i.count)).toFixed(2) }}</td>

            <td>
            </td>
            <template #placeholder>
              <tr>
                <td colspan="6">Loading...</td>
              </tr>
            </template>
          </lazy-component>
          </tbody>
        </v-table>

        <lazy-component v-else wrapper-tag="table" class="cells-wrapper-mobile" v-for="(i) in item.items" :key="i.artikul">
          <tbody>
          <tr>
            <td class="cell-mobile" style="width: 100%; text-align: left;" colspan="3">
              <v-tooltip location="top">
                <template v-slot:activator="{ props: tooltip }">
                  <td v-bind="tooltip">
                    {{ i.breadcrumbs }}
                    <template v-if="i.error.length > 0"><br><span style="color: red; font-size: 10px">{{ i.error }}</span></template>
                  </td>
                </template>
                <span>
                <span v-if="i.images.length > 0">
                  <v-img v-for="(img, index) in i.images.split(';')" :key="index" :src="restUrl + img" width="200"/><br>
                </span>
                {{ i.description }}
              </span>
              </v-tooltip>
            </td>
          </tr>
          <tr>
            <td class="cell-mobile" style="width: 100%; text-align: center;" colspan="3"><i>{{ i.artikul }}</i></td>
          </tr>


          <tr>
            <td class="cell-mobile" style="width: 30%">
              <b><span :style="getColor(i)">{{ i.count }}</span></b>
            </td>
            <td class="cell-mobile" style="border-left: 1px solid #dedede;width: 70%" >
              <v-text-field variant="solo" :id="'count-input-' + index" @keyup.enter="nextPlease('count-input-' + (index + 1))" v-if="item.status === 1" density="compact" :hide-details="true" type="number" onkeypress="return event.charCode >= 46" @input="v => inputN(v, i, 'countEstimated')" :model-value="i.countEstimated" min="0" step="1"></v-text-field>
              <span v-else>{{ i.countEstimated }}</span>
            </td>
          </tr>

          <tr>
            <td class="cell-mobile" style="width: 30%">
              {{ i.price }}
            </td>
            <td colspan="2" class="cell-mobile" style="width: 70%">
              <b>{{ ((i.countEstimated * i.price) - (i.price * i.count)).toFixed(2) }}</b>
            </td>
          </tr>

          </tbody>
          <template #placeholder>
            <tr>
              <td colspan="3">Loading...</td>
            </tr>
          </template>
        </lazy-component>


      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row :dense="true">
          <v-col cols="12">
            <v-row :no-gutters="true">
              <v-col cols="4" v-if="!mobile">
                <div class="d-inline-flex" v-if="!mobile">Итого:</div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="d-inline-flex" :style="mobile ? 'font-size: 10px':''">Колличество единиц:&nbsp;<span style="font-weight: bold;">{{ getTotalCount() }} / {{ getTotalCountEst() }}</span></div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="d-inline-flex" :style="mobile ? 'font-size: 10px':''">Итоговая сумма: <span style="font-weight: bold;">{{ getTotalSumEst() - getTotalSum() }}</span></div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" :style="mobile ? '':'text-align: left'">
            <v-btn :block="mobile" :disabled="item.items.length === 0 || item.status !== 1" x-small @click="saveItem()"><span style="color: green">Сохранить</span></v-btn>
          </v-col>
          <v-col cols="12" md="6" :style="mobile ? '':'text-align: right'">
            <v-btn :block="mobile" x-small @click="clearEditItem()"><span style="color: red;">Закрыть</span></v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <loader @close="displayLoader = false" v-model="displayLoader"></loader>
    <ahtung @close="alert_switch = false" :value="alert_switch" :message="alert_message" :header="alert_header"></ahtung>
    <confirm-dialog ref="confirm"></confirm-dialog>
    <log-dialog :items="logs" :show="showLogs" @close="closeLogs()" :mobile="mobile"></log-dialog>

  </v-dialog>

</template>

<script>


import Loader from "@/components/Loader.vue";
import Ahtung from "@/components/Ahtung.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import {computed, defineComponent} from "vue";
import LogDialog from "@/components/common/LogDialog.vue";
import axios from "axios";

export default defineComponent ({
  name: "WaybillRevision",
  components: {
    LogDialog,
    ConfirmDialog,
    Ahtung,
    Loader
  },
  props: {
    show: {type: Boolean, required: true, default: false},
    mobile: {type: Boolean, required: true, default: false},
    templateWbIt: {type: Object, required: true, default() {return {}}},
    modelValue: {type: Object, required: true, default() {return {}}},
    types: {type: Array, required: true, default() {return []}},
    loader: {type: Boolean, required: true, default: false},
  },
  setup(props, { emit }) {
    const item = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });
    return { item };
  },
  data() {
    return {
      restUrl: process.env.VUE_APP_BACKEND_BASE_URL,
      displayLoader: false,
      alert_switch: false,
      alert_message: "",
      alert_header: "",
      headers: [
        {text: 'ID', value: 'id', field: 'id', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Наименование', value: 'name', field: 'name', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Кол-во рассчетное', value: 'count', field: 'count', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Кол-во фактическое', value: 'countEstimated', field: 'countEstimated', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Стоимость', value: 'price', field: 'price', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Недостача/излишек', value: 'df', field: 'df', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
      ],
      logs: [],
      showLogs: false,
    }
  },
  watch: {
  },
  mounted() {
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },

  methods: {
    getColor(i) {
      if(i.count > i.countEstimated) return "color: #f00;";
      else if(i.count < i.countEstimated) return "color: #0f0;";
      else return "color: #fff;";
    },
    nextPlease(id) {
      document.getElementById(id).focus();
    },
    closeLogs() {
      this.logs=[];
      this.showLogs = false;
    },
    getPrice() {
      let self = this;
      let json = { "item": this.item };
      axios({headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json,
        method: 'post',
        url: this.restUrl + '/rest/stores/price',
        responseType: 'blob',
      }).then((response) => {
        let filename = response.request.getResponseHeader('content-disposition').split("=")[1];
        self.$global.forceFileDownload(response.data, filename);
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
      });
    },
    getLogs() {
      let self = this;
      let json = { wbId: this.item.id };
      axios({
        method: 'post',
        url: this.restUrl + "/rest/stores/get_wb_logs",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        if(response.data.status) {
          self.logs = response.data.logs;
          self.showLogs = true;
        }
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
      });
    },
    inputN(v, i, f) {
      let x = this.$global.inputNumberGE(v.target.value, 0);
      if(x === 0) v.target.value = 0;
      i[f] = x;
    },

    getHeaderForModal() {
      return 'Тип: ' + this.getFieldFromTypeWb(this.item.type, 'nameR') + "; Статус: " + this.getFieldFromStatusWb(this.item.type, this.item.status, 'nameR');
    },
    getFieldFromTypeWb(typeId, field) {
      for(let t of this.types) if(typeId === t.id) return t[field];
      return '';
    },
    getFieldFromStatusWb(typeId, statusId, field) {
      for(let t of this.types) if(typeId === t.id) for(let s of t.statuses) if(statusId === s.id) return s[field];
      return '';
    },
    getTotalSum() {
      let result = 0;
      for(let i of this.item.items) result += i.count * i.price;
      return result;
    },
    getTotalSumEst() {
      let result = 0;
      for(let i of this.item.items) result += i.countEstimated * i.price;
      return result;
    },
    getTotalCount() {
      let result = 0;
      for(let i of this.item.items) result += i.count;
      return result;
    },
    getTotalCountEst() {
      let result = 0;
      for(let i of this.item.items) result += i.countEstimated;
      return result;
    },
    saveItem() {
      this.$emit('save');
    },
    clearEditItem() {
      this.$emit('clear');
      this.$emit('close');
    },
    hide() {
      this.$emit('close');
    },
  }
});
</script>

