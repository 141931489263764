export default {
  "language_selector": {
    "switch_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Switch Language"])}
  },
  "login": {
    "authorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authorization"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login:"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password:"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code:"])},
    "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIGN IN"])},
    "login_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bad credentials"])},
    "server_unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The server is temporarily unavailable."])},
    "call_to_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If the problem lasts more than 5 minutes, contact your administrator."])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])}
  }
}