import { createI18n } from "vue-i18n";
import { languages } from './locales/index'

export default createI18n({
    legacy: false,
    globalInjection: true,
    locale: process.env.VUE_APP_I18N_LOCALE || "ru",
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "ru",
    messages: languages,
});
