<style scoped>
.cells-wrapper-mobile {
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin-bottom: 3px;
  font-size: 10px;
  text-align: center;
}
.cell-mobile {
  border-bottom: 1px solid #dedede;
}

.pagination-wb-element {
  display: inline-block;
  color: #00ff00;
  font-size: 12px;
  background-color: #1e1e1e;
  text-align: center;
  height: 13px;
  line-height: 13px;
  outline: none;
}
.menu-cl-d {
  background-color: black;
}
.menu-cl-l {
  background-color: white;
}
.v-lazy-component.v-lazy-component--loading {
  filter: blur(15px);
}

.v-lazy-component.v-lazy-component--loaded {
  filter: blur(0);
  transition: filter 0.4s;
}
</style>
<template>
  <default-layout-dynamic>

    <loader @close="displayLoader = false" v-model="displayLoader"></loader>
    <ahtung @close="alert_switch = false" :value="alert_switch" :message="alert_message" :header="alert_header"></ahtung>
    <confirm-dialog ref="confirm"></confirm-dialog>
    <v-progress-linear :active="displayLoaderRecalculate" indeterminate></v-progress-linear>

    <v-navigation-drawer :width="$vuetify.display.mdAndUp ? $vuetify.display.width / 2.5 : $vuetify.display.width"
                         :disable-resize-watcher="true" :disable-route-watcher="true" fixed hide-overlay style="padding:5px; overflow-y: auto;"
                         v-model="sidePanel" location="right">

      <v-card elevation=10 style="margin-bottom: 5px; height: 4%; margin-bottom: 5px">
        <v-card-title style="padding: 0; margin-bottom: 5px">
          <v-row :dense="true">
            <v-col align-self="center" cols="11" style="text-align: center;">ДВИЖЕНИЕ ТОВАРОВ</v-col>
            <v-col align-self="center" cols="1" style="display: flex;justify-content: center;align-items: center;">
              <v-btn @click="sidePanel = !sidePanel" size="x-small" icon="mdi-close"></v-btn>
            </v-col>
          </v-row>
        </v-card-title>
      </v-card>
      <v-card>
        <v-card-text style="height: 90%; padding: 5px;">
          <div style="width: 100%; height: 100%; overflow-y: auto; border: 1px solid green;">
            <template v-if="selectedStore">
              <div style="width: 100%; text-align: center;">
                <div style="display: inline-block; width: 10%; text-align: center;">
                  <v-tooltip top>
                    <template v-slot:activator="{ props: tooltip }"><v-btn class="pagination-wb-element" :disabled="wBpage <= 1" @click="wBpage -= 1" v-bind="tooltip" size="x-small">&lt;</v-btn></template>
                    <span>Предыдущая страница</span>
                  </v-tooltip>
                </div>

                <div style="display: inline-block; width: 77%; text-align: center;">
                  <v-tooltip top>
                    <template v-slot:activator="{ props: tooltip }">
                      <span class="pagination-wb-element" style="border: 1px solid green; border-radius: 2px; width: 49%; height: 15px;" v-bind="tooltip">
                        <input class="pagination-wb-element" :value="wBpage" @change="v => wBpage = parseInt(v.target.value)" @keyup.enter="loadWaybillsData()" style="width:70%;" type="number"/>из {{wBpages}}
                      </span>
                    </template>
                    <span>Страница</span>
                  </v-tooltip>

                  <v-tooltip top>
                    <template v-slot:activator="{ props: tooltip }">
                      <span class="pagination-wb-element" style="border: 1px solid green; border-radius: 2px; width: 49%; height: 15px;" v-bind="tooltip">
                        <input class="pagination-wb-element" :value="wBelPerPage" @change="v => wBelPerPage = parseInt(v.target.value)" style="width:100%;" type="number"/>
                      </span>
                    </template>
                    <span>Элементов на странице</span>
                  </v-tooltip>
                </div>

                <div style="display: inline-block; width: 10%; text-align: center;">
                  <v-tooltip location="top">
                    <template v-slot:activator="{ props: tooltip }"><v-btn class="pagination-wb-element" :disabled="wBpage >= wBpages" @click="wBpage += 1" v-bind="tooltip" size="x-small">&gt;</v-btn></template>
                    <span>Следующая страница</span>
                  </v-tooltip>
                </div>

              </div>
            </template>
            <template v-else>
              Склад не выбран.
            </template>
          </div>
        </v-card-text>
      </v-card>

      <v-card elevation=10 style="height: 90%; margin-bottom: 5px">
<!--   WAYBILLS     -->
        <v-card-text style="height: 90%; padding: 5px; overflow-y: auto" >
          <waybill :cashier="cashier" :profile="profile" :stores="stores" :sel-store-id="selectedStore.id" :types="typesWb" :wrapper="w" :mobile="m" v-for="w in storeData.waybills" @update-waybills="loadWaybillsData()" @update-all="loadStoreData()" :key="w.id"  @edit-wb="run_edit_wb"></waybill>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions style="margin-bottom: 5px; height: 10%">
            <v-tooltip v-if="$global.checkRoles(this.user, ['SUDO']) && (selectedStore)" location="top">
              <template v-slot:activator="{ props: tooltip }"><v-btn color="#e2d612" @click="oprihodovanie()" v-bind="tooltip" size="x-small" icon="mdi-format-align-justify mdi-spin"></v-btn></template>
              <span>Оприходование</span>
            </v-tooltip>

          <v-tooltip v-if="$global.checkRoles(this.user, ['SUDO', 'ADMIN'])" location="top">
            <template v-slot:activator="{ props: tooltip }"><v-btn color="#538641" @click="zakaz()" v-bind="tooltip" size="x-small" icon="mdi-format-align-justify mdi-spin"></v-btn></template>
            <span>Заказ</span>
          </v-tooltip>

<!--            <v-tooltip v-if="$global.checkRoles(this.user, ['SUDO']) && (selectedStore)" location="top">-->
<!--              <template v-slot:activator="{ props: tooltip }"><v-btn color="#237886" @click="pereocenka()" v-bind="tooltip" size="x-small" icon="mdi-format-align-justify"></v-btn></template>-->
<!--              <span>Переоценка</span>-->
<!--            </v-tooltip>-->

            <v-tooltip v-if="$global.checkRoles(this.user, ['SUDO', 'ADMIN']) && (selectedStore)" location="top">
              <template v-slot:activator="{ props: tooltip }"><v-btn color="#e1e1e2" @click="revision()" v-bind="tooltip" size="x-small" icon="mdi-format-align-justify mdi-spin"></v-btn></template>
              <span>Ревизия</span>
            </v-tooltip>

<!--            <v-tooltip v-if="$global.checkRoles(this.user, ['SUDO']) && (selectedStore && selectedStore.mainStoreId > 0)" location="top">-->
<!--              <template v-slot:activator="{ props: tooltip }"><v-btn color="#EB3BB6" @click="vozvrat()" v-bind="tooltip" size="x-small" icon="mdi-format-align-justify"></v-btn></template>-->
<!--              <span>Возврат</span>-->
<!--            </v-tooltip>-->
        </v-card-actions>
      </v-card>

    </v-navigation-drawer>

    <v-row :dense="true">
      <v-col cols="6">
        <div class="d-inline-flex" v-if="selectedStore && selectedStore.mainStoreId > 0">
          <v-tooltip location="top">
            <template v-slot:activator="{ props: tooltip }"><v-checkbox-btn color="#f00" density="compact" v-bind="tooltip" v-model="zakaz_mode"></v-checkbox-btn></template>
            <span>Переключить в режим {{zakaz_mode ? "просмотра" : "заказа"}}</span>
          </v-tooltip>
        </div>
        <v-select style="display: inline-flex" variant="solo" label="Выбор склада" :disabled="stores.length <= 1" :items="stores" :hide-details="true" density="compact" :return-object="true" :item-title="'name'" v-model="selectedStore"></v-select>
      </v-col>
      <v-col cols="6">
        <v-tooltip location="bottom">
          <template v-slot:activator="{ props: tooltip }"><v-btn @click="snackbarCashier = !snackbarCashier" v-bind="tooltip" icon="mdi-currency-btc" size="x-small"></v-btn></template>
          <span>Балланс склада</span>
        </v-tooltip>

       <v-menu v-if="$global.checkRoles(user, ['SUDO', 'ADMIN'])" location="bottom" :open-on-click="false" :close-on-content-click="false" width="150" v-model="workMenu">
          <template v-slot:activator="{ props }">
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props: tooltip }"><span v-bind="props"><v-btn @click="openWorkMoney()" v-bind="tooltip" icon="mdi-thumb-down-outline" size="x-small"></v-btn></span></template>
              <span>Рабочие расходы</span>
            </v-tooltip>
          </template>
          <v-row :class="'menu-cl-' + (isDark ? 'd':'l')" dense align-content="center" align="center" justify="center">
            <v-col cols="12" align-self="center">
              <v-text-field variant="solo" :ref="'workVal'"  @keyup.enter="workMoney()" @keyup.esc="workMenu = false"  density="compact" :hide-details="true" type="number" v-model.number="workValue" label="BYN"></v-text-field>
              <v-text-field variant="solo" density="compact" :hide-details="true" v-model="workComment" label="Комментарий"></v-text-field>
              <v-btn size="x-small" icon="mdi-checkbox-marked-circle-outline" :disabled="workValue <= 0" @click="workMoney()"></v-btn>
              <v-btn size="x-small" icon="mdi-cancel" @click="workMenu = false"></v-btn>
            </v-col>
          </v-row>
        </v-menu>

      <v-menu v-if="$global.checkRoles(user, ['SUDO', 'ADMIN'])" location="bottom" :open-on-click="false" :close-on-content-click="false" width="150" v-model="grabMenu">
          <template v-slot:activator="{ props }">
            <v-tooltip location="bottom">
              <template v-slot:activator="{ props: tooltip }"><span v-bind="props"><v-btn @click="openGrabMoney()" v-bind="tooltip" icon="mdi-thumb-up-outline" size="x-small"></v-btn></span></template>
              <span>Забрать свои деньги</span>
            </v-tooltip>
          </template>
          <v-row :class="'menu-cl-' + (isDark ? 'd':'l')" dense align-content="center" align="center" justify="center">
            <v-col cols="12" align-self="center">
              <v-text-field variant="solo" :ref="'grabVal'"  @keyup.enter="grabMoney()" @keyup.esc="grabMenu = false"  density="compact" :hide-details="true" type="number" v-model.number="grabValue" label="BYN"></v-text-field>
              <v-text-field variant="solo" density="compact" :hide-details="true" v-model="grabComment" label="Комментарий"></v-text-field>
              <v-btn size="x-small" icon="mdi-checkbox-marked-circle-outline" :disabled="grabValue <= 0" @click="grabMoney()"></v-btn>
              <v-btn size="x-small" icon="mdi-cancel" @click="grabMenu = false"></v-btn>
            </v-col>
          </v-row>
        </v-menu>

        <v-tooltip location="bottom">
          <template v-slot:activator="{ props: tooltip }"><v-btn @click="sidePanel = !sidePanel" v-bind="tooltip" icon="mdi-clipboard-flow" size="x-small"></v-btn></template>
          <span>Движение товаров</span>
        </v-tooltip>
        <v-tooltip  location="bottom">
          <template v-slot:activator="{ props: tooltip }"><v-btn v-bind="tooltip" @click="showItemsFilters = !showItemsFilters" size="x-small" icon="mdi-arrow-down"></v-btn></template>
          <span>Скрыть/показать фильтры</span>
        </v-tooltip>

      </v-col>



      <template v-if="showItemsFilters">
        <v-col align-self="center" cols="12">
<!--          <v-text-field :density="'compact'" :hide-details="true" v-model="filterItems" label="Фильтр по тексту"></v-text-field>-->
          <v-text-field variant="solo" :density="'compact'" :hide-details="true" v-model="iTsearch" @change="loadItemsData()" label="Текстовый поиск"></v-text-field>
        </v-col>
        <v-col cols="4" v-if="selectedStore">
          <v-checkbox color="#ff0000" density="compact" :hide-details="true" v-model="iTshowZeroProducts" label="нулевые"></v-checkbox>
        </v-col>
        <v-col cols="4" md="1">
          <v-text-field variant="solo" :density="'compact'" :hide-details="true" label="№ стр:" type="number" v-model.number="iTpage" min=1 step=1></v-text-field>
        </v-col>
        <v-col cols="4" md="1">
          <v-text-field variant="solo" :density="'compact'" :hide-details="true" type="number" v-model.number="iTelPerPage" label="шт/стр"></v-text-field>
        </v-col>
      </template>

      <v-col cols="12" v-if="selectedStore && storeData.items.length > 0 && iTpages > 1">
        <v-pagination v-model="iTpage" :length="iTpages" :rounded="true" size="x-small"></v-pagination>
      </v-col>

      <v-col cols="12" v-if="selectedStore">

        <v-table v-if="!m" :fixed-header="true" height="600" :density="'compact'" :style="'font-size:' + (m ? '10px;' : '14px;' )">
          <tbody>
          <lazy-component wrapper-tag="tr" v-for="item in storeData.items" :key="item.id">
            <td style="width: 6%"><v-checkbox density="compact" :hide-details="true" :model-value="checkSelectedStoreItemsId(item.id)" @change="checkSelectedStoreItems(item)"></v-checkbox></td>
            <td style="width: 15%">{{ item.artikul }}</td>
            <td style="width: 59%">
              <v-tooltip location="top">
                <template v-slot:activator="{ props: tooltip }">
                  <span :style="'color: #5b7f23;'" v-bind="tooltip">{{ item.breadcrumbs }}</span>
                </template>
                <span>
                  <span v-if="item.images.length > 0">
                    <v-img v-for="(img, index) in item.images.split(';')" :key="index" :src="restUrl + img" width="130"/><br>
                  </span>
                  {{ item.description }}
                </span>
              </v-tooltip>
            </td>
            <td style="width: 10%">{{ item.count }}<span v-if="item.brak > 0 && !zakaz_mode">&nbsp;/&nbsp;<span style="color: red">{{item.brak}}</span></span></td>
            <td style="width: 10%">{{ item.price }}</td>
            <template #placeholder>
              <tr>
                <td colspan="5">Loading...</td>
              </tr>
            </template>
          </lazy-component>
          </tbody>
        </v-table>

        <lazy-component v-else wrapper-tag="table" class="cells-wrapper-mobile" v-for="item in storeData.items" :key="item.id">
          <tbody>
          <tr>
            <td class="cell-mobile" style="width: 100%; text-align: left;" colspan="4">
              <v-tooltip location="top">
                <template v-slot:activator="{ props: tooltip }">
                  <span :style="'color: #5b7f23;'" v-bind="tooltip">{{ item.breadcrumbs }}</span>
                </template>
                <span>
                  <span v-if="item.images.length > 0">
                    <v-img v-for="(img, index) in item.images.split(';')" :key="index" :src="restUrl + img" width="130"/><br>
                  </span>
                  {{ item.description }}
                </span>
              </v-tooltip>
            </td>
          </tr>

          <tr>
            <td class="cell-mobile" style="border-right: 1px solid #dedede; width: 15%; font-size: 14px;" @dblclick="$emit('edit-item')">
              <v-checkbox density="compact" :hide-details="true" :model-value="checkSelectedStoreItemsId(item.id)" @change="checkSelectedStoreItems(item)"></v-checkbox>
            </td>
            <td class="cell-mobile" style="width: 45%">
              <b>{{ item.artikul }}</b>
            </td>
            <td class="cell-mobile" style="width: 20%">
              {{ item.count }}<span v-if="item.brak > 0 && !zakaz_mode">&nbsp;/&nbsp;<span style="color: red">{{item.brak}}</span></span>
            </td>
            <td class="cell-mobile" style="border-left: 1px solid #dedede;width: 20%">
              {{ item.price }}
            </td>
          </tr>
          </tbody>
          <template #placeholder>
            <tr>
              <td colspan="4">Loading...</td>
            </tr>
          </template>
        </lazy-component>




      </v-col>
    </v-row>

    <v-snackbar :max-width="200" timeout="-1" location="left bottom" color="#9c9c9c" v-model="snackbarCashier" vertical>
      <p>Баланс: <b>{{ cashier.balance }}</b> BYN</p>
      <p>Дебит: <b>{{ cashier.debt }}</b> BYN</p>
      <p>Кредит: <b>{{ cashier.kredit }}</b> BYN</p>
      <p>Касса: <b>{{ cashier.cashier }}</b> BYN</p>
      <p>Свои: <b>{{ cashier.own }}</b> BYN</p>
      <p>Свои расчетные: <b>{{ cashier.ownTeor }}</b> BYN</p>
      <template v-slot:actions>
        <v-btn color="indigo" @click="getLogs">ЛОГ</v-btn>
        <v-btn color="indigo" v-if="$global.checkRoles(this.user, ['SUDO'])" @click="editCash">ПРАВКА</v-btn>
        <v-btn color="indigo" @click="snackbarCashier = false">ЗАКРЫТЬ</v-btn>
      </template>
    </v-snackbar>

    <waybill-oprihod :types="typesWb" v-model="editWb" :template-wb-it="templateWbIt" @save="saveWb('O', true)"
                    @close="editWbDialogO = false" :show="editWbDialogO" :mobile="m"></waybill-oprihod>

<!--   TODO dispatcher store for disablin zakaz on stop-sales-->
    <waybill-zakaz :store="selectedStore" :zakaz_mode="zakaz_mode" :dispatchers="stores" :consumers="stores" @clear="clearWaybill()" :loader="displayLoaderRecalculate" :types="typesWb" v-model="editWb" :template-wb-it="templateWbIt" @save="saveWb"
                   @recalculate="recalculateZakaz()" @close="editWbDialogZ = false" :show="editWbDialogZ" :mobile="m"></waybill-zakaz>

<!--    <waybill-pereocenka :types="typesWb" v-model="editWb" :template-wb-it="templateWbIt" @save="saveWb('P')"-->
<!--                     @close="editWbDialogP = false" :show="editWbDialogP" :mobile="m"></waybill-pereocenka>-->

    <waybill-revision @clear="clearWaybill()" :loader="displayLoaderRecalculate" :types="typesWb" v-model="editWb" :template-wb-it="templateWbIt" @save="saveWb('R', true)"
                     @close="editWbDialogR = false" :show="editWbDialogR" :mobile="m"></waybill-revision>

<!--    <waybill-vozvrat :types="typesWb" v-model="editWb" :template-wb-it="templateWbIt" @save="saveWb('V')"-->
<!--                      @close="editWbDialogV = false" :show="editWbDialogV" :mobile="m"></waybill-vozvrat>-->

    <log-dialog :items="logs" :show="showLogs" @close="closeLogs()" :mobile="m"></log-dialog>
    <edit-cashier :store="selectedStore" v-if="$global.checkRoles(this.user, ['SUDO'])" v-model="cashier" :show="editCashDialog" @close="closeEditCash" @save="saveCashier()" :mobile="m"></edit-cashier>



  </default-layout-dynamic>
</template>

<script>

import DefaultLayoutDynamic from "../components/layouts/DefaultLayoutDynamic";
import Loader from "../components/Loader";
import axios from "axios";
import Ahtung from "../components/Ahtung";
import router from "@/router/router";
import Waybill from "@/components/stores/Waybill.vue";
import WaybillOprihod from "@/components/stores/WaybillOprihod.vue";
import WaybillZakaz from "@/components/stores/WaybillZakaz.vue";
// import WaybillPereocenka from "@/components/stores/WaybillPereocenka.vue";
import WaybillRevision from "@/components/stores/WaybillRevision.vue";
// import WaybillVozvrat from "@/components/stores/WaybillVozvrat.vue";
import EditCashier from "@/components/stores/EditCashier.vue";
import LogDialog from "@/components/common/LogDialog.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import {useTheme} from "vuetify";

export default {
  name: "Stores",
  components: {
    ConfirmDialog,
    LogDialog,
    WaybillOprihod,
    // WaybillPereocenka,
    WaybillRevision,
    WaybillZakaz,
    // WaybillVozvrat,
    Waybill,
    DefaultLayoutDynamic,
    Loader,
    Ahtung,
    EditCashier,
  },
  data() {
    return {
      restUrl: process.env.VUE_APP_BACKEND_BASE_URL,
      displayLoader: false,
      displayLoaderRecalculate: false,
      sidePanel: false,

      stores: [],
      selectedStore: undefined,
      selectedStoreItems: [],
      storeData: { items: [], waybills: [], wbLoaders: [] },

      profile: {},


      zakaz_mode: false,
      showItemsFilters: true,
      filterItems: '',

      iTsortAsc: false,
      iTsortField: 'id',
      iTsearch: '',
      iTpages: 0,
      iTpage: 1,
      iTelPerPage: 50,
      iTshowZeroProducts: false,
      iTshowCountLessThen: 0,

      wBsortAsc: false,
      wBsortField: 'id',
      wBsearch: '',
      wBpages: 0,
      wBpage: 1,
      wBelPerPage: 25,
      wBselectedTypes: [],
      wBselectedStatuses: [],

      firstLoad: true,

      editWbDialogO: false,
      editWbDialogP: false,
      editWbDialogR: false,
      editWbDialogV: false,
      editWbDialogZ: false,
      templateWb: undefined,
      templateWbIt: undefined,
      typesWb: [],
      editWb: undefined,
      savingWb: true,
      willCloseEdit: false,

      alert_switch: false,
      alert_message: "",
      alert_header: "",
      cashier: {},
      snackbarCashier: true,
      editCashDialog: false,

      grabMenu: false,
      grabComment: '',
      grabValue: 0,

      workMenu: false,
      workComment: '',
      workValue: 0,

      logs: [],
      showLogs: false,

      m: true,


    }
  },
  mounted() {
    this.m = !this.$vuetify.display.mdAndUp;
  },
  created() {
    if (!this.loggedIn) router.push('/start')
    else {
      this.getData();
    }
  },
  methods: {
    openWorkMoney() {
      document.activeElement.blur();
      this.workMenu = !this.workMenu;
      let self = this;
      this.$nextTick(() => {
        setTimeout(() => {
          self.$refs['workVal'].focus()
        }, 100)
      });
    },
    workMoney() {
      let self = this;
      this.$refs.confirm.open('Расход кассы.', 'Вы уверены?', {color: '#9C0A20'}).then((result) => {
        if(result) {
          let json = {storeId: self.selectedStore.id, value: this.workValue, comment: this.workComment};
          axios({
            method: 'post',
            url: self.restUrl + "/rest/stores/work_money",
            headers: self.$global.headers(self.$store.state.auth.user),
            dataType: 'json',
            data: json
          }).then(function (r) {
            if (r.status) {
              self.workValue = 0;
              self.workComment = '';
              self.alert_message = "Успешно.";
              self.alert_header = "Изъятие своих средств";
              self.alert_switch = true;
            }
          }).catch(function (e) {
            self.alert_message = "Ошибка загрузки данных. " + e;
            self.alert_header = "Внимание!";
            self.alert_switch = true;
          });
        }
      });
    },
    openGrabMoney() {
      document.activeElement.blur();
      if(this.cashier.own <= this.cashier.cashier) this.grabValue = this.cashier.own;
      else this.grabValue = this.cashier.cashier;
      this.grabMenu = !this.grabMenu;
      let self = this;
      this.$nextTick(() => {
        setTimeout(() => {
          self.$refs['grabVal'].focus()
        }, 100)
      });
    },
    grabMoney() {
      let self = this;
      this.$refs.confirm.open('Изъятие своих средств!', 'Вы уверены?', {color: '#9C0A20'}).then((result) => {
        if(result) {
          let json = {storeId: self.selectedStore.id, value: this.grabValue, comment: this.grabComment};
          axios({
            method: 'post',
            url: self.restUrl + "/rest/stores/grab_money",
            headers: self.$global.headers(self.$store.state.auth.user),
            dataType: 'json',
            data: json
          }).then(function (r) {
            if (r.status) {
              self.grabValue = 0;
              self.grabComment = '';
              self.alert_message = "Успешно.";
              self.alert_header = "Изъятие своих средств";
              self.alert_switch = true;
            }
          }).catch(function (e) {
            self.alert_message = "Ошибка загрузки данных. " + e;
            self.alert_header = "Внимание!";
            self.alert_switch = true;
          });
        }
      });
    },
    run_edit_wb(wb, func){
      this[func](wb);
    },
    clearWaybill() {
      this.editWb = this.$global.clone(this.templateWb);
    },
    closeLogs() {
      this.logs=[];
      this.showLogs = false;
    },
    editCash() {
      // if(this.selectedStore.stopSales) {
        this.editCashDialog = true;
      // }
    },
    closeEditCash() {
      this.editCashDialog = false;
    },
    saveCashier() {
      let self = this;
      let json = { item: this.cashier };
      axios({
        method: 'post',
        url: this.restUrl + "/rest/stores/save_cashier",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (r) {
        if(r.status) {
          self.closeEditCash();
        }
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
      });
    },
    getLogs() {
      let self = this;
      let json = { id : this.cashier.id, type: 'CASHIER' };
      axios({
        method: 'post',
        url: this.restUrl + "/rest/settings/logs/get",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        if(response.data.status) {
          self.logs = response.data.logs;
          self.showLogs = true;
        }
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
      });
    },
    loadCashier(store) {
      let self = this;
      let json = { storeId: store.id  };
      axios({
        method: 'post',
        url: this.restUrl + "/rest/stores/load_cashier",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        self.cashier = response.data.cashier;
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
      });
    },
    recalculateZakaz() {
      this.displayLoaderRecalculate = true;
      let self = this;
      let json = { item: this.editWb, zakaz: this.zakaz_mode};
      axios({
        method: 'post',
        url: this.restUrl + "/rest/stores/recalculate_zakaz",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        if(response.data.status) {
          self.editWb = response.data.item;
        }
        self.displayLoaderRecalculate = false;
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.displayLoaderRecalculate = false;
      });
    },
    createZakazFromOprihod(item) {
      this.displayLoader = true;
      let self = this;
      let json = { item: item };
      axios({
        method: 'post',
        url: this.restUrl + "/rest/stores/generate_zakaz",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        if(response.data.status) {
          console.log(response.data.item)
          self.zakaz(response.data.item);
        }
        self.displayLoader = false;
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.displayLoader = false;
      });
    },
    zakaz(wb) {
      if(!wb) {
        this.editWb = this.$global.clone(this.templateWb);
        if(this.zakaz_mode) {
          this.editWb.storeId = this.selectedStore.id;
          this.editWb.dispatchStoreId = this.selectedStore.mainStoreId;
        } else {
          this.editWb.dispatchStoreId = this.selectedStore.id;
          this.editWb.storeId = 0;
          for(let st of this.stores) if(st.mainStoreId === this.editWb.dispatchStoreId) {
            this.editWb.storeId = st.id;
            break;
          }
          if (this.editWb.storeId === 0) {
            this.alert_message = "Нет доступных складов для отгрузки.";
            this.alert_header = "Внимание!";
            this.alert_switch = true;
            return;
          }
        }
        this.editWb.type = 3;
        this.editWb.status = 1;
        if (this.selectedStoreItems.length > 0) {
          for (let itemS of this.selectedStoreItems) {
            let itemW = this.$global.clone(this.templateWbIt);
            itemW.artikul = itemS.artikul;
            itemW.images = itemS.images;
            itemW.searchString = itemS.searchString;
            itemW.breadcrumbs = itemS.breadcrumbs;
            itemW.description = itemS.description;
            itemW.price = itemS.price;
            itemW.priceCalculated = itemS.price;
            itemW.formerId = itemS.formerId;
            itemW.dif = itemS.dif;
            itemW.count = 1;
            this.editWb.items.unshift(itemW);
          }
        }
      } else {
        this.editWb = this.$global.clone(wb);
        if (this.editWb.status === 1 && !this.checkClient && !(this.editWb.id === 0 && this.editWb.specialOprihod)) this.editWb.status = 2;
      }
      this.recalculateZakaz();
      this.editWbDialogZ = true;
    },
    // vozvrat() {
    //
    // },
    revision(wb) {
      if(!wb) {
        this.editWb = this.$global.clone(this.templateWb);
        this.editWb.storeId = this.selectedStore.id;
        this.editWb.dispatchStoreId = this.selectedStore.mainStoreId;
        this.editWb.type = 4;
        this.editWb.status = 1;
        this.displayLoader = true;
        let self = this;
        let json = { storeId: this.selectedStore.id };
        axios({
          method: 'post',
          url: this.restUrl + "/rest/stores/get_all_for_revision",
          headers: this.$global.headers(this.$store.state.auth.user),
          dataType: 'json',
          data: json
        }).then(function (response) {
          if(response.data.status) {
            if(response.data.error.length === 0) {
              for(let itemS of response.data.items) {
                let itemW = self.$global.clone(self.templateWbIt);
                itemW.artikul = itemS.artikul;
                itemW.images = itemS.images;
                itemW.searchString = itemS.searchString;
                itemW.breadcrumbs = itemS.breadcrumbs;
                itemW.description = itemS.description;
                itemW.price = itemS.price;
                itemW.priceCalculated = itemS.price;
                itemW.formerId = itemS.formerId;
                itemW.dif = itemS.dif;
                itemW.count = itemS.count;
                itemW.countEstimated = itemS.count;
                self.editWb.items.unshift(itemW);
              }
              self.editWbDialogR = true;
            } else {
              self.editOr = response.data.item;
              self.alert_message = response.data.error;
              self.alert_header = "Внимание!";
              self.alert_switch = true;
            }
          }
          self.displayLoader = false;
        }).catch(function (e) {
          self.alert_message = "Ошибка загрузки данных. " + e;
          self.alert_header = "Внимание!";
          self.alert_switch = true;
          self.displayLoader = false;
        });
      } else {
        this.editWb = this.$global.clone(wb);
        this.editWbDialogR = true;
      }
    },
    // pereocenka() {
    //
    // },
    oprihodovanie(wb) {
      if(!wb) {
        this.editWb = this.$global.clone(this.templateWb);
        this.editWb.specialOprihod = this.selectedStore.mainStoreId !== 0;
        this.editWb.storeId = this.selectedStore.id;
        this.editWb.type = 1;
        this.editWb.status = 1;
        if (this.selectedStoreItems.length > 0) {
          for (let itemS of this.selectedStoreItems) {
            let itemW = this.$global.clone(this.templateWbIt);
            itemW.artikul = itemS.artikul;
            itemW.images = itemS.images;
            itemW.searchString = itemS.searchString;
            itemW.breadcrumbs = itemS.breadcrumbs;
            itemW.description = itemS.description;
            itemW.price = itemS.price;
            itemW.count = 1;
            this.editWb.items.unshift(itemW);
          }
        }
      } else {
        this.editWb = this.$global.clone(wb);
      }
      this.editWbDialogO = true;
    },
    checkSelectedStoreItemsId(id) {
      for(let i=0; i < this.selectedStoreItems.length; i++) {
        if(this.selectedStoreItems[i].id === id) {
          return true;
        }
      }
      return false;
    },
    checkSelectedStoreItems(item) {
      let index = -1;
      for(let i=0; i<this.selectedStoreItems.length; i++) {
        if(this.selectedStoreItems[i].id === item.id) {
          index = i;
          break;
        }
      }
      if(index >= 0) this.selectedStoreItems.splice(index, 1);
      else this.selectedStoreItems.push(item);
    },
    saveWb(l, close) {
      this.displayLoader = true;
      this.savingWb = true;
      this.willCloseEdit = close;
      let self = this;
      let startId = this.editWb.id;
      let json = { item: this.editWb };
      axios({
        method: 'post',
        url: this.restUrl + "/rest/stores/save_wb",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        if(response.data.status) {
          if(response.data.error.length === 0) {
            if ((!response.data.item.hasErrors && close) || (!response.data.item.hasErrors && startId === 0 && self.checkClient )) {
              self.$nextTick(() => {
                self.clearWaybill();
                self.savingWb = false;
                self['editWbDialog' + l] = false;
              });
            } else {
              self.editWb = response.data.item;
              self.recalculateZakaz();
            }
            if(response.data.item.specialOprihod && response.data.item.type === 1) {
              self.createZakazFromOprihod(response.data.item);
            }
          } else {
            self.editOr = response.data.item;
            self.alert_message = response.data.error;
            self.alert_header = "Внимание!";
            self.alert_switch = true;
          }
        }
        self.displayLoader = false;
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.displayLoader = false;
      });
    },
    loadItemsData() {
      this.displayLoader = true;
      let self = this;
      let json = {zakaz: this.zakaz_mode, storeId: this.selectedStore.id, iTsearch: this.iTsearch, iTsortField: this.iTsortField, iTsortAsc: this.iTsortAsc, iTshowZeroProducts: this.iTshowZeroProducts, iTshowCountLessThen: this.iTshowCountLessThen, iTpage: this.iTpage - 1, iTelPerPag: this.iTelPerPage};
      axios({
        method: 'post',
        url: this.restUrl + "/rest/stores/items/data",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        self.storeData.items = response.data.items.content;
        self.iTpages = response.data.items.totalPages;
        if (self.iTpage > self.iTpages && self.iTpages != 0) self.iTpage = self.iTpages;
        self.displayLoader = false;

      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.displayLoader = false;
      });
    },
    loadWaybillsData() {
      this.displayLoader = true;
      let self = this;
      let json = {storeId: this.selectedStore.id, wBsearch: this.wBsearch, wBsortField: this.wBsortField, wBsortAsc: this.wBsortAsc, wBselectedTypes: this.wBselectedTypes, wBselectedStatuses: this.wBselectedStatuses, wBpage: this.wBpage - 1, wBelPerPag: this.wBelPerPage};
      axios({
        method: 'post',
        url: this.restUrl + "/rest/stores/waybill/data",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        self.storeData.waybills = response.data.waybills.content;
        self.wBpages = response.data.waybills.totalPages;
        if (self.wBpage > self.wBpages && self.wBpages != 0) self.wBpage = self.wbpages;
        self.displayLoader = false;
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.displayLoader = false;
      });
    },
    loadStoreData() {
      this.displayLoader = true;
      let self = this;
      let json = {zakaz: this.zakaz_mode, storeId: this.selectedStore.id, iTsearch: this.iTsearch, iTsortField: this.iTsortField, iTsortAsc: this.iTsortAsc, iTshowZeroProducts: this.iTshowZeroProducts, iTshowCountLessThen: this.iTshowCountLessThen, iTpage: this.iTpage - 1, iTelPerPag: this.iTelPerPage,
                  wBsearch: this.wBsearch, wBsortField: this.wBsortField, wBsortAsc: this.wBsortAsc, wBselectedTypes: this.wBselectedTypes, wBselectedStatuses: this.wBselectedStatuses, wBpage: this.wBpage - 1, wBelPerPag: this.wBelPerPage };
      axios({
        method: 'post',
        url: this.restUrl + "/rest/stores/store/data",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        self.storeData.items = response.data.items.content;
        self.storeData.waybills = response.data.waybills.content;
        self.iTpages = response.data.items.totalPages;
        if (self.iTpage > self.iTpages && self.iTpages != 0) self.iTpage = self.iTpages;
        self.wBpages = response.data.waybills.totalPages;
        if (self.wBpage > self.wBpages && self.wBpages != 0) self.wBpage = self.wbpages;
        self.firstLoad = false;
        self.displayLoader = false;
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.displayLoader = false;
      });
    },
    getData() {
      this.displayLoader = true;
      let self = this;
      let json = {};
      axios({
        method: 'post',
        url: this.restUrl + "/rest/stores/data",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        self.stores = response.data.stores;
        if(self.stores.length > 0)self.selectedStore = self.stores[0];
        self.typesWb = response.data.typesWb;
        self.templateWb = response.data.templateWb;
        self.templateWbIt = response.data.templateWbIt;
        self.profile = response.data.user;
        self.displayLoader = false;
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
        self.displayLoader = false;
      });
    },
    reloadEditItem(id) {
      let self = this;
      let json = { id: id, storeId: this.selectedStore.id };
      axios({
        method: 'post',
        url: this.restUrl + "/rest/stores/reload_edit_wb",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        // self.profile = response.data.user; //current
        // self.profiles = response.data.users; //available to store admins
        // self.clients = response.data.clients; //available to store clients
        // self.clients.unshift({id:0, name:'не выбран'});
        self.$nextTick(() => {
          self.editWb = response.data.item;
          if (self.editWb.status === 1 && !self.checkClient && !(self.editWb.id === 0 && self.editWb.specialOprihod)) self.editWb.status = 2;
          self.recalculateZakaz();
        });
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
      });
    },
  },
  watch: {
    '$store.state.cashier_to_update'(val) {
      if(val.cash.storeId === this.selectedStore.id) {
        this.cashier = val.cash;
      }
    },
    '$store.state.storesUpdater'(val) {
      if(val.storeId === this.selectedStore.id) {
        this.loadStoreData();
        if((this.editWbDialogO || this.editWbDialogR || this.editWbDialogZ) && this.editWb && this.editWb.id === val.wbId) {
          if(((!this.savingWb && !this.willCloseEdit) || val.wbId === 0) && !(this.checkClient && this.editWb.status === 1)) this.recalculateZakaz();
          else {
            this.reloadEditItem(val.wbId);
          }
        }
      }
    },
    '$vuetify.display.mdAndUp'(val) {
      this.m = !val;
    },
    selectedStore(val) {
      if(val) {
        if(val.mainStoreId === 0) this.zakaz_mode = false;
        this.firstLoad = true;
        this.iTpages = 0;
        this.iTpage = 1;
        this.wBpages = 0;
        this.wBpage = 1;
        this.loadStoreData(val);
        this.loadCashier(val);
        this.selectedStoreItems = [];
      } else {
        this.storeData = {items: [], waybills: [], wbLoaders: []};
      }
    },
    wBsortAsc() {
      if(!this.firstLoad) this.loadWaybillsData();
    },
    wBsortField() {
      if(!this.firstLoad) this.loadWaybillsData();
    },
    wBelPerPage() {
      if(!this.firstLoad) this.loadWaybillsData();
    },
    wBshowZeroProducts() {
      if(!this.firstLoad) this.loadWaybillsData();
    },
    wBshowCountLessThen() {
      if(!this.firstLoad) this.loadWaybillsData();
    },
    wBpage() {
      if(!this.firstLoad) this.loadWaybillsData();
    },
    iTsortField() {
      if(!this.firstLoad) this.loadItemsData();
    },
    iTsortAsc() {
      if(!this.firstLoad) this.loadItemsData();
    },
    iTelPerPage() {
      if(!this.firstLoad) this.loadItemsData();
    },
    iTshowZeroProducts() {
      if(!this.firstLoad) this.loadItemsData();
    },
    iTshowCountLessThen() {
      if(!this.firstLoad) this.loadItemsData();
    },
    iTpage() {
      if(!this.firstLoad) this.loadItemsData();
    },
    zakaz_mode() {
      this.selectedStoreItems = [];
      this.loadItemsData();
    },
  },
  computed: {
    isDark() {
      return useTheme().global.name.value === 'dark';
    },
    checkClient() {
      if(this.editWb) for(let st of this.stores) if(st.id === this.editWb.dispatchStoreId && st.adminIds.includes(this.user.id)) return false;
      return true;
    },
    computedItems() {
      if(this.filterItems.length > 2) {
        let result = [];
        let words = this.filterItems.split(" ");
        for(let item of this.storeData.items) if(words.every(word => item.searchString.toLowerCase().includes(word.toLowerCase()))) result.push(item);
        return result;
      } else return this.storeData.items;
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    user() {
      return this.$store.state.auth.user;
    },
  },
};
</script>
