export default {
  "language_selector": {
    "switch_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar idioma"])}
  },
  "login": {
    "authorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorización"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso:"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña:"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Código:"])},
    "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INICIAR SESIÓN"])},
    "login_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credenciales incorrectas"])},
    "server_unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El servidor no está disponible temporalmente."])},
    "call_to_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si el problema dura más de 5 minutos, comuníquese con su administrador."])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualizar"])}
  }
}