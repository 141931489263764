<style>
.bounce-enter-active {
    animation: bounce-in 0.5s;
}

.bounce-leave-active {
    animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.25);
    }
    100% {
        transform: scale(1);
    }
}
</style>
<template>
    <default-layout-dynamic>
        <v-row :dense="true">
            <v-col cols="12" style="text-align: center">
                <h1>ГЛАВНАЯ СТРАНИЦА</h1>
            </v-col>
        </v-row>
        <v-row :dense="true">
            <v-col cols="12" :sm="n.show ? 12 : 6" :md="n.show ?  12 : 4" v-for="n in news" :key="n.id">
                <v-card :style="n.owner ? 'border: 1px solid red':''" @click="n.show = !n.show" class="mx-auto"
                        :max-width="n.show ? (m ? undefined : '60%' ) : '600'">
                    <v-img class="align-end text-white" :height="n.show ? undefined:'200'"
                           :src="$global.getImageUrl(n.mainImage, true, 'news', restUrl)" cover>
                        <v-card-title>{{ $global.displayDate(n.dateCreation, 'DD.MM.YYYY HH:mm') }}</v-card-title>
                    </v-img>
                    <v-card-subtitle class="pt-4">
                        <div v-html="n.text"></div>
                    </v-card-subtitle>
                    <Transition name="bounce">
                        <v-card-text v-show="n.show">
                            <div v-html="n.longText"></div>
                        </v-card-text>
                    </Transition>
                </v-card>
            </v-col>
        </v-row>

    </default-layout-dynamic>
</template>

<script>
import DefaultLayoutDynamic from "../components/layouts/DefaultLayoutDynamic";
import router from "@/router/router";
import axios from "axios";

export default {
    components: {
        DefaultLayoutDynamic,
    },
    data() {
        return {
            restUrl: process.env.VUE_APP_BACKEND_BASE_URL,
            news: [],
            m: true,

        }
    },
    name: "Main",
    mounted() {
        this.m = !this.$vuetify.display.mdAndUp;
    },
    created() {
        if (!this.loggedIn) router.push('/start')
        else {
            console.log("MAIN");
            this.getNews();
        }
    },
    methods: {
        getNews() {
            let self = this;
            let json = {};
            axios({
                method: 'post',
                url: this.restUrl + "/rest/ent/news/get_last_10",
                headers: this.$global.headers(this.$store.state.auth.user),
                dataType: 'json',
                data: json
            }).then(function (response) {
                self.news = response.data.news;
            }).catch(function (err) {
                console.log(err);
                router.push('/start');
            });
        },
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        },
        user() {
            return this.$store.state.auth.user;
        },
    },
    watch: {
        '$store.state.newsUpdater'(val) {
           console.log(val);
           this.getNews();
        },
        '$vuetify.display.mdAndUp'(val) {
            console.log(!val);
            this.m = !val;
        }
    },
};
</script>
