
<template>

  <div class="ie-panel">
    <a href="http://windows.microsoft.com/en-US/internet-explorer/">
      <img src="images/ie8-panel/warning_bar_0000_us.jpg" height="42" width="820" alt="You are using an outdated browser. For a faster, safer browsing experience, upgrade for free today.">
    </a>
  </div>
  <!--  <keep-alive>-->
  <component :is="layout">
    <router-view @update-layout="updateLayout"/>
  </component>
  <!--  </keep-alive>-->
</template>

<script>
import DefaultLayout from "./components/layouts/DefaultLayout"
import {shallowRef} from "vue";

export default {
  name: "App",
  data() {
    return {
      layout: shallowRef(DefaultLayout),
    }
  },

  methods: {
    updateLayout(l){
      try {
        this.layout = l;
      } catch (e) {
        console.log(e);
      }
    },
  },
  computed: {

    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ROLE_ADMIN');
      }
      return false;
    },
  },
  created() {
  }
};
</script>

<style scoped>

@import "../public/css/fonts.css";
@import "../public/css/style.css";
</style>
