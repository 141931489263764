<style lang="scss" scoped>
.cells-wrapper-mobile {
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin-bottom: 3px;
  font-size: 10px;
  text-align: center;
}
.cell-mobile {
  border-bottom: 1px solid #dedede;
}

.v-lazy-component.v-lazy-component--loading {
  filter: blur(15px);
}

.v-lazy-component.v-lazy-component--loaded {
  filter: blur(0);
  transition: filter 0.4s;
}
</style>
<template>

  <v-dialog :fullscreen="mobile" :model-value="show" absolute persistent :scrollable="true">

    <v-card style="overflow-x: hidden">

      <v-row :no-gutters="true">
        <v-col cols="7">
          <span :style="'margin-left: 14px; font-size:' + (mobile ? '10px':'20px')"><b>{{ getHeaderForModal() }}</b></span>
        </v-col>
        <v-col style="text-align: right">
          <v-tooltip location="left" v-if="item.id > 0 && $global.checkRoles(this.user, ['SUDO', 'ADMIN', 'MANAGER'])">
            <template v-slot:activator="{ props: tooltip }">
              <v-btn style="display: inline" v-bind="tooltip" icon :size="(mobile ? 'x-small' : 'x-small')" @click="getPrice()"><v-icon>mdi-file-excel</v-icon></v-btn>
            </template>
            <span>XLS</span>
          </v-tooltip>

          <v-tooltip location="left" v-if="item.id > 0 && $global.checkRoles(this.user, ['SUDO', 'ADMIN'])">
            <template v-slot:activator="{ props: tooltip }">
              <v-btn style="display: inline" v-bind="tooltip" icon :size="(mobile ? 'x-small' : 'x-small')" @click="getLogs()"><v-icon>mdi-information-variant</v-icon></v-btn>
            </template>
            <span>Лог</span>
          </v-tooltip>

          <v-tooltip style="display: inline-block" top>
            <template v-slot:activator="{ props: tooltip }">
              <v-btn v-bind="tooltip" icon :size="(mobile ?'x-small':'x-small')" @click="hide"><v-icon>mdi-close</v-icon></v-btn>
            </template>
            <span>Закрыть</span>
          </v-tooltip>
        </v-col>
      </v-row>

      <v-divider></v-divider>
      <v-row :no-gutters="true">
        <v-col cols="12"><v-textarea variant="solo" :disabled="item.status !== 1" :return-object="false" rows="2" label="Комментарий" density="compact" :hide-details="true" v-model="item.comment"></v-textarea></v-col>
      </v-row>
      <v-divider></v-divider>

      <v-card-text>
        <v-row :no-gutters="true" v-if="item.status === 1">
          <v-col cols="12" md="5">
            <catalog-search search-type="product" :url="'/rest/stores/search_catalog'" @select-item="catalogItemSelected" :mobile="mobile" label="Поиск продуктов"></catalog-search>
          </v-col>
          <v-col cols="12" md="4">
            <catalog-search search-type="category" :url="'/rest/stores/search_catalog'" @select-item="catalogCategorySelected" :mobile="mobile" :label="'Поиск категорий: ' + (categoty_for_all ? categoty_for_all:'')" @clear="categoty_for_all = undefined" :clearable="!!categoty_for_all" ></catalog-search>
          </v-col>
          <v-col cols="4" md="1">
            <v-text-field variant="solo" label="шт" density="compact" :hide-details="true" type="number" onkeypress="return event.charCode >= 46" @input="v => inputNCfO(v)" min="0" step="1" :model-value="count_for_all"></v-text-field>
          </v-col>
          <v-col cols="4" md="1">
            <currency-input v-model="price_for_all" :disabled="false" label="BYN" :options="{ currency: 'BYN', currencyDisplay: 'hidden', valueRange: {min:0} }"/>
          </v-col>
          <v-col cols="4" md="1">
            <v-btn x-small @click="recieve_all()"><span>-></span></v-btn>
          </v-col>

        </v-row>


        <v-table v-if="!mobile" :fixed-header="true" height="600" :density="'compact'">
          <thead><tr>
            <th class="text-left" v-for="h in headers" :key="h.text">
              {{ h.text }}
            </th>
          </tr></thead>
          <tbody>
          <lazy-component wrapper-tag="tr" v-for="(i, index) in item.items" :key="i.artikul">
            <td>{{ i.artikul }}</td>
            <td>
            <v-tooltip location="top">
              <template v-slot:activator="{ props: tooltip }">
                <td v-bind="tooltip">{{ i.breadcrumbs }}</td>
              </template>
              <span>
                <span v-if="i.images.length > 0">
                  <v-img v-for="(img, index) in i.images.split(';')" :key="index" :src="restUrl + img" width="200"/><br>
                </span>
                {{ i.description }}
              </span>
            </v-tooltip>
            </td>
            <td>
              <v-text-field v-if="item.status === 1" label="шт" density="compact" :hide-details="true" type="number" onkeypress="return event.charCode >= 46" @input="v => inputN(v, i, 'count')" min="0" step="1" :model-value="i.count"></v-text-field>
              <span v-else>{{ i.count }}</span>
            </td>
            <td>
              <currency-input v-if="item.status === 1" v-model="i.price" :disabled="false" label="BYN" :options="{ currency: 'BYN', currencyDisplay: 'hidden', valueRange: {min:0} }"/>
              <span v-else>{{ i.price }}</span>
<!--              <v-text-field density="compact" :hide-details="true" type="number" onkeypress="return event.charCode >= 46" @input="v => inputP(v, i, 'price')" min="0.01" step="0.01" :model-value="i.price"></v-text-field>-->
            </td>
            <td style="text-align: center;">{{ (i.count * i.price).toFixed(2) }}</td>
            <td>
              <v-tooltip location="bottom">
                <template v-slot:activator="{ props: tooltip }">
                  <v-btn v-bind="tooltip" icon="mdi-cart-off" :disabled="item.status !== 1" :color="'#ff0000'" size="x-small" @click="item.items.splice(index, 1);"></v-btn>
                </template>
                <span>Удалить из корзины</span>
              </v-tooltip>
            </td>
            <template #placeholder>
              <tr>
                <td colspan="6">Loading...</td>
              </tr>
            </template>
          </lazy-component>

          </tbody>
        </v-table>

        <lazy-component v-else wrapper-tag="table" class="cells-wrapper-mobile" v-for="(i, index) in item.items" :key="i.artikul">
          <tbody>
          <tr>
            <td class="cell-mobile" style="width: 100%; text-align: left;" colspan="3">
              <v-tooltip location="top">
                <template v-slot:activator="{ props: tooltip }">
                  <td v-bind="tooltip">{{ i.breadcrumbs }}</td>
                </template>
                <span>
                <span v-if="i.images.length > 0">
                  <v-img v-for="(img, index) in i.images.split(';')" :key="index" :src="restUrl + img" width="200"/><br>
                </span>
                {{ i.description }}
              </span>
              </v-tooltip>
            </td>
          </tr>

          <tr>
            <td class="cell-mobile" style="width: 30%">
              <b>{{ i.artikul }}</b>
            </td>
            <td class="cell-mobile" style="width: 35%">
              <v-text-field v-if="item.status === 1" label="шт" density="compact" :hide-details="true" type="number" onkeypress="return event.charCode >= 46" @input="v => inputN(v, i, 'count')" min="0" step="1" :model-value="i.count"></v-text-field>
              <span v-else>{{ i.count }}</span>
            </td>
            <td class="cell-mobile" style="border-left: 1px solid #dedede;width: 35%">
              <currency-input v-if="item.status === 1" v-model="i.price" :disabled="false" label="BYN" :options="{ currency: 'BYN', currencyDisplay: 'hidden', valueRange: {min:0} }"/>
              <span v-else>{{ i.price }}</span>
            </td>
          </tr>

          <tr>
            <td class="cell-mobile" style="width: 30%">
              {{ (i.count * i.price).toFixed(2) }}
            </td>
            <td colspan="2" class="cell-mobile" style="width: 70%">
              <v-tooltip location="bottom">
                <template v-slot:activator="{ props: tooltip }">
                  <v-btn v-bind="tooltip" icon="mdi-cart-off" :disabled="item.status !== 1" :color="'#ff0000'" size="x-small" @click="item.items.splice(index, 1);"></v-btn>
                </template>
                <span>Удалить из корзины</span>
              </v-tooltip>
            </td>
          </tr>

          </tbody>
          <template #placeholder>
            <tr>
              <td colspan="3">Loading...</td>
            </tr>
          </template>
        </lazy-component>

      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row :dense="true">
          <v-col cols="12">
            <v-row :no-gutters="true">
              <v-col cols="4" v-if="!mobile">
                <div class="d-inline-flex" v-if="!mobile">Итого:</div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="d-inline-flex" :style="mobile ? 'font-size: 10px':''">Колличество единиц:&nbsp;<span style="font-weight: bold;">{{ getTotalCount() }}</span></div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="d-inline-flex" :style="mobile ? 'font-size: 10px':''">Итоговая сумма:&nbsp;{{ getTotalSum('').toFixed(2) }}</div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" v-if="!((item.id === 0) && $global.checkRoles(user, ['SUDO']))"></v-col>
          <v-col v-else cols="12" md="6" :style="mobile ? 'text-align: center':'text-align: left'">
<!--            <v-checkbox style="display: inline-block" color="#ff0000" density="compact" :hide-details="true" v-model="item.dontApplyToStore" label="Не применять заказ к складу"></v-checkbox>-->
            <v-checkbox v-if="!mobile" style="display: inline-block;" color="#ff0000" density="compact" :hide-details="true" v-model="item.specialOprihod" label="Сформировать заказ после оприходования"></v-checkbox>
            <input type="checkbox" v-else style="display: inline-block;" v-model="item.specialOprihod"><span :style="(mobile ? 'font-size: 12px; ' : '')">Сформировать заказ после оприходования</span>

          </v-col>
          <v-col cols="12" md="6" v-if="!((item.id === 0) && $global.checkRoles(user, ['SUDO']))"></v-col>
          <v-col v-else cols="12" md="6" :style="mobile ? 'text-align: center':'text-align: left'">
            <v-checkbox v-if="!mobile" style="display: inline-block;" color="#ff0000" density="compact" :hide-details="true" v-model="item.dontApplyToCashier" label="Не применять накладную к кассе"></v-checkbox>
            <input type="checkbox" v-else style="display: inline-block;" v-model="item.dontApplyToCashier"><span :style="(mobile ? 'font-size: 12px; ' : '')">Не применять накладную к кассе</span>
          </v-col>
          <v-col cols="6" style="text-align: left">
            <v-btn :disabled="item.items.length === 0 || item.status !== 1" x-small @click="saveItem()"><span>Сохранить</span></v-btn>
          </v-col>
          <v-col cols="6" style="text-align: right">
            <v-btn x-small  @click="cancelEditItem()"><span>Отмена</span></v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <loader @close="displayLoader = false" v-model="displayLoader"></loader>
    <ahtung @close="alert_switch = false" :value="alert_switch" :message="alert_message" :header="alert_header"></ahtung>
    <confirm-dialog ref="confirm"></confirm-dialog>
    <log-dialog :items="logs" :show="showLogs" @close="closeLogs()" :mobile="mobile"></log-dialog>

  </v-dialog>

</template>

<script>


import Loader from "@/components/Loader.vue";
import Ahtung from "@/components/Ahtung.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import {computed, defineComponent} from "vue";
import CatalogSearch from "@/components/common/CatalogSearch.vue";
import axios from "axios";
import CurrencyInput from "@/components/common/CurrencyInput.vue";
import LogDialog from "@/components/common/LogDialog.vue";

export default defineComponent ({
  name: "WaybillOprihod",
  components: {
    LogDialog,
    CurrencyInput,
    CatalogSearch,
    ConfirmDialog,
    Ahtung,
    Loader
  },
  props: {
    show: {type: Boolean, required: true, default: false},
    mobile: {type: Boolean, required: true, default: false},
    templateWbIt: {type: Object, required: true, default() {return {}}},
    modelValue: {type: Object, required: true, default() {return {}}},
    types: {type: Array, required: true, default() {return []}},
  },
  setup(props, { emit }) {
    const item = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });
    return { item };
  },
  data() {
    return {
      restUrl: process.env.VUE_APP_BACKEND_BASE_URL,
      displayLoader: false,
      alert_switch: false,
      alert_message: "",
      alert_header: "",
      headers: [
        {text: 'ID', value: 'id', field: 'id', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Наименование', value: 'name', field: 'name', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Кол-во', value: 'count', field: 'count', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Стоимость', value: 'price', field: 'price', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Сумма', value: 'sum', field: 'sum', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Действия', value: 'sum', field: 'sum', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
      ],
      price_for_all: 0,
      count_for_all: 0,
      categoty_for_all: undefined,
      logs: [],
      showLogs: false,
    }
  },
  watch: {
  },
  mounted() {
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },

  methods: {
    closeLogs() {
      this.logs=[];
      this.showLogs = false;
    },
    getPrice() {
      let self = this;
      let json = { "item": this.item };
      axios({headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json,
        method: 'post',
        url: this.restUrl + '/rest/stores/price',
        responseType: 'blob',
      }).then((response) => {
        let filename = response.request.getResponseHeader('content-disposition').split("=")[1];
        self.$global.forceFileDownload(response.data, filename);
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
      });
    },
    getLogs() {
      let self = this;
      let json = { wbId: this.item.id };
      axios({
        method: 'post',
        url: this.restUrl + "/rest/stores/get_wb_logs",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        if(response.data.status) {
          self.logs = response.data.logs;
          self.showLogs = true;
        }
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
      });

    },
    recieve_all() {
      let self = this;
      self.displayLoader = true;
      let json = { catId: self.categoty_for_all, price: self.price_for_all };
      axios({
        method: 'post',
        url: self.restUrl + "/rest/stores/recieve_all",
        headers: self.$global.headers(self.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        let arr = self.catalogItemsSelected(response.data);
        let jsn = {storeId: self.item.storeId, items: arr};
        axios({
          method: 'post',
          url: self.restUrl + "/rest/stores/check_current_prices",
          headers: self.$global.headers(self.$store.state.auth.user),
          dataType: 'json',
          data: jsn
        }).then(function (response) {
          if(response.data.status) {
            arr = response.data.items;
            for (let item of self.item.items) arr.push(item);
            self.item.items = arr;
          } else {
            for (let item of self.item.items) arr.push(item);
            self.item.items = arr;
          }
          self.displayLoader = false;
        });
      }).catch(function (err) {
        self.displayLoader = false;
        console.log(err);
      });
    },
    catalogCategorySelected(cat) {
      this.categoty_for_all = cat.id;
    },
    inputN(v, i, f) {
      let x = this.$global.inputNumberGE(v.target.value, 0);
      if(x === 0) v.target.value = 0.;
      i[f] = x;
    },
    inputNCfO(v) {
      let x = this.$global.inputNumberGE(v.target.value, 0);
      if(x === 0) v.target.value = 0.;
      this.count_for_all = x;
    },
    catalogItemsSelected(cats) {
      let arr = [];
      for(let cat of cats) {
        if (!this.checkExist(cat.id)) {
          let itemW = this.$global.clone(this.templateWbIt);
          itemW.artikul = cat.id;
          let imgs = '';
          for (let i = 0; i < cat.images.length; i++) {
            if (i === 0) imgs += cat.images[i];
            else imgs += ';' + cat.images[i];
          }
          itemW.images = imgs;
          itemW.description = cat.description;
          itemW.searchString = cat.searchString;
          itemW.breadcrumbs = cat.text;
          itemW.count = this.count_for_all;
          if (this.price_for_all <= 0) {
            itemW.checkPrice = true;
          } else {
            itemW.checkPrice = false;
            itemW.price = this.price_for_all;
          }
          arr.push(itemW)
        }
      }
      return arr;
    },
    catalogItemSelected(cat) {
      if(!this.checkExist(cat.id)) {
        let itemW = this.$global.clone(this.templateWbIt);
        itemW.artikul = cat.id;
        let imgs = '';
        for(let i = 0;i < cat.images.length; i++) {
          if (i === 0) imgs += cat.images[i];
          else imgs += ';' + cat.images[i];
        }
        itemW.images = imgs;
        itemW.description = cat.description;
        itemW.searchString = cat.searchString;
        itemW.breadcrumbs = cat.text;
        itemW.count = this.count_for_all;
        if(this.price_for_all <= 0) {
          try {
            this.checkPrice(itemW, this.item.storeId);
          } catch (e) {
            console.log(e);
            itemW.price = 0;
            this.item.items.unshift(itemW);
          }
        } else {
          itemW.price = this.price_for_all;
          return itemW;
        }
      } else {
        //TODO highlight selected;
      }
    },
    checkExist(id) {
      for(let i of this.item.items) if(i.artikul === id) return true;
      return false;
    },
    checkPrice(itemW, sId) {
      let self = this;
      let json = {storeId: sId, artikul: itemW.artikul};
      axios({
        method: 'post',
        url: this.restUrl + "/rest/stores/check_current_price",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        itemW.price = parseFloat(response.data);
        self.item.items.unshift(itemW);
      });
    },
    getHeaderForModal() {
      return 'Тип: ' + this.getFieldFromTypeWb(this.item.type, 'nameR') + "; Статус: " + this.getFieldFromStatusWb(this.item.type, this.item.status, 'nameR');
    },
    getFieldFromTypeWb(typeId, field) {
      for(let t of this.types) if(typeId === t.id) return t[field];
      return '';
    },
    getFieldFromStatusWb(typeId, statusId, field) {
      for(let t of this.types) if(typeId === t.id) for(let s of t.statuses) if(statusId === s.id) return s[field];
      return '';
    },
    getTotalSum(prefix) {
      let result = 0;
      for(let i of this.item.items) result += i.count * i[prefix.length > 0 ? prefix + 'Price' : 'price'];
      return result;
    },
    getTotalCount() {
      let result = 0;
      for(let i of this.item.items) result += i.count;
      return result;
    },
    saveItem() {
      this.$emit('save');
    },
    cancelEditItem() {
      this.$emit('close');
    },
    hide() {
      this.$emit('close');
    },
  }
});
</script>

