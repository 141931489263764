export default {
  "language_selector": {
    "switch_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Переключить язык"])}
  },
  "login": {
    "authorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Авторизация"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логин:"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль:"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Код:"])},
    "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ВОЙТИ"])},
    "login_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Неверные учетные данные"])},
    "server_unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сервер временно недоступен."])},
    "call_to_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Если проблема длится более 5 минут, обратитесь к администратору."])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обновить"])}
  }
}