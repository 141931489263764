<template>
  <v-menu offset-y>
    <template v-slot:activator="{ props: menu }">
      <v-tooltip bottom>
        <template v-slot:activator="{ props: tooltip }">
          <v-btn icon v-bind="mergeProps(menu, tooltip)">
<!--            <img :width="($vuetify.display.mdAndUp ? '75%':'50%')" :height="($vuetify.display.mdAndUp ? '75%':'50%')" :src="currentLanguageIcon" v-if="currentLanguageIcon">-->
            <img :src="currentLanguageIcon" v-if="currentLanguageIcon">
            <v-icon v-else>mdi-earth</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('language_selector.switch_tooltip') }}</span>
      </v-tooltip>
    </template>
    <v-list>
      <v-list-item v-for="language in languages" :key="language" @click="changeLanguage(language)">
        <v-list-item-media tile size="24">
          <v-img :src="urlFront + '/images/flags/' + language + '.png'"></v-img>
        </v-list-item-media>
        <v-list-item-title><span style="font-size: 12px;">{{ language }}</span></v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mergeProps } from 'vue'
export default {
  props: {
    languages: { type: Array, required: true },
    currentLanguage: { type: String, required: false, default: null }
  },
  data() {
    return {
      urlFront: process.env.VUE_APP_FRONTEND_BASE_URL
    }
  },
  computed: {
    currentLanguageIcon() {
      if (!this.currentLanguage) return null;
      return this.urlFront + '/images/flags/' + this.currentLanguage + '.png';
    }
  },
  methods: {
    mergeProps,
    changeLanguage(code) {
      this.$i18n.locale = code;
    }
  }
};
</script>

<style lang="scss" scoped></style>
