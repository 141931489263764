import DefaultLayout from "./DefaultLayout"
import { markRaw } from "vue";
export default {
    emits: ["update-layout"],
    name: 'DefaultLayoutDynamic',
    created() {
        const dl = markRaw(DefaultLayout);
        this.$parent.$emit('update-layout', dl);
    },
    render() {
        return this.$slots.default();
    },
};
