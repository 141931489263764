<style>
.v-lazy-component.v-lazy-component--loading {
  filter: blur(15px);
}

.v-lazy-component.v-lazy-component--loaded {
  filter: blur(0);
  transition: filter 0.4s;
}
.cells-wrapper-mobile {
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin-bottom: 3px;
  font-size: 10px;
  text-align: center;
}
.cell-mobile {
  border-bottom: 1px solid #dedede;
}
.imageThumbnail {
  display: flex;
  border: 1px solid gray;
  position: relative;
  width: 40px;
  height: 40px;
}

.imageThumbnail .delete-image-icon {
  position: absolute; bottom: 0px; right: 0;
}

.imageThumbnail img {
  position: absolute; bottom: 0px; left: 0px;
  width: 100%;
  height: 100%;
}

.imageThumbnail input {
  position: absolute; bottom: 0px; right: 0px;

}
.checkbox_main-image {
  width: 12px;
  height: 12px;
}
</style>
<template>

  <v-dialog :fullscreen="mobile" :model-value="show" absolute persistent :scrollable="true">

    <v-card style="overflow-x: hidden">
      <v-card-title class="headline">
      <v-row :no-gutters="true">
        <v-col cols="7">
          <span :style="'margin-left: 14px; font-size:' + (mobile ? '10px':'20px')"><b>Настройки продуктов</b></span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col style="text-align: right">
          <v-tooltip style="display: inline-block" top>
            <template v-slot:activator="{ props: tooltip }">
              <v-btn v-bind="tooltip" icon :size="(mobile ?'x-small':'x-small')" @click="hide"><v-icon>mdi-close</v-icon></v-btn>
            </template>
            <span>Закрыть</span>
          </v-tooltip>
        </v-col>
      </v-row>
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text>
        <v-row :dense="true">
          <v-col align-self="center" cols="12" md="6">
            <v-text-field variant="solo" :density="'compact'" :hide-details="true" v-model="val.search" @change="$emit('refresh')" label="Фильтр по тексту"></v-text-field>
          </v-col>
          <v-col align-self="center" cols="6" md="5">
            <v-btn :block="true" @click="editItem(template)"><v-icon>mdi-plus</v-icon></v-btn>
          </v-col>
          <v-col align-self="center" cols="6" md="1">
            <v-btn :block="true" @click="reindex()"><v-icon>mdi-refresh</v-icon></v-btn>
          </v-col>
        </v-row>

        <v-col cols="12" v-if="items.length > 0 && val.pages > 1">
          <v-pagination v-model="val.page" :length="val.pages" :rounded="true" @input="$emit('refresh')" size="x-small"></v-pagination>
        </v-col>

        <v-table v-if="!mobile" :fixed-header="true" height="600" :density="'compact'">
          <thead><tr>
            <th class="text-left" v-for="h in headers" :key="h.text">
              {{h.text}}
            </th>
          </tr></thead>
          <tbody>
            <tr v-for="item in computedItems" :key="item.id"  @dblclick="editItem(item)">
             <td>{{ item.artikul }}</td>
              <td><span style="color: #5b7f23">{{ item.categoryBreadcrumbs }} > </span>{{ item.name }}</td>
             <td>{{ item.description }}</td>
             <td>{{ item.categoryId }}</td>
            </tr>
          </tbody>
        </v-table>
        <lazy-component v-else wrapper-tag="table" class="cells-wrapper-mobile" v-for="item in computedItems" :key="item.id">
          <tbody @click="editItem(item)">
          <tr>
            <td class="cell-mobile" style="width: 100%; text-align: left;" colspan="4">
              <span style="color: #5b7f23">{{ item.categoryBreadcrumbs }} > </span>{{ item.name }}
            </td>
          </tr>

          <tr>
            <td class="cell-mobile" style="width: 15%" colspan="2">
              <b>{{ item.artikul }}</b>
            </td>
            <td class="cell-mobile" style="border-right: 1px solid #dedede; width: 85%;" colspan="2">
              {{ item.description }}
            </td>
          </tr>
          </tbody>
          <template #placeholder>
            <tr>
              <td colspan="4">Loading...</td>
            </tr>
          </template>
        </lazy-component>
      </v-card-text>

    </v-card>

    <v-dialog :fullscreen="mobile" v-model="editDialog" :width="getModalWidth" :scrollable="true" persistent>
      <v-card v-if="item && editDialog">
        <uploader @close="displayUpLoader = false" :percentage="uploadPercentage" :visible="displayUpLoader"></uploader>
        <v-card-title class="headline">
          <v-row danse>
            <v-col cols="7">
              <span :style="'margin-left: 14px; font-size:' + (mobile ? '10px':'20px')"><b>{{ (item.id === 0 ? 'Создание нового элемента' : 'Редактирование элемента #' + item.id) }}</b></span>
            </v-col>
              <v-col style="text-align: right">
                  <v-tooltip location="left" v-if="item.id > 0 && $global.checkRoles(this.user, ['SUDO', 'ADMIN'])">
                      <template v-slot:activator="{ props: tooltip }">
                          <v-btn style="display: inline" v-bind="tooltip" icon :size="(mobile ? 'x-small' : 'x-small')" @click="getLogs()"><v-icon>mdi-information-variant</v-icon></v-btn>
                      </template>
                      <span>Лог</span>
                  </v-tooltip>
                <v-tooltip style="display: inline-block" top>
                  <template v-slot:activator="{ props: tooltip }">
                    <v-btn v-bind="tooltip" icon :size="(mobile ?'x-small':'x-small')" @click="cancelEditItem()"><v-icon>mdi-close</v-icon></v-btn>
                  </template>
                  <span>Закрыть</span>
                </v-tooltip>
              </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text v-if="item">
          <v-text-field variant="solo" :hide-details="true" density="compact" label="ID" disabled v-model="item.id"></v-text-field>
          <v-text-field variant="solo" :hide-details="true" density="compact" label="Название*" v-model="item.name"></v-text-field>
          <v-text-field variant="solo" :hide-details="true" density="compact" label="Описание" v-model="item.description"></v-text-field>
          <v-text-field variant="solo" :hide-details="true" density="compact" label="Поисковые слова" v-model="item.searchString"></v-text-field>

          <catalog-search search-type="category" :url="'/rest/settings/search_catalog'" @select-item="catalogItemSelected" :mobile="mobile"></catalog-search>
          <span style="font-size: 11px;">Категория: <span style="color: #0f8107;">{{ item.categoryBreadcrumbs }}</span></span>

          <v-row :dense="true" v-if="!item.id.includes('temp')">
            <v-col align-self="center" cols="12" md="5">
              <v-tooltip top >
                <template v-slot:activator="{ props: tooltip }">
                  <v-file-input variant="solo" @change="uploaderHandle(item.id, 'product')"
                                :counter="true" density="compact" :hide-details="true" label="Выбор файлов изображений" :multiple="true"
                                show-size v-model="images" v-on="tooltip">
                  </v-file-input>
                </template>
                <span>Добавить изображения</span>
              </v-tooltip>
            </v-col>
            <v-col :key="indexImage" align-self="center" cols="4" md="1" style="font-size: 8px;" v-for="(image, indexImage) in item.images">
              <div class="imageThumbnail">
                <img :src="$global.getImageUrl(image, false, 'product', restUrl)" @click="showLargeImageSlider(item.images, indexImage)"/>
                <v-icon @click="removeImage(item, indexImage)" color="red" x-small>mdi-delete-forever</v-icon>
                <input :checked="item.mainImageId === image.id" :disabled="item.mainImageId === image.id" :value="item.mainImageId" @change="item.mainImageId = image.id;" class="checkbox_main-image" type="checkbox"/>
              </div>
            </v-col>
          </v-row>
          <v-row :dense="true" v-else>
            <v-col cols="12" style="color: red">
              Сохраните элемент перед дальнейшим редактированием
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-row :dense="true">
            <v-col cols="6" style="text-align: left">
              <v-btn x-small @click="saveItem()">
                <span>Сохранить</span></v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <loader @close="displayLoader = false" v-model="displayLoader"></loader>
    <ahtung @close="alert_switch = false" :value="alert_switch" :message="alert_message" :header="alert_header"></ahtung>
    <confirm-dialog ref="confirm"></confirm-dialog>
    <image-slider folder="product" @close="dialogForImage = false; currentImageIndex = 0; currentImages = [];" @change-slide="(v) => {this.currentImageIndex = v}" :visible="dialogForImage" :images="currentImages" :slideIndex="$global.clone(currentImageIndex)" :mobile="mobile"></image-slider>
      <log-dialog :items="logs" :show="showLogs" @close="closeLogs()" :mobile="mobile"></log-dialog>

  </v-dialog>


</template>

<script>

import axios from "axios";
import Loader from "../Loader";
import Ahtung from "../Ahtung";
import ConfirmDialog from "../ConfirmDialog";
import ImageSlider from "../common/ImageSlider";
import Uploader from "../common/Uploader";
import CatalogSearch from "@/components/common/CatalogSearch";
import LogDialog from "@/components/common/LogDialog.vue";
import {computed} from "vue";
export default {
  components: {
      LogDialog,
    Loader,
    Ahtung,
    ImageSlider,
    ConfirmDialog,
    Uploader,
    CatalogSearch
  },
  props: {
    modelValue: {type: Object, required: true, default() {return {}}}, //pagination
    show: {type: Boolean, required: true, default: false},
    mobile: {type: Boolean, required: true, default: false},
    items: {type: Array, required: true, default() {return []}},
    template: {type: Object, required: true, default() {return {}}},
  },
  setup(props, { emit }) {
    const val = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });
    return { val };
  },
  data() {
    return {
      windowWidth: 0,
      restUrl: process.env.VUE_APP_BACKEND_BASE_URL,

      displayLoader: false,
      headers: [
        {text: 'ID', value: 'id', field: 'id', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Имя', value: 'name', field: 'name', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Описание', value: 'description', field: 'description', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Категория', value: 'login', field: 'login', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
      ],
      item: {},
      editDialog: false,
      images: [],
      displayUpLoader: false,
      uploadPercentage: 0,
      filterProducts: '',
      currentImageIndex: 0,
      currentImages: [],
      dialogForImage: false,

      alert_switch: false,
      alert_message: "",
      alert_header: "",

        showLogs: false,
        logs: [],
    }
  },
  watch: {
  },
  mounted() {
    this.windowWidth = document.documentElement.clientWidth;
    window.addEventListener('resize', this.onResize, {passive: true});
  },
  computed: {
    computedItems() {
      if(this.filterProducts.length > 1) {
        let result = [];
        let words = this.filterProducts.split(" ");
        for(let item of this.items) if(words.every(word => item.computedSearchString.toLowerCase().includes(word.toLowerCase()))) result.push(item);
        return result;
      } else return this.items;
    },
    getModalWidth() {
      return (this.windowWidth <= 1020 ? '98%' : '1000');
    },
      user() {
          return this.$store.state.auth.user;
      },
  },

  methods: {
      getLogs() {
          let self = this;
          let json = { id : this.item.id, type: 'PRODUCT' };
          axios({
              method: 'post',
              url: this.restUrl + "/rest/settings/logs/get",
              headers: this.$global.headers(this.$store.state.auth.user),
              dataType: 'json',
              data: json
          }).then(function (response) {
              if(response.data.status) {
                  self.logs = response.data.logs;
                  self.showLogs = true;
              }
          }).catch(function (e) {
              self.alert_message = "Ошибка загрузки данных. " + e;
              self.alert_header = "Внимание!";
              self.alert_switch = true;
          });
      },
      closeLogs() {
          this.logs=[];
          this.showLogs = false;
      },
    reindex() {
      this.displayLoader = true;
      let self = this;
      let json = {};
      axios({
        method: 'post',
        url: this.restUrl + "/rest/settings/product/reindex",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function () {
          self.displayLoader = false;
          console.log("DONE");
      });
    },
    catalogItemSelected(cat) {
      this.item.categoryId = cat.id;
      this.item.categoryBreadcrumbs = cat.text;
    },
    showLargeImageSlider(images, index) {
      this.currentImages = images;
      this.currentImageIndex = index + 1;
      this.dialogForImage = true;
    },
    uploaderHandle(prodId, folder) {
      let self = this;
      self.uploadPercentage = 0;
      self.displayUpLoader = true;
      let formData = new FormData();
      for (let file of self.images) {
        formData.append("files", file, file.name);
      }
      formData.append("id", prodId);
      formData.append("folder", folder);
      axios({
        method: 'post',
        url: this.restUrl + "/rest/settings/image/upload",
        data: formData,
        headers: this.$global.headersMf(this.$store.state.auth.user),
        onUploadProgress: function (progressEvent) {
          console.log('uploading...');
          this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
        }.bind(this)
      })
          .then(function (response) {
            console.log(response.data)
            self.updateItem(response.data.item);
            self.images = [];
            self.displayUpLoader = false;
          }).catch(err => {
            console.log(err);
        self.displayUpLoader = false;
      });
    },
    removeImage(item, iImage) {
      this.$refs.confirm.open('Внимание!', 'Удалить изображение?', {color: '#9C0A20'}).then((result) => {
        if(result) {
          if (item.mainImageId === item.images[iImage].id) item.mainImageId = 0;
          item.images.splice(iImage, 1);
          if (item.images.length > 0 && !item.mainImageId > 0) {
            item.mainImageId = item.images[0].id;
          }
        }
      });
    },
    updateItem(item) {
      console.log('update on product');
      this.$emit('update-item', item);
      this.editItem(item);
    },
    editItem(item) {
      this.item = this.$global.clone(item);
      this.editDialog = true;
    },
    cancelEditItem() {
      this.item = {};
      this.editDialog = false;
    },
    saveItem() {
      if(this.item.categoryId.length > 0 && this.item.name.length > 0 ) {
        this.$emit('save-item', this.item, 'product');
        this.cancelEditItem();
      } else {
        this.alert_message = "Заполните обязательные поля.";
        this.alert_header = "Внимание!"
        this.alert_switch = true;
      }
    },

    hide() {
      this.$emit('close');
    },
    onResize() {
      this.windowWidth = document.documentElement.clientWidth;
    },
  }
};
</script>

<style lang="scss" scoped></style>
