<style scoped>
.v-lazy-component.v-lazy-component--loading {
  filter: blur(15px);
}

.v-lazy-component.v-lazy-component--loaded {
  filter: blur(0);
  transition: filter 0.4s;
}
</style>
<template>

  <v-dialog :fullscreen="mobile" :model-value="show" absolute persistent :scrollable="true">

    <v-card style="overflow-x: hidden">

      <v-row :no-gutters="true">
        <v-col cols="7">
          <span :style="'margin-left: 14px; font-size:' + (mobile ? '10px':'20px')"><b>{{getHeaderForModal()}}</b></span>
        </v-col>
        <v-col style="text-align: right">

          <v-tooltip location="left" v-if="!(val.status > (checkClient ? 1 : 2))">
            <template v-slot:activator="{ props: tooltip }"><v-checkbox-btn density="compact" style="display: inline-flex;" color="#0f0" v-bind="tooltip" v-model="auto_recalculate"></v-checkbox-btn></template>
            <span>Автопересчет заказа</span>
          </v-tooltip>

          <v-tooltip location="left" v-if="val.id > 0 && $global.checkRoles(this.user, ['SUDO', 'ADMIN', 'MANAGER'])">
            <template v-slot:activator="{ props: tooltip }">
              <v-btn style="display: inline" v-bind="tooltip" icon :size="(mobile ? 'x-small' : 'x-small')" @click="getPrice()"><v-icon>mdi-file-excel</v-icon></v-btn>
            </template>
            <span>XLS</span>
          </v-tooltip>

          <v-tooltip location="left" v-if="val.id > 0 && $global.checkRoles(this.user, ['SUDO', 'ADMIN']) && !checkClient">
            <template v-slot:activator="{ props: tooltip }">
              <v-btn style="display: inline" v-bind="tooltip" icon :size="(mobile ? 'x-small' : 'x-small')" @click="getLogs()"><v-icon>mdi-information-variant</v-icon></v-btn>
            </template>
            <span>Лог</span>
          </v-tooltip>

          <v-tooltip style="display: inline-block" top>
            <template v-slot:activator="{ props: tooltip }">
              <v-btn v-bind="tooltip" icon :size="(mobile ?'x-small':'x-small')" @click="hide"><v-icon>mdi-close</v-icon></v-btn>
            </template>
            <span>Закрыть</span>
          </v-tooltip>

        </v-col>
      </v-row>

      <v-divider></v-divider>

<!--      TODO show exp?-->
        <v-row :no-gutters="true">
          <v-col cols="12"><v-textarea variant="solo" :return-object="false" rows="2" label="Комментарий" density="compact" :hide-details="true" v-model="val.comment"></v-textarea></v-col>
        </v-row>
        <v-divider></v-divider>

      <v-row :no-gutters="true">
        <v-col cols="12" md="6">
          <v-select variant="solo" label="Поставщик" v-if="dispatchers.length > 0 && checkDispatcherId(val.dispatchStoreId)" :disabled="zakaz_mode || val.id > 0 || dispatchers.length === 1" :items="dispatchers" :hide-details="true" density="compact" :item-value="'id'" :item-title="'name'" v-model="val.dispatchStoreId"></v-select>
        </v-col>
        <v-col cols="12" md="6">
          <v-select variant="solo" label="Получатель" v-if="computedConsumers.length > 0" :disabled="val.id > 0 || computedConsumers.length === 1" :items="computedConsumers" :hide-details="true" density="compact" :item-value="'id'" :item-title="'name'" v-model="val.storeId"></v-select>
        </v-col>
      </v-row>
      <v-row :no-gutters="true" v-if="!checkClient">
        <v-col cols="12" md="6">

          <v-select variant="solo" hide-selected :disabled="val.id === 0" label="Статус" item-title="nameR" :return-object="true" density="compact" :hide-details="true" :items="getStatuses()" :model-value="val.status">
            <template v-slot:item="{ item }">
              <v-list-item :disabled="item.raw.disabled" @click="val.status = item.raw.id">{{ item.raw.nameR }}</v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <v-chip>
                <span>{{ getStatusNameById(item.raw) }}</span>
              </v-chip>
            </template>
          </v-select>

        </v-col>
        <v-col cols="12" md="2">
          <v-checkbox color="#ff0000" density="compact" :hide-details="true" v-model="val.attention" label="Требует внимания"></v-checkbox>
        </v-col>
        <v-col cols="12" md="4">
          <VueDatePicker  placeholder="Дата напоминания" v-model="val.dateAttention"></VueDatePicker>
        </v-col>
      </v-row>
      <store-search :disabled="val.status > (checkClient ? 1 : 2)" :show-data="!checkClient" :url="'/rest/stores/search_store_zakaz'" @select-item="catalogItemSelected" :store-id="val.dispatchStoreId" :mobile="mobile"></store-search>


        <v-card-text :style="mobile ? 'font-size: 10px; min-height: 300px;':''">
          <v-progress-linear :active="loader" indeterminate></v-progress-linear>

          <v-table v-if="!mobile" :fixed-header="true" height="600" :density="'compact'" :style="mobile ? 'min-width: 1000px':''">
            <thead><tr>
              <th class="text-left" v-for="h in headers" :key="h.text">
                {{ h.text }}
              </th>
            </tr></thead>
            <tbody>

            <lazy-component wrapper-tag="tr" v-for="(i, index) in val.items" :key="i.artikul">
              <td>{{ i.artikul }}</td>
              <v-tooltip location="top">
                <template v-slot:activator="{ props: tooltip }">
                  <td v-bind="tooltip">
                    {{ i.breadcrumbs }}
                    <template v-if="i.error.length > 0"><br><span style="color: red; font-size: 10px">{{ i.error }}</span></template>
                  </td>
                </template>
                <span>
                <span v-if="i.images.length > 0">
                  <v-img v-for="(img, index) in i.images.split(';')" :key="index" :src="restUrl + img" width="200"/><br>
                </span>
                {{ i.description }}
              </span>
              </v-tooltip>

              <td><v-text-field density="compact" :disabled="val.status > (checkClient ? 1 : 2)" :label="checkClient ? '' : '' + i.availableCount" :hide-details="true" type="number" onkeypress="return event.charCode >= 46" @input="v => inputN(v, i, 'count')" :model-value="i.count" min="1" step="1"></v-text-field></td>
              <template v-if="checkClient">
                <td v-if="!i.chip">{{ i.price }}</td>
                <td v-else><s style="color: #717171">{{ i.price }}</s>{{ i.priceCalculated }}</td>
                <td style="text-align: center;">{{ i.sumCalculated }}</td>
              </template>
              <template v-else>
                <td  style="width: 15%">
                  <div class="d-flex">
                    <v-tooltip location="top">
                      <template v-slot:activator="{ props: tooltip }"><v-checkbox-btn :disabled="val.status > 2"  density="compact" @change="recalculateOrder()" v-bind="tooltip" v-model="i.manualPrice"></v-checkbox-btn></template>
                      <span>Ручной ввод цены</span>
                    </v-tooltip>
                    <currency-input :color="(i.price < i.pp ? '#f00':'')" :disabled="!i.manualPrice || val.status > 2" v-model="i.price" :label="'' + i.pp + ' BYN'" @kp="recalculateOrder()" :options="{ currency: 'BYN', currencyDisplay: 'hidden', valueRange: {min:0} }"/>
                  </div>
                </td>
                <td style="width: 15%">
                  <div class="d-flex">
                    <v-tooltip location="top">
                      <template v-slot:activator="{ props: tooltip }"><v-checkbox-btn :disabled="val.status > 2" density="compact" @change="v => checkOrderSumManual(v.target.checked, i)" v-bind="tooltip" v-model="i.manualSum"></v-checkbox-btn></template>
                      <span>Ручной ввод суммы</span>
                    </v-tooltip>
                    <currency-input :disabled="val.status > 2" v-if="i.manualSum" v-model="i.manualSumVal" @kp="recalculateOrder()" label="BYN" :options="{ currency: 'BYN', currencyDisplay: 'hidden', valueRange: {min:0} }"/>
                    <span v-else> {{ i.sum }} </span>
                  </div>
                </td>
              </template>

              <td>
                <v-tooltip location="bottom">
                  <template v-slot:activator="{ props: tooltip }">
                    <v-btn :disabled="val.status > (checkClient ? 1 : 2)" v-bind="tooltip" icon="mdi-cart-off" :color="'#ff0000'" size="x-small" @click="val.items.splice(index, 1); recalculateOrder()"></v-btn>
                  </template>
                  <span>Удалить из корзины</span>
                </v-tooltip>
              </td>
              <template #placeholder>
                <tr>
                  <td colspan="6">Loading...</td>
                </tr>
              </template>
            </lazy-component>
            </tbody>
          </v-table>

          <lazy-component v-else wrapper-tag="table" class="cells-wrapper-mobile" v-for="(i, index) in val.items" :key="index">
            <tbody>
            <tr>
              <td colspan="1" class="cell-mobile" style="width: 15%">
                <v-tooltip location="bottom">
                  <template v-slot:activator="{ props: tooltip }">
                    <v-btn :disabled="val.status > (checkClient ? 1 : 2)" v-bind="tooltip" icon="mdi-cart-off" :color="'#ff0000'" size="x-small" @click="val.items.splice(index, 1); recalculateOrder()"></v-btn>
                  </template>
                  <span>Удалить из корзины</span>
                </v-tooltip>
              </td>
              <v-tooltip location="top">
                <template v-slot:activator="{ props: tooltip }">
                  <td v-bind="tooltip" style="width: 85%" colspan="3">
                    {{ i.breadcrumbs }}
                    <template v-if="i.error.length > 0"><br><span style="color: red; font-size: 10px">{{ i.error }}</span></template>
                  </td>
                </template>
                <span>
                <span v-if="i.images.length > 0">
                  <v-img v-for="(img, index) in i.images.split(';')" :key="index" :src="restUrl + img" width="200"/><br>
                </span>
                {{ i.description }}
              </span>
              </v-tooltip>
            </tr>

            <tr>
              <td class="cell-mobile" style="width: 15%">
                <b>{{ i.artikul }}</b>
              </td>
              <td class="cell-mobile" style="width: 25%">
                <v-text-field density="compact" :disabled="val.status > (checkClient ? 1 : 2)" :label="checkClient ? '' : '' + i.availableCount" :hide-details="true" type="number" onkeypress="return event.charCode >= 46" @input="v => inputN(v, i, 'count')" :model-value="i.count" min="1" step="1"></v-text-field>
              </td>

              <template v-if="checkClient">
                <td v-if="!i.chip" class="cell-mobile" style="border-left: 1px solid #dedede;width: 30%">{{ i.price }}</td>
                <td v-else class="cell-mobile" style="border-left: 1px solid #dedede;width: 30%"><s style="color: #717171">{{ i.price }}</s>{{ i.priceCalculated }}</td>
                <td class="cell-mobile" style="border-left: 1px solid #dedede;width: 30%; text-align: center;">{{ i.sumCalculated }}</td>
              </template>
              <template v-else>
                <td  style="border-left: 1px solid #dedede;width: 30%">
                  <div class="d-flex">
                    <v-tooltip location="top">
                      <template v-slot:activator="{ props: tooltip }"><v-checkbox-btn :disabled="val.status > 2"  density="compact" @change="recalculateOrder()" v-bind="tooltip" v-model="i.manualPrice"></v-checkbox-btn></template>
                      <span>Ручной ввод цены</span>
                    </v-tooltip>
                    <currency-input :color="(i.price < i.pp ? '#f00':'')" :disabled="!i.manualPrice || val.status > 2" v-model="i.price" :label="'' + i.pp + ' BYN'" @kp="recalculateOrder()" :options="{ currency: 'BYN', currencyDisplay: 'hidden', valueRange: {min:0} }"/>
                  </div>
                </td>
                <td style="border-left: 1px solid #dedede;width: 30%">
                  <div class="d-flex">
                    <v-tooltip location="top">
                      <template v-slot:activator="{ props: tooltip }"><v-checkbox-btn :disabled="val.status > 2" density="compact" @change="v => checkOrderSumManual(v.target.checked, i)" v-bind="tooltip" v-model="i.manualSum"></v-checkbox-btn></template>
                      <span>Ручной ввод суммы</span>
                    </v-tooltip>
                    <currency-input :disabled="val.status > 2" v-if="i.manualSum" v-model="i.manualSumVal" @kp="recalculateOrder()" label="BYN" :options="{ currency: 'BYN', currencyDisplay: 'hidden', valueRange: {min:0} }"/>
                    <span v-else> {{ i.sum }} </span>
                  </div>
                </td>
              </template>
            </tr>


            </tbody>
            <template #placeholder>
              <tr>
                <td colspan="4">Loading...</td>
              </tr>
            </template>
          </lazy-component>


      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-row :dense="true">
          <v-col cols="12">
            <v-row :no-gutters="true">
              <v-col cols="4" v-if="!mobile">
                <div class="d-inline-flex" v-if="!mobile">Итого:</div>
              </v-col>
              <v-col cols="12" md="4">
                <div class="d-inline-flex" :style="mobile ? 'font-size: 10px':''">Колличество единиц:&nbsp;<span style="font-weight: bold;">{{ val.totalCount }}</span></div>
              </v-col>
              <v-col cols="12" md="4" v-if="!checkClient">
                <div class="d-flex" :style="mobile ? 'font-size: 10px':''">
                  Итоговая сумма:&nbsp;
                  <v-tooltip location="top">
                    <template v-slot:activator="{ props: tooltip }"><v-checkbox-btn :disabled="val.status > 2 && val.status !== 8" density="compact" @change="v => checkOrderSumManual(v.target.checked, val)" v-bind="tooltip" v-model="val.manualSum"></v-checkbox-btn></template>
                    <span>Ручной ввод итоговой суммы</span>
                  </v-tooltip>
                  <currency-input :disabled="val.status > 2 && val.status !== 8" v-if="val.manualSum" v-model="val.manualSumVal" @kp="recalculateOrder()" label="BYN" :options="{ currency: 'BYN', currencyDisplay: 'hidden', valueRange: {min:0} }"/>
                  <span v-else style="font-weight: bold;">{{ val.sum }}</span>
                </div>
              </v-col>
              <v-col cols="12" md="4" v-else>
                <div v-if="!orderContainsChip()" class="d-inline-flex" :style="mobile ? 'font-size: 10px':''">Итоговая сумма: <span style="font-weight: bold;">{{ val.sum }}</span></div>
                <div v-else class="d-inline-flex" :style="mobile ? 'font-size: 10px':''">Итоговая сумма:&nbsp;<span style="font-weight: bold;"><s style="color: #717171">{{ val.sum }}</s> {{ val.sumCalculated }}</span></div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6" :style="mobile ? '':'text-align: left'">
            <v-btn :block="mobile" v-if="!store.stopSales" :disabled="!auto_recalculate || (val.items.length === 0 || checkOrderHasErrors() || (checkClient && val.status > 1))" x-small @click="saveItem(false)"><span style="color: green">{{ val.id === 0 ? 'Оформить':'Сохранить'}}</span></v-btn>
          </v-col>
          <v-col cols="12" md="6" :style="mobile ? '':'text-align: right'" v-if="val.id > 0">
            <v-btn :block="mobile" v-if="!store.stopSales" :disabled="!auto_recalculate || (val.items.length === 0 || checkOrderHasErrors() || (checkClient && val.status > 1))" x-small @click="saveItem(true)"><span style="color: green">Сохранить и закрыть</span></v-btn>
          </v-col>
          <v-col cols="12" md="6" v-else>
          </v-col>
          <v-col cols="12" md="4" v-if="!($global.checkRoles(user, ['SUDO']))"></v-col>
          <v-col v-else cols="12" md="4" :style="mobile ? 'text-align: center':'text-align: left'">
            <v-checkbox :disabled="val.id !== 0 && !checkClient" style="display: inline-block" color="#ff0000" density="compact" :hide-details="true" v-model="val.dontApplyToStore" label="Не применять заказ к складу"></v-checkbox>
          </v-col>
          <v-col cols="12" md="4" v-if="!($global.checkRoles(user, ['SUDO']))"></v-col>
          <v-col v-else cols="12" md="4" :style="mobile ? 'text-align: center':'text-align: left'">
            <v-checkbox :disabled="val.id !== 0 && !checkClient" style="display: inline-block" color="#ff0000" density="compact" :hide-details="true" v-model="val.dontApplyToCashier" label="Не применять заказ к кассе"></v-checkbox>
          </v-col>
          <v-col cols="12" md="4" :style="mobile ? '':'text-align: right'" v-if="!checkClient">
            <v-btn :block="mobile" x-small :disabled="val.items.length === 0" @click="recalculateOrder(true)"><span style="color: red;">Пересчет заказа</span></v-btn>
          </v-col>
          <v-col cols="12" md="4" :style="mobile ? '':'text-align: right'" v-else>
            <v-btn :block="mobile" x-small :disabled="val.items.length === 0" @click="clearEditItem()"><span style="color: red;">{{ (val.id > 0 ? 'Закрыть' : 'Очистить корзину') }}</span></v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <loader @close="displayLoader = false" v-model="displayLoader"></loader>
    <ahtung @close="alert_switch = false" :value="alert_switch" :message="alert_message" :header="alert_header"></ahtung>
    <confirm-dialog ref="confirm"></confirm-dialog>
    <log-dialog :items="logs" :show="showLogs" @close="closeLogs()" :mobile="mobile"></log-dialog>

  </v-dialog>

</template>

<script>


import Loader from "@/components/Loader.vue";
import Ahtung from "@/components/Ahtung.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import {computed, defineComponent} from "vue";
import StoreSearch from "@/components/common/StoreSearch.vue";
import axios from "axios";
import LogDialog from "@/components/common/LogDialog.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import CurrencyInput from "@/components/common/CurrencyInput.vue";


export default defineComponent ({
  name: "WaybillZakaz",
  components: {
    CurrencyInput,
    VueDatePicker,
    LogDialog,
    StoreSearch,
    ConfirmDialog,
    Ahtung,
    Loader
  },
  props: {
    show: {type: Boolean, required: true, default: false},
    mobile: {type: Boolean, required: true, default: false},
    templateWbIt: {type: Object, required: true, default() {return {}}},
    modelValue: {type: Object, required: true, default() {return {}}},
    types: {type: Array, required: true, default() {return []}},
    dispatchers: {type: Array, required: true, default() {return []}},
    consumers: {type: Array, required: true, default() {return []}},
    loader: {type: Boolean, required: true, default: false},
    store: {type: Object, required: true, default() {return {}}},
    zakaz_mode: {type: Boolean, required: true, default: false},
  },
  setup(props, { emit }) {
    const val = computed({
      get: () => props.modelValue,
      set: (value) => emit('update:modelValue', value),
    });
    return { val };
  },
  data() {
    return {
      restUrl: process.env.VUE_APP_BACKEND_BASE_URL,
      displayLoader: false,
      alert_switch: false,
      alert_message: "",
      alert_header: "",
      headers: [
        {text: 'ID', value: 'id', field: 'id', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Наименование', value: 'name', field: 'name', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Кол-во', value: 'count', field: 'count', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Стоимость', value: 'price', field: 'price', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
        {text: 'Сумма', value: 'sum', field: 'sum', align: 'center', sortIncrease: false, select: true, sortable: true, divider: true, width: 30},
      ],
      logs: [],
      showLogs: false,
      auto_recalculate: true,
    }
  },
  watch: {
    auto_recalculate(val) {
      if(val) {
        this.recalculateOrder();
      }
    },
    'val.dispatchStoreId'(val) {
      if(this.show) {
        this.val.items = [];
        this.val.storeId = 0;
        for (let st of this.computedConsumers) if (st.mainStoreId === val) {
          this.val.storeId = st.id;
          break;
        }
        if (this.val.storeId === 0) {
          this.alert_message = "Нет доступных складов для отгрузки.";
          this.alert_header = "Внимание!";
          this.alert_switch = true;
        }
      }
    },
    show(val) {
      console.log(val);
    }
  },
  mounted() {
  },
  computed: {
    computedConsumers() {
      let result = [];
      for(let st of this.consumers) if(st.mainStoreId === this.val.dispatchStoreId) result.push(st);
      return result;
    },
    checkClient() {
      for(let st of this.dispatchers) if(st.id === this.val.dispatchStoreId && st.adminIds.includes(this.user.id)) return false;
      return true;
    },
    user() {
      return this.$store.state.auth.user;
    },
  },

  methods: {
    checkOrderSumManual(v, i) {
      if(v) i.manualSumVal = i.sum;
      else this.recalculateOrder();
    },
    checkDispatcherId(dispatchStoreId) {
      for(let st of this.dispatchers) if(st.id === dispatchStoreId) return true;
      return false;
    },
    closeLogs() {
      this.logs=[];
      this.showLogs = false;
    },
    getPrice() {
      let self = this;
      let json = { "item": this.val };
      axios({headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json,
        method: 'post',
        url: this.restUrl + '/rest/stores/price',
        responseType: 'blob',
      }).then((response) => {
        let filename = response.request.getResponseHeader('content-disposition').split("=")[1];
        self.$global.forceFileDownload(response.data, filename);
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
      });
    },
    getLogs() {
      let self = this;
      let json = { wbId: this.val.id };
      axios({
        method: 'post',
        url: this.restUrl + "/rest/stores/get_wb_logs",
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json
      }).then(function (response) {
        if(response.data.status) {
          self.logs = response.data.logs;
          self.showLogs = true;
        }
      }).catch(function (e) {
        self.alert_message = "Ошибка загрузки данных. " + e;
        self.alert_header = "Внимание!";
        self.alert_switch = true;
      });

    },
    inputN(v, i, f) {
      let x = this.$global.inputNumberGE(v.target.value, 1);
      if(x === 0) v.target.value = 0;
      i[f] = x;
      this.recalculateOrder();
    },
    async catalogItemSelected(cat) {
      if(!this.checkExist(cat.artikul)) {
        let itemW = this.$global.clone(this.templateWbIt);
        itemW.artikul = cat.artikul;
        itemW.images = cat.images;
        itemW.description = cat.description;
        itemW.searchString = cat.searchString;
        itemW.breadcrumbs = cat.breadcrumbs;
        itemW.count = 1;
        itemW.price = cat.price;
        itemW.priceCalculated = cat.price;
        itemW.pp = cat.previousPrice;
        itemW.dif = cat.dif;
        itemW.formerId = cat.formerId;
        this.val.items.unshift(itemW);
        this.recalculateOrder();
      }
    },
    checkExist(id) {
      for(let i of this.val.items) if(i.artikul === id) return true;
      return false;
    },
    getHeaderForModal() {
      return 'Тип: ' + this.getFieldFromTypeWb(this.val.type, 'nameR') + "-" + this.val.id + "; Статус: " + this.getFieldFromStatusWb(this.val.type, this.val.status, 'nameR');
    },
    getFieldFromTypeWb(typeId, field) {
      for(let t of this.types) if(typeId === t.id) return t[field];
      return '';
    },
    getFieldFromStatusWb(typeId, statusId, field) {
      for(let t of this.types) if(typeId === t.id) for(let s of t.statuses) if(statusId === s.id) return s[field];
      return '';
    },
    checkOrderHasErrors() {
      for(let i of this.val.items) if(i.error.length > 0) return true;
      return false;
    },
    orderContainsChip() {
      for(let i of this.val.items) if(i.chip) return true;
      return false;
    },
    getTotalSum(prefix) {
      let result = 0;
      for(let i of this.val.items) result += i.count * i[prefix.length > 0 ? prefix + 'Price' : 'price'];
      return result;
    },
    getTotalCount() {
      let result = 0;
      for(let i of this.val.items) result += i.count;
      return result;
    },
    saveItem(close) {
      this.$emit('save', 'Z', close);
    },
    clearEditItem() {
      this.$emit('clear');
      this.$emit('close');
    },
    hide() {
      this.$emit('close');
    },
    recalculateOrder(manual) {
      if(this.auto_recalculate || manual) this.$emit('recalculate');
    },
    getStatuses() {
      let result = [];
      for(let t of this.types) if(t.id === this.val.type) {
        let cur = this.val.status;
        for(let s of t.statuses) {
          switch (cur) {
            case 1:
              if(s.id === 1) s.disabled = true;         //new
              else if(s.id === 2) s.disabled = false;   //sborka
              else if(s.id === 3) s.disabled = false;   //sent
              else if(s.id === 4) s.disabled = false;   //recieve
              else if(s.id === 5) s.disabled = true;   //realize
              else if(s.id === 6) s.disabled = false;   //otmena
              else if(s.id === 7) s.disabled = true;   //zavershen
              else if(s.id === 8) s.disabled = true;   //vozvrat
              break;
            case 2:
              if(s.id === 1) s.disabled = true;         //new
              else if(s.id === 2) s.disabled = true;    //sborka
              else if(s.id === 3) s.disabled = false;   //sent
              else if(s.id === 4) s.disabled = false;   //recieve
              else if(s.id === 5) s.disabled = true;   //realize
              else if(s.id === 6) s.disabled = false;   //otmena
              else if(s.id === 7) s.disabled = true;   //zavershen
              else if(s.id === 8) s.disabled = true;   //vozvrat
              break;
            case 3:
              if(s.id === 1) s.disabled = true;        //new
              else if(s.id === 2) s.disabled = true;   //sborka
              else if(s.id === 3) s.disabled = true;   //sent
              else if(s.id === 4) s.disabled = false;  //recieve
              else if(s.id === 5) s.disabled = true;   //realize
              else if(s.id === 6) s.disabled = true;   //otmena
              else if(s.id === 7) s.disabled = true;   //zavershen
              else if(s.id === 8) s.disabled = true;   //vozvrat
              break;
            case 4:
              if(s.id === 1) s.disabled = true;        //new
              else if(s.id === 2) s.disabled = true;   //sborka
              else if(s.id === 3) s.disabled = true;   //sent
              else if(s.id === 4) s.disabled = true;   //recieve
              else if(s.id === 5) s.disabled = false;  //realize
              else if(s.id === 6) s.disabled = true;   //otmena
              else if(s.id === 7) s.disabled = false;  //zavershen
              else if(s.id === 8) s.disabled = false;  //vozvrat
              break;
            case 5:
              if(s.id === 1) s.disabled = true;        //new
              else if(s.id === 2) s.disabled = true;   //sborka
              else if(s.id === 3) s.disabled = true;   //sent
              else if(s.id === 4) s.disabled = true;   //recieve
              else if(s.id === 5) s.disabled = true;   //realize
              else if(s.id === 6) s.disabled = true;   //otmena
              else if(s.id === 7) s.disabled = false;  //zavershen
              else if(s.id === 8) s.disabled = false;  //vozvrat
              break;
            case 6:
              if(s.id === 1) s.disabled = true;        //new
              else if(s.id === 2) s.disabled = true;   //sborka
              else if(s.id === 3) s.disabled = true;   //sent
              else if(s.id === 4) s.disabled = true;   //recieve
              else if(s.id === 5) s.disabled = true;   //realize
              else if(s.id === 6) s.disabled = true;   //otmena
              else if(s.id === 7) s.disabled = true;   //zavershen
              else if(s.id === 8) s.disabled = true;   //vozvrat
              break;
            case 7:
              if(s.id === 1) s.disabled = true;        //new
              else if(s.id === 2) s.disabled = true;   //sborka
              else if(s.id === 3) s.disabled = true;   //sent
              else if(s.id === 4) s.disabled = true;   //recieve
              else if(s.id === 5) s.disabled = false;  //realize
              else if(s.id === 6) s.disabled = true;   //otmena
              else if(s.id === 7) s.disabled = true;   //zavershen
              else if(s.id === 8) s.disabled = false;  //vozvrat
              break;
            case 8:
              if(s.id === 1) s.disabled = true;        //new
              else if(s.id === 2) s.disabled = true;   //sborka
              else if(s.id === 3) s.disabled = true;   //sent
              else if(s.id === 4) s.disabled = true;   //recieve
              else if(s.id === 5) s.disabled = false;  //realize
              else if(s.id === 6) s.disabled = true;   //otmena
              else if(s.id === 7) s.disabled = false;  //zavershen
              else if(s.id === 8) s.disabled = true;   //vozvrat
              break;
          }
          result.push(s);
        }
      }
      return result;
    },
    getStatusNameById(id) {
      for(let s of this.getStatuses()) if(s.id === id) return s.nameR;
      return "";
    },
  }
});
</script>

