export default {
  "language_selector": {
    "switch_tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer de langue"])}
  },
  "login": {
    "authorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisation"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion:"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe:"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code:"])},
    "enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'IDENTIFIER"])},
    "login_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauvaises qualifications"])},
    "server_unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le serveur est momentanément indisponible."])},
    "call_to_admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si le problème dure plus de 5 minutes, contactez votre administrateur."])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rafraîchir"])}
  }
}