<style>

</style>
<template>

  <v-dialog :fullscreen="mobile" :model-value="show" persistent :scrollable="true">
    <v-card style="overflow-x: hidden">

      <v-row :no-gutters="true">
        <v-col cols="10">
          <span :style="'margin-left: 14px; font-size:' + (mobile ? '12px':'20px')"><b>Категории товаров</b></span>
          <v-tooltip style="display: inline-block" top>
            <template v-slot:activator="{ props: tooltip }">
              <v-btn v-bind="tooltip" icon :size="(mobile ? 'x-small' : 'x-small')" @click="addRootNode"><v-icon>mdi-plus</v-icon></v-btn>
            </template>
            <span>Добавить корневой элемент</span>
          </v-tooltip>
          <v-tooltip style="display: inline-block" top>
            <template v-slot:activator="{ props: tooltip }">
              <v-btn v-bind="tooltip" icon :size="(mobile ? 'x-small' : 'x-small')" @click="saveItem"><v-icon>mdi-content-save</v-icon></v-btn>
            </template>
            <span>Сохранить структуру категорий</span>
          </v-tooltip>
          <v-tooltip style="display: inline-block" top>
            <template v-slot:activator="{ props: tooltip }">
              <v-btn v-bind="tooltip" icon :size="(mobile ? 'x-small' : 'x-small')" @click="$emit('refresh')"><v-icon>mdi-refresh</v-icon></v-btn>
            </template>
            <span>Перезагрузить список без сохранения</span>
          </v-tooltip>
        </v-col>
        <v-col>

        </v-col>
        <v-spacer></v-spacer>
        <v-col style="text-align: right">
          <v-tooltip style="display: inline-block" top>
            <template v-slot:activator="{ props: tooltip }">
              <v-btn v-bind="tooltip" icon :size="(mobile ?'x-small':'x-small')" @click="hide"><v-icon>mdi-close</v-icon></v-btn>
            </template>
            <span>Закрыть</span>
          </v-tooltip>
        </v-col>

      </v-row>
      <v-divider></v-divider>
      <v-card-text>

        <v-col cols="12">
          <tree :custom-options="myCustomOptions" :custom-styles="myCustomStyles" :nodes="items"></tree>
        </v-col>




      </v-card-text>
    </v-card>

    <v-dialog :fullscreen="mobile" v-model="editDialog" :width="getModalWidth" :scrollable="true" persistent>
      <v-card v-if="item && editDialog">
        <uploader @close="displayUpLoader = false" :percentage="uploadPercentage" :visible="displayUpLoader"></uploader>
        <v-card-title class="headline">
          <v-row danse>
            <v-col cols="7">
              <span :style="'margin-left: 14px; font-size:' + (mobile ? '10px':'20px')"><b>{{ (item.id.includes('temp') ? 'Создание нового элемента' : 'Редактирование элемента #' + item.id) }}</b></span>
            </v-col>
              <v-col style="text-align: right">
                  <v-tooltip location="left" v-if="!item.id.includes('temp') && $global.checkRoles(this.user, ['SUDO', 'ADMIN'])">
                      <template v-slot:activator="{ props: tooltip }">
                          <v-btn style="display: inline" v-bind="tooltip" icon :size="(mobile ? 'x-small' : 'x-small')" @click="getLogs()"><v-icon>mdi-information-variant</v-icon></v-btn>
                      </template>
                      <span>Лог</span>
                  </v-tooltip>
                <v-tooltip style="display: inline-block" top>
                  <template v-slot:activator="{ props: tooltip }">
                    <v-btn v-bind="tooltip" icon :size="(mobile ?'x-small':'x-small')" @click="closeEditItem()"><v-icon>mdi-close</v-icon></v-btn>
                  </template>
                  <span>Закрыть</span>
                </v-tooltip>
              </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text v-if="item">
          <v-text-field variant="solo" density="compact" :hide-details="true" label="Название" v-model="item.name" @input="v => item.text = item.id + ' / ' + v.target.value"></v-text-field>
          <v-text-field variant="solo" density="compact" :hide-details="true" label="Описание" v-model="item.definition"></v-text-field>
          <v-text-field variant="solo" density="compact" :hide-details="true" label="Поисковые слова" v-model="item.searchString"></v-text-field>
<!--          <v-checkbox density="compact" label="Включена?" v-model="item.enabled"></v-checkbox>-->

          <v-row :dense="true" v-if="!item.id.includes('temp')">
            <v-col align-self="center" ccols="12" md="5">
              <v-tooltip top >
                <template v-slot:activator="{ props: tooltip }">
                  <v-file-input variant="solo" @change="uploaderHandle(item.id, 'category')"
                                :counter="true" density="compact" :hide-details="true" label="Выбор файлов изображений" :multiple="true"
                                show-size v-model="images" v-on="tooltip">
                  </v-file-input>
                </template>
                <span>Добавить изображения</span>
              </v-tooltip>
            </v-col>
            <v-col :key="indexImage" align-self="center" cols="4" md="1" style="font-size: 8px;" v-for="(image, indexImage) in item.images">
              <div class="imageThumbnail">
                <img :src="$global.getImageUrl(image, false, 'category', restUrl)" @click="showLargeImageSlider(item.images, indexImage)"/>
                <v-icon @click="removeImage(item, indexImage)" color="red" x-small>mdi-delete-forever</v-icon>
                <input :checked="item.mainImageId === image.id" :disabled="item.mainImageId === image.id" :value="item.mainImageId" @change="item.mainImageId = image.id;" class="checkbox_main-image" type="checkbox"/>
              </div>
            </v-col>
          </v-row>
          <v-row :dense="true" v-else>
            <v-col cols="12" style="color: red">
              Сохраните элемент перед дальнейшим редактированием
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-row :dense="true">
            <v-col cols="6" style="text-align: right"><v-btn x-small  @click="closeEditItem()"><span>OK</span></v-btn></v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <loader @close="displayLoader = false" v-model="displayLoader"></loader>
    <ahtung @close="alert_switch = false" :value="alert_switch" :message="alert_message" :header="alert_header"></ahtung>
    <confirm-dialog ref="confirm"></confirm-dialog>
    <image-slider folder="category" @close="dialogForImage = false; currentImageIndex = 0; currentImages = [];" @change-slide="(v) => {this.currentImageIndex = v}" :visible="dialogForImage" :images="currentImages" :slideIndex="$global.clone(currentImageIndex)" :mobile="mobile"></image-slider>
      <log-dialog :items="logs" :show="showLogs" @close="closeLogs()" :mobile="mobile"></log-dialog>

  </v-dialog>


</template>

<script>


import Loader from "../Loader";
import Ahtung from "../Ahtung";
import Tree from 'vuejs-tree'
import axios from "axios";
import ConfirmDialog from "../ConfirmDialog";
import ImageSlider from "../common/ImageSlider";
import Uploader from "../common/Uploader";
import LogDialog from "@/components/common/LogDialog.vue";

export default {
  components: {
      LogDialog,
      Tree,
    Loader,
    Ahtung,
    ConfirmDialog,
    ImageSlider,
    Uploader,
  },
  props: {
    show: {type: Boolean, required: true, default: false},
    mobile: {type: Boolean, required: true, default: false},
    items: {type: Array, required: true, default() {return []}},
    template: {type: Object, required: true, default() {return {}}},
  },
  data() {
    return {
      windowWidth: 0,
      restUrl: process.env.VUE_APP_BACKEND_BASE_URL,
      displayLoader: false,
      item: {},
      editDialog: false,

      images: [],
      displayUpLoader: false,
      uploadPercentage: 0,

      currentImageIndex: 0,
      currentImages: [],
      dialogForImage: false,

      alert_switch: false,
      alert_message: "",
      alert_header: "",

      key: 0,

        showLogs: false,
        logs: [],
    }
  },
  watch: {
  },
  mounted() {
    this.windowWidth = document.documentElement.clientWidth;
    window.addEventListener('resize', this.onResize, {passive: true});
  },

  methods: {
      getLogs() {
          let self = this;
          let json = { id : this.item.id, type: 'CATEGORY' };
          axios({
              method: 'post',
              url: this.restUrl + "/rest/settings/logs/get",
              headers: this.$global.headers(this.$store.state.auth.user),
              dataType: 'json',
              data: json
          }).then(function (response) {
              if(response.data.status) {
                  self.logs = response.data.logs;
                  self.showLogs = true;
              }
          }).catch(function (e) {
              self.alert_message = "Ошибка загрузки данных. " + e;
              self.alert_header = "Внимание!";
              self.alert_switch = true;
          });
      },
      closeLogs() {
          this.logs=[];
          this.showLogs = false;
      },
    uploaderHandle(prodId, folder) {
      let self = this;
      self.uploadPercentage = 0;
      self.displayUpLoader = true;
      let formData = new FormData();
      for (let file of self.images) {
        formData.append("files", file, file.name);
      }
      formData.append("id", prodId);
      formData.append("folder", folder);
      axios({
        method: 'post',
        url: this.restUrl + "/rest/settings/image/upload",
        data: formData,
        headers: this.$global.headersMf(this.$store.state.auth.user),
        onUploadProgress: function (progressEvent) {
          console.log('uploading...');
          this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
        }.bind(this)
      })
          .then(function (response) {
            console.log(response.data)
            self.updateItem(response.data.item);
            self.images = [];
            self.displayUpLoader = false;
          }).catch(err => {
        console.log(err);
        self.displayUpLoader = false;
      });
    },
    showLargeImageSlider(images, index) {
      this.currentImages = images;
      this.currentImageIndex = index + 1;
      this.dialogForImage = true;
    },
    removeImage(item, iImage) {
      this.$refs.confirm.open('Внимание!', 'Удалить изображение?', {color: '#9C0A20'}).then((result) => {
        if(result) {
          if (item.mainImageId === item.images[iImage].id) item.mainImageId = 0;
          item.images.splice(iImage, 1);
          if (item.images.length > 0 && !item.mainImageId > 0) {
            item.mainImageId = item.images[0].id;
          }
        }
      });
    },
    updateItem(item) {
      console.log('update on product');
      this.$emit('refresh');
      this.editNode(item);
    },
    treeExpanded(id, val) {
      console.log('tree-expanded');
      console.log(id);
      console.log(val);
    },
    treeCollapsed(id, val) {
      console.log('tree-collapsed');
      console.log(id);
      console.log(val);
    },
    treeSelected(id, val) {
      console.log('tree-selected');
      console.log(id);
      console.log(val);
    },
    myCheckedFunction(id, val) {
      console.log('tree-checked');
      console.log(id);
      console.log(val);
    },
    eventsExpanded(id, val) {
      console.log('event-expanded');
      console.log(id);
      console.log(val);
    },
    eventsSelected(id, val) {
      console.log('event-selected');
      console.log(id);
      console.log(val);
    },
    eventsChecked(id, val) {
      console.log('event-checked');
      console.log(id);
      console.log(val);
    },
    editableName(id, val) {
      console.log('editable-name');
      console.log(id);
      console.log(val);
    },
    addNode(parent) {
      try {
        console.log('add-node');
        console.log(parent);
        let node = this.$global.clone(this.template);
        node.name = "Новая категория"
        node.parentId = parent.id
        node.depth = parent.depth + 1;
        node.id = 'temp-' + this.key;
        node.text = node.id + " / Новая категория"
        this.key += 1;
        this.$emit('add-item', parent, node);
        parent.expandable = true;
        parent.state.expanded = true;

      } catch (e) {
        console.log(e);
      }
    },
    addRootNode() {
      let node = this.$global.clone(this.template);
      node.root = true;
      node.name = "Новая категория"
      node.id = 'temp-' + this.key;
      node.text = node.id + " / Новая категория"
      this.key += 1;
      this.$emit('add-item-root', node);
    },
    editNode(node) {
      console.log('edit-node');
      console.log(node);
      this.item = node;
      this.editDialog = true;
    },
    deleteNode(node) {
      try {
        console.log('delete-node');
        console.log(node);
        if (!node.id.includes('temp')) {
          console.log("Невозможно удалить")
        } else {
          console.log("Удаление...");
          this.$emit('del-item', this.items, node.id);
        }
      } catch (e) {
        console.log(e);
      }
    },
    closeEditItem() {
      this.item = {};
      this.editDialog = false;
    },
    saveItem() {
      this.$emit('save-item', this.items, 'category');
      // this.cancelEditItem();
    },
    hide() {
      this.$refs.confirm.open('Внимание!', 'Закрыть окно? Все не сохраненные данные будут утеряны.', {color: '#9C0A20'}).then((result) => {
        if(result) {
          this.$emit('close');
        }
      });
    },
    onResize() {
      this.windowWidth = document.documentElement.clientWidth;
    },
  },
  computed: {
      user() {
          return this.$store.state.auth.user;
      },
    getModalWidth() {
      return (this.windowWidth <= 1020 ? '98%' : '1000');
    },
    myCustomStyles() {
      return {
        tree: {
          style: {
            height: 'auto',
            maxHeight: '500px',
            overflowY: 'visible',
            display: 'inline-block',
            textAlign: 'left'
          }
        },
        row: {
          style: {
            width: '500px',
            cursor: 'pointer'
          },
          child: {
            class: 'child-class',
            style: {
              height: '20px'
            },
            active: {
              class: 'child-class-active',
              style: {
                height: '20px'
              }
            }
          }
        },
        expanded: {
          class: 'null_class',
        },
        addNode: {
          // class: 'mdi-plus',
          style: {}
        },
        editNode: {
          // class: 'mdi-edit',
          style: {}
        },
        deleteNode: {
          // class: 'mdi-delete',
          style: {}
        },
        selectIcon: {
          // class: 'mdi-select',
          style: {},
          active: {
            // class: 'mdi-selected',
            style: {}
          }
        },
        text: {
          style: {},
          // class: 'capitalize',
          active: {
            style: {
              'font-weight': 'bold',
            }
          }
        }
      };
    },
    myCustomOptions() {
      return {
        treeEvents: {
          expanded: {
            state: true,
            fn: this.treeExpanded,
          },
          collapsed: {
            state: true,
            fn: this.treeCollapsed,
          },
          selected: {
            state: false,
            fn: this.treeSelected,
          },
          checked: {
            state: false,
            fn: this.myCheckedFunction,
          }
        },
        events: {
          expanded: {
            state: false,
            fn: null,
            // fn: this.eventsExpanded,
          },
          selected: {
            state: false,
            fn: null,
            // fn: this.eventsSelected,
          },
          checked: {
            state: false,
            fn: null,
            // fn: this.eventsChecked,
          },
          editableName: {
            state: true,
            fn: this.editableName,
            calledEvent: 'expanded',
          }
        },
        addNode: { state: true, fn: this.addNode, appearOnHover: true },
        editNode: { state: true, fn: this.editNode, appearOnHover: true },
        deleteNode: { state: true, fn: this.deleteNode, appearOnHover: true },
        showTags: false,
      };
    }
  },
};
</script>

<style lang="scss" scoped></style>
