import ru from "./ru.json";
import en from "./en.json";
import by from "./by.json";
import fr from "./fr.json";
import es from "./es.json";



export const defaultLocale = "ru";

export const languages = {
  ru: ru,
  by: by,
  en: en,
  es: es,
  fr: fr,
};

