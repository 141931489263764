import EmptyLayout from "./EmptyLayout"
import { markRaw } from "vue";
export default {
    emits: ["update-layout"],
    name: 'EmptyLayoutDynamic',
    created() {
        const dl = markRaw(EmptyLayout);
        this.$parent.$emit('update-layout', dl);
    },
    render() {
        return this.$slots.default();
    },
};
