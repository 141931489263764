<style lang="scss" scoped>
.plus_minus {
  font-size: 18px;
  color: red;
  cursor: pointer;
}
</style>
<template>

      <tbody style="font-size: 12px">
      <tr @dblclick="expand(wrapper.id)">
        <td>{{ wrapper.id }}</td>
        <td>{{ $global.displayDate(wrapper.dateCreation, 'DD.MM.YYYY HH:mm') }}</td>
        <td>{{ getFieldFromStatusOr(wrapper.type, wrapper.status, 'nameR') }}</td>
        <td>{{ wrapper.sum }} / {{ wrapper.paySum }}</td>
        <td>
          <v-tooltip  location="bottom">
            <template v-slot:activator="{ props: tooltip }"><v-btn v-bind="tooltip" size="x-small" icon="mdi-arrow-down" @click="expand(wrapper.id)"></v-btn></template>
            <span>Подробности заказа</span>
          </v-tooltip>
        </td>
      </tr>
      <tr v-if="expanded.includes(wrapper.id)">
        <td colspan="4" style="font-size: 11px;"><b>Состав заказа:</b></td>
      </tr>
      <tr v-if="expanded.includes(wrapper.id)">
        <td colspan="4">
        <table style="border-radius: 5px; width: 100%; font-size: 10px;" border="1">
          <tr>
            <td>Арт</td>
            <td>Назв</td>
            <td>Кол</td>
            <td>Цен</td>
            <td>Сум</td>
            <td v-if="wrapper.hasPresents">Под</td>
            <td v-if="profile.returning_allowed_brak">Брк</td>
            <td v-if="profile.returning_allowed">Взв</td>
          </tr>
          <tr v-for="(it, index) of wrapper.items" :key="index">
            <td>{{it.artikul}}</td>
            <td>{{it.breadcrumbs}}</td>
            <td>{{it.count}}</td>
            <td>{{it.price}}</td>
            <td>{{it.sum}}</td>
            <td v-if="wrapper.hasPresents">{{it.present}}</td>
            <td v-if="profile.returning_allowed_brak && wrapper.status === 7"><v-tooltip location="bottom"><template v-slot:activator="{ props: tooltip }"><span v-bind="tooltip" v-if="!wrapper.returnsApplied && ![8, 9].includes(wrapper.status) && !(it.brak + it.ret === it.count)" class="plus_minus" @click="icrease(it, 1, 'brak', wrapper.id)">+</span></template><span>Добавить брак</span></v-tooltip>&nbsp;{{ it.brak }}&nbsp;<v-tooltip location="bottom"><template v-slot:activator="{ props: tooltip }"><span v-bind="tooltip" v-if="!wrapper.returnsApplied && ![8, 9].includes(wrapper.status) && !(it.brak === 0)" class="plus_minus" @click="icrease(it, -1, 'brak', wrapper.id)">-</span></template><span>Уменьшить брак</span></v-tooltip></td>
            <td v-if="profile.returning_allowed && wrapper.status === 7"><v-tooltip location="bottom"><template v-slot:activator="{ props: tooltip }"><span v-bind="tooltip" v-if="!wrapper.returnsApplied && ![8, 9].includes(wrapper.status) && !(it.brak + it.ret === it.count)" class="plus_minus" @click="icrease(it, 1, 'ret', wrapper.id)">+</span></template><span>Добавить возврат</span></v-tooltip>&nbsp;{{ it.ret }}&nbsp;<v-tooltip location="bottom"><template v-slot:activator="{ props: tooltip }"><span v-bind="tooltip" v-if="!wrapper.returnsApplied && ![8, 9].includes(wrapper.status) && !(it.ret === 0)" class="plus_minus" @click="icrease(it, -1, 'ret', wrapper.id)">-</span></template><span>Уменьшьть возврат</span></v-tooltip></td>
          </tr>
          <tr>
            <td></td>
            <td style="text-align: right;"><b>Итого:</b></td>
            <td>{{ wrapper.totalCount }}</td>
            <td></td>
            <td>{{ wrapper.sum }}</td>
            <td v-if="wrapper.hasPresents"></td>
            <td v-if="profile.returning_allowed_brak"></td>
            <td v-if="profile.returning_allowed && !store.stopSales">
              <v-tooltip location="bottom">
                <template v-slot:activator="{ props: tooltip }">
                  <v-icon v-bind="tooltip" v-if="changedOrders.includes(wrapper.id)" size="x-small" color="#ff0000" @click="saveOrder(wrapper)">mdi-content-save</v-icon>
                </template>
                <span>Сохранить заказ</span>
              </v-tooltip>
            </td>
          </tr>
          <tr>

          </tr>
        </table>
        </td>
      </tr>
      <tr v-if="expanded.includes(wrapper.id)">
        <td colspan="4" style="font-size: 11px;"><b>Платежи:</b></td>
      </tr>
      <tr v-if="expanded.includes(wrapper.id)">
        <td colspan="4">
          <table style="border-radius: 5px; width: 100%; font-size: 10px;" border="1">
            <tr v-for="(pi, index) of wrapper.payments" :key="index">
              <td>{{pi.id}}</td>
              <td>{{$global.displayDate(pi.date, 'DD.MM.YYYY HH:mm')}}</td>
              <td>{{pi.value}}</td>
              <td>{{pi.currency}}</td>
              <td>{{pi.description}}</td>
            </tr>
            <tr>

            </tr>
          </table>
        </td>
      </tr>
      <tr v-if="expanded.includes(wrapper.id)">
        <td colspan="4" style="font-size: 11px;"><b>Детали заказа:</b></td>
      </tr>
      <tr v-if="expanded.includes(wrapper.id)">
        <td colspan="4">
          <table style="border-radius: 5px; width: 100%; font-size: 10px;" border="1">
            <tr>
              <td>Имя:</td>
              <td>{{wrapper.name}}</td>
            </tr>
            <tr>
              <td>Емэйл:</td>
              <td>{{wrapper.email}}</td>
            </tr>
            <tr>
              <td>Телефон:</td>
              <td>{{wrapper.phone}}</td>
            </tr>
            <tr>
              <td>Адрес:</td>
              <td>{{wrapper.address}}</td>
            </tr>
            <tr>
              <td>Доставка:</td>
              <td>{{getShippingName(wrapper.shippingId)}}</td>
            </tr>
            <tr>
              <td>Стоимость доставки:</td>
              <td><b>{{wrapper.shippingPrice}}</b></td>
            </tr>
            <tr>
              <td>Дата доставки:</td>
              <td><b>{{$global.displayDate(wrapper.dateShipping, 'DD.MM.YYYY HH:mm')}}</b></td>
            </tr>
            <tr>
              <td>Трекномер почты:</td>
              <td><b>{{wrapper.postTrackNum}}</b></td>
            </tr>
            <tr>
              <td>Комментарий:</td>
              <td>{{wrapper.comment}}</td>
            </tr>
          </table>
        </td>
      </tr>


      </tbody>

</template>

<script>


export default {
  components: {
  },
  props: {
    mobile: {type: Boolean, required: true, default: false},
    wrapper: {type: Object, required: true, default() {return {}}},
    profile: {type: Object, required: true, default() {return {}}},
    store: {type: Object, required: true, default() {return {}}},
    types: {type: Array, required: true, default() {return []}},
    shippings: {type: Array, required: true, default() {return []}},
  },
  data() {
    return {
      restUrl: process.env.VUE_APP_BACKEND_BASE_URL,
      expanded: [],
      changedOrders: [],
    }
  },
  watch: {},
  mounted() {
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },

  methods: {
    saveOrder(o) {
      var index = this.changedOrders.indexOf(o.id);
      if (index !== -1) this.changedOrders.splice(index, 1);
      this.$emit('save', o);
    },
    icrease(it, count, field, id) {
      it[field] += count;
      if(it[field] < 0) it[field] = 0;
      else if(it.brak + it.ret > it.count) it[field] -= 1;
      else if(!this.changedOrders.includes(id)) this.changedOrders.push(id);
    },
    getShippingName(id) {
      for(let s of this.shippings) if(s.id===id) return s.name;
    },
    expand(id) {
      if(!this.expanded.includes(id)) this.expanded.push(id);
      else {
        for(let i=0;i<this.expanded.length; i++) {
          if(this.expanded[i] === id) {
            this.expanded.splice(i,1);
            break;
          }
        }
      }
    },
    getFieldFromStatusOr(typeId, statusId, field) {
      for(let t of this.types) if(typeId === t.id) for(let s of t.statuses) if(statusId === s.id) return s[field];
      return '';
    },
  }
};
</script>

