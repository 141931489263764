<style>

</style>
<template>

  <v-dialog :fullscreen="mobile" :model-value="show" absolute persistent>

    <v-card style="overflow-x: hidden">

      <v-card-title class="headline">
      <v-row :no-gutters="true">
        <v-col cols="10">
          <span :style="'margin-left: 14px; font-size:' + (mobile ? '12px':'20px')"><b>Системные настройки</b></span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col style="text-align: right">
          <v-tooltip style="display: inline-block" top>
            <template v-slot:activator="{ props: tooltip }">
              <v-btn v-bind="tooltip" icon :size="(mobile ?'x-small':'x-small')" @click="hide"><v-icon>mdi-close</v-icon></v-btn>
            </template>
            <span>Закрыть</span>
          </v-tooltip>
        </v-col>
      </v-row>
      </v-card-title>

      <v-divider></v-divider>
      <v-card-text>
      <v-row :no-gutters="true">
        <v-col cols="12">
          <v-btn @click="clearCaches">Чистка кэша</v-btn>
          <v-btn @click="makeDump">Скачать дамп базы</v-btn>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field variant="solo" v-model.number="sdMin" type="number" density="compact" :hide-details="true" label="Выключение через N минут"></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-text-field variant="solo" v-model.number="sdLen" type="number" density="compact" :hide-details="true" label="Длительность простоя N минут"></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
          <v-btn @click="runTimer">Запуск таймера</v-btn>
        </v-col>
        <v-col  cols="12" md="3">
          <v-btn @click="stopTimer">Отключить таймер</v-btn>
        </v-col>
      </v-row>
      </v-card-text>
    </v-card>


    <loader @close="displayLoader = false" v-model="displayLoader"></loader>
    <ahtung @close="alert_switch = false" :value="alert_switch" :message="alert_message"
            :header="alert_header"></ahtung>
    <confirm-dialog ref="confirm"></confirm-dialog>

  </v-dialog>


</template>

<script>

import axios from "axios";
import Loader from "../Loader";
import Ahtung from "../Ahtung";
import ConfirmDialog from "../ConfirmDialog";

export default {
  components: {
    Loader,
    Ahtung,
    ConfirmDialog,
  },
  props: {
    show: {type: Boolean, required: true, default: false},
    mobile: {type: Boolean, required: true, default: false},
  },
  data() {
    return {
      windowWidth: 0,
      restUrl: process.env.VUE_APP_BACKEND_BASE_URL,

      displayLoader: false,

      sdMin: 10,
      sdLen: 10,

      alert_switch: false,
      alert_message: "",
      alert_header: "",
    }
  },
  watch: {},
  mounted() {
    this.windowWidth = document.documentElement.clientWidth;
    window.addEventListener('resize', this.onResize, {passive: true});
  },
  computed: {
    getModalWidth() {
      return (this.windowWidth <= 1020 ? '98%' : '1000');
    },
  },

  methods: {
    runTimer() {
      let json = {min: this.sdMin, len: this.sdLen};
      axios({
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json,
        method: 'post',
        url: this.restUrl + "/rest/common/start_shutdown_timer",
      })
          .then(() => {
          }).catch(function (e) {
        console.log(e);
      });
    },
    stopTimer() {
      self.displayLoader = true;
      let json = {};
      axios({
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json,
        method: 'post',
        url: this.restUrl + "/rest/common/stop_shutdown_timer",
      })
          .then(() => {
          }).catch(function (e) {
        console.log(e);
      });
    },
    clearCaches() {
      axios.get(this.restUrl + '/rest/common/clear_caches', {headers: this.$global.headersMf(this.$store.state.auth.user)});
    },
    makeDump() {
      let self = this;
      self.displayLoader = true;
      let json = {};
      axios({
        headers: this.$global.headers(this.$store.state.auth.user),
        dataType: 'json',
        data: json,
        method: 'post',
        url: this.restUrl + "/rest/common/db_dump",
      })
          .then((response) => {
            let filename = response.request.getResponseHeader('content-disposition').split("=")[1];
            self.$global.forceFileDownload(response.data, filename);
            self.displayLoader = false;
          }).catch(function (e) {
        console.log(e);
        self.displayLoader = false;
      });
    },

    hide() {
      this.$emit('close');
    },
    onResize() {
      this.windowWidth = document.documentElement.clientWidth;
    },
  }
};
</script>

<style lang="scss" scoped></style>
