<template>
  <section class="bg-default section-md">
    <div class="container">
      ДОСТУП ЗАПРЕЩЕН
    </div>
  </section>
</template>

<script>

export default {
  components: {
  },
  data() {
    return {

    }
  },
  name: "P403",
  created() {

  },
  methods: {

  },
  computed: {

  }
};
</script>
