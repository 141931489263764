import { createApp } from 'vue';
import App from './App';
import router from "./router/router";
import store from './store';
import i18n from './i18n';
import global from './plugins/global';
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader';
import LazyComponent from 'v-lazy-component'

loadFonts();
const app = createApp(App);
app.config.globalProperties.$global = global.global;
app.use(store).use(i18n).use(router).use(vuetify).use(LazyComponent);
router.isReady().then(() => app.mount('#app'))
// app.mount('#app');
